import React, { Component } from "react";
import swal from "sweetalert";
import endPoint from "../../../config";
import axios from "../../../httpServices/httpService";
import valid from '../../../utill/validation';
import joi from 'joi';

import { ToastContainer, toast } from 'react-toastify';

class ComponentMaster extends Component {
  state = {
    component: { Operation: [] },
    oldObj: {},
    toolRequireRadioState:this.props.actionStatus=='update'?this.props.componentData.Operation[0].ToolRequiredToProduce?1:0: 1,
    cycleTimeRadioState: 1,
    operationSerial:this.props.actionStatus=='update'?1: 0,
    noOfOperation: [],
    OperationObj: this.props.actionStatus=='add'?{}:this.props.actionStatus=='update'?this.props.componentData.Operation[0]:{} ,
    action: "",
    selectedEquipmentRequired: "",
    equipmentTypeList:[],
    toolList:[],
    errors:{},
  };

  schema={
    operationRejectionCost:joi.number(),
    ComponentId:joi.string().required().label('Component Id'),
    NumberOfOperations:joi.number().required().label('No Of Operations'),

    OperationID: joi.string(),
    OperationSerial: joi.number().min(0),
    EquipmentRequired: joi.string(),
    NetWeightPerComponent: joi.number().min(0),
    ToolRequiredToProduce: joi.bool(),

   
    Operation: joi.array()
    .items(joi.object().keys({ 
      Tool: joi.string().when('ToolRequiredToProduce',{is:true,then:joi.required()}),
      OperationID: joi.string().required() ,
      OperationSerial: joi.number().min(0).required(),
      EquipmentRequired: joi.string().required(),
      NetWeightPerComponent: joi.number().min(0).required(),
      ToolRequiredToProduce: joi.bool().required(),

      ScrapWeightCycle: joi.number().min(0).when('ToolRequiredToProduce',{is:false,then:joi.required()}),
      IdealCycleTime: joi.number().min(0).when('ToolRequiredToProduce',{is:false,then:joi.required()}),
      CycleTime:joi.number().min(0).when('ToolRequiredToProduce',{is:false,then:joi.required()})

    }))
    .min(1).max(this.state.noOfOperation.length)
    .required().label('Operations')
    

  }

  componentDidMount() {
    axios.get(endPoint.api+'GetAllEquipmentTypes')
    .then((result)=>{
    // consol(result.data)
      this.setState({equipmentTypeList:result.data})
    })
    .catch((err)=>{
      swal(`${err}`)
    })
    axios.get(endPoint.api+'GetAllTools')
    .then((result)=>{
      this.setState({toolList:result.data})
    })
    .catch((err)=>{
      swal(`${err}`)
    })
    if (this.props.actionStatus === "add") {
      this.setState({ component: { Operation: [] } });
    }
    if (this.props.actionStatus === "update") {
      const component = { ...this.props.componentData };
      this.setState({ component: this.props.componentData, oldObj: component });
    }
  }
  handleOnChangeOperationSerial = e => {
    this.setState({ operationSerial: e.currentTarget.value });
  };

  handleOnChange = e => {
    const { component,errors } = { ...this.state };
    const { OperationObj } = { ...this.state };

    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    if (
      e.currentTarget.name == "ComponentId" ||
      e.currentTarget.name == "NumberOfOperations"
    ) {
      component[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ component,errors:errors||{} });
    } else {
      if (e.currentTarget.name === "Tool") {
        OperationObj[e.currentTarget.name] = e.currentTarget.value;
        OperationObj["OperationSerial"] = this.state.operationSerial;
        OperationObj["ToolRequiredToProduce"] = true;
        this.setState({ OperationObj });
      } else {
        OperationObj["OperationSerial"] = this.state.operationSerial;
        OperationObj[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ OperationObj });
      }
    }
  };
  handleRadioChange = e => {
    //const { component } = { ...this.state };
    const { OperationObj,errors } = { ...this.state };
    const { name, value } = e.currentTarget;

    // const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    // if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    // else delete errors[e.currentTarget.name];

    if (name === "ToolRequiredToProduce") {
      OperationObj[e.currentTarget.name] = e.currentTarget.value;
      this.setState({
        [name]: value === "true" ? 1 : 0,
        toolRequireRadioState: value === "false" ? 0 : 1,
        OperationObj,errors:errors||{}
      });
    }
    if (name === "CycleTime") {
      this.setState({
        [name]: value === "PPC" ? 1 : 0,
        cycleTimeRadioState: value === "CPP" ? 0 : 1,errors:errors||{}
      });
    }
  };
  handleSubmit = (data, obj) => {
    const errors = valid.validate(data,this.schema);
    // consol(errors)
    this.setState({ errors: errors || {} });
    if (errors) return;

    if (
      data.Operation.filter(one => one.OperationSerial === obj.OperationSerial)
        .length <= 0
    )
      data.Operation.push(obj);
     // consol(data)
     
    if (
      this.props.componentList.filter(
        one => one.ComponentId == data.ComponentId
      ).length <= 0
    ) {
      if (this.props.actionStatus === "add") {
        axios
          .post(endPoint.api + "AddComponent", data)
          .then(result => {
            if (result.status==200) {
              this.props.history.push("/setting/component/list");
              swal("Save Data Successfully");
            } else {
              swal(`Data Not Saved`);
            }
          })
          .catch(err => {
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          });
      }
      if (this.props.actionStatus === "update") {
        axios
          .patch(endPoint.api + "UpdateComponent", {
            old: this.state.oldObj,
            new: data
          })
          .then(result => {
            const { n, nModified, ok } = result.data;
            if (ok == 1 && n == 1 && nModified == 1) {
              this.props.history.push("/setting/component/list");
              swal("Update Data Successfully");
            } else {
              swal(`${result.data}`);
            }
          })
          .catch(err => {
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          });
      }
    } else {
      swal("Duplicate Entry Not Inserted");
    }
  };

  handleNoOfOperation = () => {
    if(this.props.actionStatus=='add')
    {
      for (let i = 0; i < this.state.component.NumberOfOperations; i++) {
        this.state.noOfOperation[i] = i + 1;
      }
    }
    if(this.props.actionStatus=='update')
    {
      for (let i = 0; i < this.state.component.NumberOfOperations; i++) {
        this.state.noOfOperation[i] = i+1 ;
      }
    }
   
  };

  moveNextSerial = Operation => {
    let { operationSerial, noOfOperation } = this.state;
    const { component } = { ...this.state };
    if(this.props.actionStatus==='add')
    {
      operationSerial++;
      component.Operation.push(Operation);
      this.setState({
        operationSerial: operationSerial,
        component,
        OperationObj: {}
      });
      // consol(component);
    }
    if(this.props.actionStatus==='update')
    {
      
      operationSerial++;
    
      this.setState({
        operationSerial: operationSerial,
         toolRequireRadioState:this.props.actionStatus=='update'?this.state.component.Operation[operationSerial].ToolRequiredToProduce?1:0:1,
        OperationObj: this.props.actionStatus=='add'?{}:this.props.actionStatus=='update'?this.props.componentData.Operation[operationSerial]:{}
      });
    }
  };
 

  render() {
    
    this.handleNoOfOperation();
 
 
   

    return (
      <React.Fragment>              <ToastContainer/>
        <div className=" border ">
          <div className="row justify-content-center ">
            <div className="col-sm-4 overflow-auto">
              <div className="form-group">
                <label htmlFor="componentid">Component ID</label>
                <input
                  className="form-control form-control-sm"
                  required
                  type="text"
                  onChange={this.handleOnChange}
                  name="ComponentId"
                  placeholder="Enter Component Id..."
                  defaultValue={this.state.component.ComponentId}
                />
                {this.state.errors.ComponentId && <div className="alert small alert-danger">{this.state.errors['ComponentId']}</div>}
 
              </div>
            </div>
            <div className="col-sm-4 overflow-auto">
              <div className="form-group">
                <label>NumberOfOperations</label>
                <input
                  className="form-control form-control-sm"
                  required
                  type="number"
                  min="0"
                  onChange={this.handleOnChange}
                  name="NumberOfOperations"
                  placeholder="Enter Number  Of Operation"
                  defaultValue={this.state.component.NumberOfOperations}
                />
                {this.state.errors.NumberOfOperations && <div className="alert small alert-danger">{this.state.errors['NumberOfOperations']}</div>}
 
              </div>
            </div>

            <div className="col-sm-4 ">
              <div className="form-group text-center">
                <label>Operation Serial</label>
                <select
                  type="text"
                  required
                  className="form-control form-control-sm"
                  name="OperationSerial"
                  onChange={this.handleOnChangeOperationSerial}
                  value={this.props.actionStatus==='add'?this.state.operationSerial:this.state.OperationObj.OperationSerial}
                >
                  <option>--select operation serial--</option>
                  {this.state.noOfOperation.map(one => {
                    return <option>{one}</option>;
                  })}
                </select>
                {this.state.errors.OperationSerial && <div className="alert small alert-danger">{this.state.errors['OperationSerial']}</div>}
 
              </div>
            </div>
          </div>

          {
            <form>
              <div className=" justify-content-center col-sm-8  border">
                <div className="form-group">
                  <label>Operational ID</label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    required
                  
                    onChange={this.handleOnChange}
                    name="OperationID"
                    placeholder="Enter Operation Id"
                    defaultValue={this.state.OperationObj.OperationID}
                  />
                  {this.state.errors.OperationID && <div className="alert small alert-danger">{this.state.errors['OperationID']}</div>}
 
                </div>

                <div className="form-group text-center">
                  <label>Equipment Required</label>
                  <select
                    type="text"
                    required
                    className="form-control form-control-sm"
                    name="EquipmentRequired"
                    onChange={this.handleOnChange}
                    value={this.state.OperationObj.EquipmentRequired}
                  >
                    <option>--select equipment required--</option>
                    {this.state.equipmentTypeList.map(one=>{
                      return <option>{one.EquipmentType}</option>
                    })}
                    </select>
                    {this.state.errors.EquipmentRequired && <div className="alert small alert-danger">{this.state.errors['EquipmentRequired']}</div>}
 
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="componentweight">Component Weight</label>
                  <input
                    className="form-control form-control-sm"
                    type="number"
                    min="0"
                    required
                    onChange={this.handleOnChange}
                    name="NetWeightPerComponent"
                    placeholder="Enter Component Weight..."
                    defaultValue={this.state.OperationObj.NetWeightPerComponent}
                  />
                  {this.state.errors.NetWeightPerComponent && <div className="alert small alert-danger">{this.state.errors['NetWeightPerComponent']}</div>}
 
                </div>
                <div className="form-group">
                  <label htmlFor="componentweigth">
                    Tool Required To Produce
                  </label>
                  <br />
                  <input
                    type="radio"
                    required
                    value={true}
                    checked={this.state.toolRequireRadioState === 1}
                    name="ToolRequiredToProduce"
                    onChange={this.handleRadioChange}
                    
                  />
                  &nbsp;<label htmlFor="yes">Yes</label>
                  <br />
                  <input
                    type="radio"
                    required
                    value={false}
                    name="ToolRequiredToProduce"
                    checked={this.state.toolRequireRadioState === 0}
                    onChange={this.handleRadioChange}
                  />
                  &nbsp;<label htmlFor="no">No</label>
                </div>
                {this.state.toolRequireRadioState === 0 ? (
                  <React.Fragment>
                    <div className="form-group">
                      <label htmlFor="scrapweight">Scrap Weight</label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        required
                        onChange={this.handleOnChange}
                        min="0"
                        name="ScrapWeightCycle"
                        defaultValue={this.state.OperationObj.ScrapWeightCycle}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="idealcycletime">Ideal Cycle Time</label>
                      <input
                        className="form-control form-control-sm"
                        onChange={this.handleOnChange}
                        type="number"
                        required
                        min="0"
                        name="IdealCycleTime"
                        defaultValue={this.state.OperationObj.IdealCycleTime}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="partspercycle">Parts Per Cycle</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                            required
                              type="radio"
                              name="CycleTime"
                              value="PPC"
                              checked={this.state.cycleTimeRadioState === 1}
                              onChange={this.handleRadioChange}
                              aria-label="Radio button for following text input"
                            />
                          </div>
                        </div>
                        <input
                        required
                          onChange={this.handleOnChange}
                          disabled={
                            this.state.cycleTimeRadioState === 1 ? false : true
                          }
                          className="form-control"
                          type="number"
                          min="0"
                          name="PartsPerCycle"
                          defaultValue={this.state.OperationObj.PartsPerCycle}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="cyclesperpart">Cycles Per Part</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                            required
                              type="radio"
                              name="CycleTime"
                              value="CPP"
                              checked={this.state.cycleTimeRadioState === 0}
                              onChange={this.handleRadioChange}
                              aria-label="Radio button for following text input"
                            />
                          </div>
                        </div>
                        <input
                          className="form-control"
                          required
                          disabled={
                            this.state.cycleTimeRadioState === 0 ? false : true
                          }
                          onChange={this.handleOnChange}
                          type="number"
                          min="0"
                          name="CyclePerParts"
                          defaultValue={this.state.OperationObj.CyclePerParts}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="form-group">
                    <label htmlFor="Tool">Tool</label>
                    <select
                    required
                      className="form-control form-control-sm"
                      onChange={this.handleOnChange}
                      name="Tool"
                      value={this.state.OperationObj.Tool}
                    >
                      <option>----Select Tool-----</option>
                      {this.state.toolList.map(one=>{
                      return <option>{one.ToolId}</option>
                    })}
                    </select>
                  </div>
                )}

               <div
                  className="text-center"
                  onClick={() => {
                    (this.state.noOfOperation.length)===this.state.operationSerial?this.handleSubmit(this.state.component,this.state.OperationObj):this.moveNextSerial(this.state.OperationObj);
                  }}
                >
                {this.state.errors.Operation && <div className="alert small alert-danger">At Least 1 Operation Item For This Component</div>}
 
 
                  <input
                    type={(this.state.noOfOperation.length)===this.state.operationSerial?'button':this.props.actionStatus==='add'?"reset":'button'}
                    className="btn btn-primary"
                    value={(this.state.noOfOperation.length)===this.state.operationSerial?'Submit':"Next"}
                  />
                </div>
              
              </div>
            </form>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default ComponentMaster;
