import React, { Component } from "react";
import Table from "../../../Common/table";
//import "../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import dateTime from "../../../utill/dateTime";
import _ from "lodash";
import axios from '../../../httpServices/httpService';
import api from '../../../config';
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';

class UserTable extends Component {
  state = {
    sortColumn: { path: "name", order: "asc" },
    sortedData: [],
    sortFlag: false,
    querySuccess: false,
    userList: []
  };
  handleSort = (sortColumn) => {
    // consol(sortColumn,this.props.userList,"data length");
    if (this.state.userList.length > 0) {
      const sorted = _.orderBy(
        this.state.userList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, sortedData: sorted, sortFlag: true });
    }
  };

  onDelete = (data) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "user/" + data._id, { data: data })
          .then(result => {
            // // consol(result.data)
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.userList.filter(
                obj => obj._id !== data._id
              );
              this.setState({ userList: filterData, querySuccess: false });
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });




  }


  componentDidMount() {
    this.setState({ querySuccess: true });
    axios.get(api.api + 'user').then((result) => {
      this.setState({ querySuccess: false, userList: result.data });
    })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  columns = [
    { path: "name", label: "Username" },
    { path: "email", label: "Email" },
    { path: "WhatsAppNumber", label: "What's App No."},
    { path: "userType", label: "User Type" },
    { path: "UserGroup", label: "User Group" },
    {
      key: "createdAt",
      label: "Created Date",
      content: (user) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(user.createdAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "updatedAt",
      label: "Last Updated Date",
      content: (user) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(user.updatedAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "update",
      content: (user) => (
        <button
          disabled={!this.props.getRightsForAccess('StaticDataRights', 'UserMaster', 'update')}
          onClick={() => this.props.onUpdate(user)}
          className="btn btn-sm btn-primary"
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    {
      key: "delete",
      content: (user) => (
        <button
          disabled={!this.props.getRightsForAccess('StaticDataRights', 'UserMaster', 'write') || this.props.user.userType == 'user' || this.props.user._id === user._id}
          onClick={() => this.onDelete(user)}
          className="btn btn-sm btn-danger"

        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  render() {
    return (
      <React.Fragment>   <ToastContainer/>
        {this.props.getRightsForAccess('StaticDataRights', 'UserMaster', 'write') ? <a style={{ cursor: 'pointer' }} className='float-right common-anchor' onClick={() => {
          this.props.history.push('/setting/user/add');
          this.props.handleOnAdd('/setting/user/add', 'add')
        }}> <FontAwesomeIcon size='1x' icon={faPlus} /> Add</a> : ''}
        <div className='text-center'>
          <p>User Master</p>
        </div>
        {this.state.userList.length < 0 ? (
          "Data Not Available"
        ) : (
          <Table
            columns={this.columns}
            sortColumn={this.state.sortColumn}
            onSort={this.handleSort}
            data={
              this.state.userList
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserTable;
