import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import endPoint from "../../../config";
import swal from "sweetalert";
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from "../../../utill/LoadingComponent";
import MultiSelect from "../../../Common/multiselect";
import { ToastContainer, toast } from 'react-toastify';

class Equipment extends Component {
  state = {
    equipment: {},
    oldObj: {},
    equipmentTypeList: [],
    equipmentGroupList: [],
    deviceList: [],
    deviceChannelList:[{DeviceChannel:'1'},{DeviceChannel:'2'},{DeviceChannel:'3'},{DeviceChannel:'4'}],
    deviceConfigList:[{DeviceConfig:'ProductionCounter'},{DeviceConfig:'ProductionEvent'},{DeviceConfig:'ProductionLogger'},{DeviceConfig:'ProductionCounterLogger'}],
    errors: {},
    querySuccess: false,
    deviceList:[]
    
  }

  schema = {
    // PartsPreventiveMaintenance: joi.number().min(0).required().label('PartsPreventiveMaintenance'),
    // PartsSinceLastPreventiveMaintenance: joi.number().min(0).required().label('PartsSinceLastPreventiveMaintenance'),
    // LastPreventiveMaintenanceDate: joi.date().required().label('LastPreventiveMaintenanceDate'),
    // MaxDaysPreventiveMaintenance: joi.number().min(0).required().label('MaxDaysPreventiveMaintenance'),
    DeviceConfig:joi.string().required().label('Device Config'),
    DownTimeThresold: joi.number().required().min(0).label('DownTime Thresold'),
    DeviceChannel: joi.string().required().label('Device Channel'),
    IdealCycleTime: joi.number().required().min(0).label('Ideal Cycle Time'),
    DeviceID: joi.string().required().label('Device ID'),
    EquipmentID: joi.string().required().label('Equipment Id'),
    HourlyRate: joi.string().label('Hourly Rate'),
   
    EquipmentGroup: joi.string().required().label('Equipment Group'),
    EquipmentType: joi.string().required().label('Equipment Type'),
  }



  componentDidMount() {
    this.setState({ querySuccess: true });
    axios.get(endPoint.api + 'equipmentgroup').then((result) => {
      this.setState({ equipmentGroupList: result.data, querySuccess: false })

    })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
    this.setState({ querySuccess: true });
    axios.get(endPoint.api + 'equipmenttype').then((result) => {
      this.setState({ equipmentTypeList: result.data, querySuccess: false })

    })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })

      axios.get(endPoint.api + 'getDeviceList').then((result) => {
        this.setState({ deviceList: result.data, querySuccess: false })
  
      })
        .catch((err) => {
          this.setState({ querySuccess: false });
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        })
   
    if (this.props.actionStatus === 'add') {
      this.setState({ equipment: {} })
    }
    if (this.props.actionStatus === 'update') {
      const equipment = { ...this.props.equipmentData }
      // consol(equipment)
      this.setState({ equipment: this.props.equipmentData, oldObj: equipment });
    }
  }

  handleOnChange = (e) => {
    const { equipment, errors } = { ...this.state };
    const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    equipment[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ equipment, errors: errors || {} });

  }

  getDate = datetime => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };

  handleSubmit = (data) => {


    if (this.props.actionStatus === 'add') {
      const errors = valid.validate(data, this.schema);
      this.setState({ errors: errors || {} });
      if (errors) return;


      this.setState({ querySuccess: true });
      axios.post(endPoint.api1 + 'equipment', data)
        .then((result) => {
          if (result.status === 200) {

            this.setState({ querySuccess: false });
            this.props.history.push('/setting/equipment/list')
            swal('Save Data Successfully');
          }
          else {
            this.setState({ querySuccess: false });
            swal('Data Not saved');
          }
        })
        .catch((err) => {
          // consol(err)
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        })

    }
    if (this.props.actionStatus === 'update') {
      this.setState({ querySuccess: true });
      axios.patch(endPoint.api + 'equipment/' + data._id, { old: this.state.oldObj, new: data})
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.props.history.push('/setting/equipment/list')
            swal('Updated Data Successfully');
          }
          else {
            this.setState({ querySuccess: false });
            swal(`${result.data}`);
          }
        })
        .catch((err) => {

          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        })

    }




  }




  render() {
    // consol(this.state.equipment)

    return (
      <React.Fragment>    <ToastContainer/>

        {this.state.querySuccess ? <Loading /> : ''}


        <div className=" border">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>EquipmentType</label>

                {/* <select
                  className="custom-select form-control"
                  name="EquipmentType"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.EquipmentType}

                >
                  <option>--Select Equipment Type--</option>
                  {this.state.equipmentTypeList.map(one => {
                    return <option>{one.EquipmentType}</option>
                  })}
                </select> */}
                <MultiSelect name="EquipmentType" handleOnChange={this.handleOnChange} masterName="EquipmentType" objLevel={1} all={this.state.equipmentTypeList} selected={this.state.equipment['EquipmentType']} />
                {this.state.errors.EquipmentType && <div className="alert small alert-danger">{this.state.errors['EquipmentType']}</div>}
              </div>


            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Equipment Group</label>

                {/* <select
                  className="custom-select form-control"
                  name="EquipmentGroup"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.EquipmentGroup}

                >
                  <option>--Select Equipment Group--</option>
                  {this.state.equipmentGroupList.map(one => {
                    return <option>{one.EquipmentGroup}</option>
                  })}
                </select> */}
                <MultiSelect name="EquipmentGroup" handleOnChange={this.handleOnChange} masterName="EquipmentGroup" objLevel={1} all={this.state.equipmentGroupList} selected={this.state.equipment['EquipmentGroup']} />

                {this.state.errors.EquipmentGroup && <div className="alert small alert-danger">{this.state.errors['EquipmentGroup']}</div>}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Equipment ID </label>
                <input
                disabled={this.props.actionStatus=='update'?true:false}
                  type="text"
                  maxLength='32'
                  className="form-control"
                  placeholder="Equipment Id "
                  name="EquipmentID"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.EquipmentID}
                />
                {this.state.errors.EquipmentID && <div className="alert small alert-danger">{this.state.errors['EquipmentID']}</div>}
              </div>
            </div>
           

            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Ideal Cyle Time(In Sec)</label>
                <input
                 min='0'
                  type="number"
                  className="form-control"
                  placeholder="Ideal Cycle Time"
                  name="IdealCycleTime"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.IdealCycleTime}

                />
                {this.state.errors.IdealCycleTime && <div className="alert small alert-danger">{this.state.errors['IdealCycleTime']}</div>}
              </div>

           
            </div>
            <div className="col-sm-4">
            <div className="form-group text-center">
                <label>Device ID</label>

                {/* <select
                  className="custom-select form-control"
                  name="DeviceID"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceID}

                >
                  <option>--Select Device ID--</option>
                  {this.state.deviceList.map(device => {
                    return <option>{device}</option>
                  })}
                </select> */}
                <MultiSelect name="DeviceID" handleOnChange={this.handleOnChange} masterName="DeviceID" objLevel={0} all={this.state.deviceList} selected={this.state.equipment['DeviceID']} />

                {this.state.errors.DeviceID && <div className="alert small alert-danger">{this.state.errors['DeviceID']}</div>}
              </div>
           
            </div>
            <div className="col-sm-4">
            <div className="form-group text-center">
                <label>Device Channel</label>

                <select
                  className="custom-select form-control"
                  name="DeviceChannel"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceChannel}
                >
                  <option>--Select Device Channel--</option>
                  {this.state.deviceChannelList.map(one => {
                    return <option>{one.DeviceChannel}</option>
                  })}
                </select>
                {this.state.errors.DeviceChannel && <div className="alert small alert-danger">{this.state.errors['DeviceChannel']}</div>}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Downtime Thresold(In Sec)</label>
                <input
                min='0'
                  type="number"
                  className="form-control"
                  placeholder="Enter DownTime Thresold In Minutes"
                  name="DownTimeThresold"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.DownTimeThresold}

                />
                {this.state.errors.DownTimeThresold && <div className="alert small alert-danger">{this.state.errors['DownTimeThresold']}</div>}
              </div>

           
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Device Configuration</label>
                <select
                  className="custom-select form-control"
                  name="DeviceConfig"
                  disabled={this.props.actionStatus=='update'?true:false}
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceConfig}
                >
                  <option>--Select Device Configuration--</option>
                  {this.state.deviceConfigList.map(one => {
                    return <option>{one.DeviceConfig}</option>
                  })}
                </select>
                {this.state.errors.DeviceConfig && <div className="alert small alert-danger">{this.state.errors['DeviceConfig']}</div>}
              </div>

           
            </div>
          
      
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit(this.state.equipment);
              }}
            >
              Submit
            </button>
          </div>

          <div className="text-center" style={{ marginTop: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.history.push('/setting/equipment/list')
              }}
            >
              Cancel
                </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Equipment;
