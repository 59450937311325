import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _, { isNumber } from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import 'bootstrap/dist/js/bootstrap'
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import { Chart } from 'react-google-charts';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const MachineStateEventLog = ({ user, equipGroups,equips,fromDateLimit }) => {
    const [availabilityData, setAvailabilityData] = useState([])
    const [inputData, setInputData] = useState({})
    const [equip, setEquip] = useState([])
    const [equip1, SetEquips] = useState([])
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false)
    const [chartData, setChartData] = useState([]);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })


    const setSelection=(key,option,equips)=>{
        if(key=='EquipmentGroup'){
            SetEquips(equips?equips:[]);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if(key=='Equipment'){
           
            setSelectedEquipOption(option)
        }
    }

 const getEquipsForReport=()=>{
        let equipArr=[]
        equips.forEach((one)=>{
            selectedEquipOption.forEach((option)=>{
                if(one._id==option.value){
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }

    const schema = {
        From: joi.date().required().label('From'),
        Till: joi.date().required().label('Till'),
        Equips:joi.array(),
        UserId: joi.string(),
    }
    const columns = [
        { path: 'From', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
        { path: 'From', label: 'From', content: (obj) => <label>{date.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till', content: (obj) => <label>{date.getTime(obj.Till)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'EquipmentGroup', label: 'EquipmentGroup'},
        { path: 'Equipment', label: 'Equipment'},
        { path: 'State', label: 'State'},
        { path: 'DowntimeGroup', label: 'Downtime Group',content:(obj)=><label>{obj.DowntimeGroup?obj.DowntimeGroup:'-'}</label>},
        { path: 'DowntimeReason', label: 'Downtime Reason',content:(obj)=><label>{obj.DowntimeReason?obj.DowntimeReason:'-'}</label>},
        { path: 'Remarks', label: 'Remarks',content:(obj)=><label>{obj.Remarks?obj.Remarks:'-'}</label>},
        { path: 'Duration', label: 'Duration (min)',content:(obj)=><label>{obj.Duration?((obj.Duration)/60).toFixed(1):'-'}</label>},
          ]


    const handleOnChange = async (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'EquipmentGroup') {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            let filterObj = equipGroups.filter(one => one.EquipmentGroup == e.currentTarget.value)[0]
            let res = await axios.put(api.api1 + 'equipment', { EquipmentGroup: filterObj._id })
            setEquip(res.data)

            setInputData(inputData);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            setInputData(inputData);
        }
        setErrors({})

    }
    const handleOnSubmit = (inputData) => {
        // inputData.Equipment=selectedEquipOption;
        // inputData.EquipmentGroup=selectedEquipGroupOption;
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
      
        inputData.UserId = user._id;
        
        if(new Date(inputData.From) <= new Date(inputData.Till)){
        }else{
            setQuerySuccess(false);
           
            return  toast.success('From date should be Less Than Till date', {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: '#babbbb', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
          }
        inputData.Equips=getEquipsForReport()
        axios.post(api.api + 'MachineStateEventLog', inputData).then((result) => {
            // consol(result.data)
            if (result.data.length > 0) {
              
                setAvailabilityData(result.data)
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }
    const handleDonwLoad = (e, date) => {
        generateExcelFile(e, date)
    }

    const handleSort = (sortColumn) => {
        if (availabilityData.length > 0) {
            const sorted = _.orderBy(
                availabilityData,
                [sortColumn.path],
                [sortColumn.order]
            );
            setAvailabilityData(sorted)
            setSortColumn(sortColumn)
        }
    }

   

   


    const chartRender=(data)=>{
        let mac='';
        selectedEquipOption.forEach(one=>{
            if(one.value!=='All'){
                mac=mac+' , '+one.label;
            }
        })
       

       return  <Chart
        width={'100%'}
        height={'500px'}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          // Material design options
          chart: {
            title: 'Availability Trend',
            subtitle: 'Trend Report for '+mac,
          },
          
          hAxis: {
                            slantedText: true,
                            slantedTextAngle: 90,
                            textStyle: { fontSize: 13 },
                           
                        },
        }}
        // For tests
        rootProps={{ 'data-testid': '2' }}
      />
    }


  
    const SelectedDates =(dates)=>{
        setInputData(dates)
      }

    return (
        <React.Fragment>
            {querySuccess ? <Loading /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Machine Event Log
                </h4>
            </div>
      <ToastContainer/>

            <div className="row ml-2">
               
           {equipGroups.length  > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip1} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
               
{/* 
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                   
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                   
                </div> */}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

                {/* <div className="col-sm-1">
                    <div className='form-group text-center'>
                        <label className='small'>Periodicity</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Periodicity'>
                        <option>--Select Periodicity--</option>
                            <option>Shift</option>
                            <option>Daily</option>
                            <option>Weekly</option>
                            <option>Monthly</option>
                        </select>
                        {errors.Periodicity && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Periodicity}</p>}
                    </div>
                </div> */}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className="col-sm-2">
                    {availabilityData.length > 0 ? 
                        <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('MachineStateEventLog')}>Download Excel</button>
                    : ''}
                </div>

            </div>
            <div>
                {/* {availabilityData.length > 0 ?chartRender(chartData): ''} */}
                <div>

                    {availabilityData.length > 0 ? <Table id='MachineStateEventLog' onSort={handleSort} columns={columns} sortColumn={sortColumn} data={availabilityData} /> : ''}
                </div>
            </div>
        </React.Fragment>


    );
}

export default MachineStateEventLog;