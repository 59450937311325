import React, { Component } from 'react';
import Table from '../../../Common/table';
//import '../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import dateTime from '../../../utill/dateTime';
import  swal  from 'sweetalert';
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import _ from 'lodash'
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';

class ShiftSettingTable extends Component {

    state = {
        shiftSettingList:[],
        sortColumn:{path:'From',order:'asc'},
        querySuccess:false,
    }

    handleSort = sortColumn => {
      // // consol(sortColumn);
      if(this.state.shiftSettingList.length>0)
      {
          const sorted = _.orderBy(this.state.shiftSettingList, [sortColumn.path], [sortColumn.order]);
          this.setState({ sortColumn,shiftSettingList:sorted });
      }
  }

  convertShiftISOTimeToIST=(shift)=>{
      let shiftKeys=Object.keys(shift)
      let shiftDoc={...shift}
      shiftKeys.forEach((key)=>{
        if(key=='From'||key=='Till'||key=='EffectiveFrom'||key=='EffectiveTill'||
        key=='Shift1StartTime'||key=='Shift2StartTime'||key=='Shift3StartTime'||
        key=='Shift1EndTime'||key=='Shift2EndTime'||key=='Shift3EndTime')
        {
          shiftDoc[key]=new Date(shiftDoc[key])
        }
        if(key=='Shift1Breaks'||key=='Shift2Breaks'||key=='Shift3Breaks'){
          shiftDoc[key].forEach((br,i)=>{
            let obj=shiftDoc[key].filter(one=>one.key==br.key)[0]
            obj.BreakStart=new Date(obj.BreakStart)
            obj.BreakEnd=new Date(obj.BreakEnd)
            shiftDoc[key][i]=obj
          })
        }
      })
      return shiftDoc
  }


    componentDidMount()
    {
      this.setState({querySuccess:true});
        axios.get(api.api+'shift')
        .then((result)=>{
          // // consol(result.data)
         if(result.data.length>0)
         {
           let arr=[]
           result.data.forEach(one=>{
             let shiftData=this.convertShiftISOTimeToIST(one)
             arr.push(shiftData)
           })
           this.setState({shiftSettingList:arr,querySuccess:false});
         }
         else{
          this.setState({querySuccess:false})
        }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });

        })
        

    }
    getTime=(datetime)=>{
        if(datetime)
        {
            // // consol(dateTime)
          let Time=new Date(datetime)
          return (Time.getHours()<10?'0':'')+Time.getHours()+':'+(Time.getMinutes()<10?'0':'')+Time.getMinutes();
        }
        
    
      }

    onDelete=(data)=>{
       
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            this.setState({querySuccess:true});
            axios
              .delete(api.api + "shift/"+data._id, { data: data})
              .then(result => {
                // // consol(result.data)
                if (result.data.ok === 1 && result.data.deletedCount === 1) {
                    let filterData = this.state.shiftSettingList.filter(
                        obj => obj._id !== data._id
                      );
                      this.setState({ shiftSettingList: filterData,querySuccess:false });
                }
              })
              .catch(err => {
                this.setState({querySuccess:false});
                swal(`${err.response.data.message}`);
              });
          } else {
            // swal("Your imaginary file is safe!");
          }
        });
 
     


}
getDate = datetime => {
  if (datetime) {
    let date = new Date(datetime);
    return (
      date.getFullYear() +
      "-" +
      ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
      "-" +
      ((date.getDate() < 10 ? "0" : "") + date.getDate())
    );
  }
};

    columns = [
        {key:'From',label:'From',content: (obj) => <React.Fragment><label>{this.getDate(obj.From)}</label><br/></React.Fragment>},
        {key:'Till',label:'Till',content: (obj) => <React.Fragment><label>{this.getDate(obj.Till)}</label><br/></React.Fragment>},

        //Shift1
        {path:'MidNightShift',label:'Midnight Shift'},
        {path:'WeeklyHolidays',label:'Weekly Holidays'},
        {key:'Shift1Enable',label:'Shift 1 Enable',content:(obj)=><label>{obj.Shift1Enable?obj.Shift1Enable.toString():'-'}</label>},
        {key:'Shift1StartTime',label:'Shift 1 Start Time',content:(obj)=><label>{this.getTime(obj.Shift1StartTime)}</label>},
        {key:'Shift1EndTime',label:'Shift 1 End Time',content:(obj)=><label>{this.getTime(obj.Shift1EndTime)}</label>},
        // {path:'Shift1Break1Start',label:'Shift 1 Break 1 Start'},
        // {path:'Shift1Break1End',label:'Shift 1 Break 1 End'},
        // {path:'Shift1Break2Start',label:'Shift 1 Break 2 Start'},
        // {path:'Shift1Break2End',label:'Shift 1 Break 2End'},
        // {path:'Shift1Break3Start',label:'Shift 1 Break 3 Start'},
        // {path:'Shift1Break3End',label:'Shift 1 Break 3 End'},
        // {path:'Shift1Break4Start',label:'Shift 1 Break 4 Start'},
        // {path:'Shift1Break4End',label:'Shift 1 Break 4 End'},
        // {path:'Shift1Break5Start',label:'Shift 1 Break 5 Start'},
        // {path:'Shift1Break5End',label:'Shift 1 Break 5 End'},


        //Shift2
        {key:'Shift2Enable',label:'Shift 2 Enable',content:(obj)=><label>{obj.Shift2Enable?obj.Shift2Enable.toString():'-'}</label>},
        {path:'Shift2StartTime',label:'Shift 2 Start Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift2StartTime)}</label><br/></React.Fragment>},
        {path:'Shift2EndTime',label:'Shift 2 End Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift2EndTime)}</label><br/></React.Fragment>},
        // {path:'Shift2Break1Start',label:'Shift 2 Break 1 Start'},
        // {path:'Shift2Break1End',label:'Shift 2 Break 1 End'},
        // {path:'Shift2Break2Start',label:'Shift 2 Break 2 Start'},
        // {path:'Shift2Break2End',label:'Shift 2 Break 2 End'},
        // {path:'Shift2Break3Start',label:'Shift 2 Break 3 Start'},
        // {path:'Shift2Break3End',label:'Shift 2 Break 3 End'},
        // {path:'Shift2Break4Start',label:'Shift 2 Break 4 Start'},
        // {path:'Shift2Break4End',label:'Shift 2 Break 4 End'},
        // {path:'Shift2Break5Start',label:'Shift 2 Break 5 Start'},
        // {path:'Shift2Break5End',label:'Shift 2 Break 5 End'},

        //Shift3
        {key:'Shift3Enable',label:'Shift 3 Enable',content:(obj)=><label>{obj.Shift3Enable?obj.Shift3Enable.toString():'-'}</label>},
        {path:'Shift3StartTime',label:'Shift 3 Start Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift3StartTime)}</label><br/></React.Fragment>},
        {path:'Shift3EndTime',label:'Shift 3 End Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift3EndTime)}</label><br/></React.Fragment>},
        // {path:'Shift3Break1Start',label:'Shift 3 Break 1 Start'},
        // {path:'Shift3Break1End',label:'Shift 3 Break 1 End'},
        // {path:'Shift3Break2Start',label:'Shift 3 Break 2 Start'},
        // {path:'Shift3Break2End',label:'Shift 3 Break 2 End'},
        // {path:'Shift3Break3Start',label:'Shift 3 Break 3 Start'},
        // {path:'Shift3Break3End',label:'Shift 3 Break 3 End'},
        // {path:'Shift3Break4Start',label:'Shift 3 Break 4 Start'},
        // {path:'Shift3Break4End',label:'Shift 3 Break 4 End'},
        // {path:'Shift3Break5Start',label:'Shift 3 Break 5 Start'},
        // {path:'Shift3Break5End',label:'Shift 3 Break 5 End'},


        {key:'Shift4Enable',label:'Shift 4 Enable',content:(obj)=><label>{obj.Shift4Enable?obj.Shift4Enable.toString():'-'}</label>},
        {path:'Shift4StartTime',label:'Shift 4 Start Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift4StartTime)}</label><br/></React.Fragment>},
        {path:'Shift4EndTime',label:'Shift 4 End Time',content: (obj) => <React.Fragment><label>{this.getTime(obj.Shift4EndTime)}</label><br/></React.Fragment>},
       
      
      
        { key: 'createdAt', label: 'Created Date',content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.createdAt)}</label><br/></React.Fragment> },
        { key: 'updatedAt', label: 'Last Updated Date',content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label><br/></React.Fragment>  },
        { key: 'update', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights','ShiftMaster','update')} onClick={() => this.props.onUpdate(user)} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEdit} /></button> },
        // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
        { key: 'delete', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights','ShiftMaster','write')} onClick={() => this.onDelete(user)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button> }

    ]


    render() {
       

        return (
            <React.Fragment>   <ToastContainer/>
            {this.state.querySuccess?<Loading/>:''}
            <div className='text-center'>
                   <p>Shift Master</p>
                 </div>
      
               {this.props.getRightsForAccess('StaticDataRights','ShiftMaster','write')? <a style={{cursor:'pointer'}} className='float-right common-anchor' 
                onClick={()=>{this.props.history.push('/setting/shiftsetting/add');
                this.props.handleOnAdd('/setting/shiftsetting/add','add','shiftSettingList',
                this.state.shiftSettingList)}}> <FontAwesomeIcon size='1x' icon={faPlus}/> Add</a>:''}
                {this.state.shiftSettingList.length <0?<label>Data Not Available</label>:<Table columns={this.columns} onSort={this.handleSort} sortColumn={this.state.sortColumn} data={this.state.shiftSettingList} />}
            </React.Fragment>

        );
    }
}

export default ShiftSettingTable;
