import React, { Component } from "react";
import MultiRadialChart from "../../utill/MultiRadialChart";
import PieChart from "../../utill/PieChartAVEP";
import MachineDetailedPage from "../../utill/MachineDetailedPage";
import api from '../../config.json';
import BatchSize from '../../config.json';
import axios from '../../httpServices/httpService';
import auth from '../../httpServices/authService';
import swal from 'sweetalert';
import pako from 'pako'
import {
    faSignOutAlt, faSignal

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from "joi/lib/types/symbol";
import Loading from "../../utill/LoadingComponent";
import { data } from "jquery";
import { Modal, Switch } from "antd";

class AndonDashboard extends Component {

    state = {
        array: [],
        modelState: false,
        singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }]],
        querySuccess: false,
        selectedCard: {},
        filterObj: {},
        filterBy:{},
        jobTableData: [],
        andOnDasboardObjOrder: {
            Shift: 0, Equipment: 1, ConnStatus: 2, State: 3,
            OperatorName: 4, Component: 5, Operation: 6, Tool: 7, CustomerName: 8, TotalCycles: 9, TargetParts: 10, PartsProduced: 11, BalanceParts: 12, Availability: 13, Performance: 14, QualityFactor: 15, OEE: 16,ComponentAlias:17,TotalDownTime:18,
            JobAverageSPM: 19, JobAverageCycleTime: 20,ShiftAverageSPM:21,ShiftAverageCycleTime:22,BatchNumber:23,

        },
        data: [],
        configObj: JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("andon")))),
        sortby:  [{
          key:'Shift',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'Equipment',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'ConnStatus',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'State',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'OperatorName',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'Component',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'Operation',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'Tool',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'CustomerName',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'TotalCycles',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'TargetParts',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'PartsProduced',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'BalanceParts',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'Availability',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'Performance',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'QualityFactor',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'OEE',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'ComponentAlias',
          value: false,
          sortby:false,
          type:'string',
        },{
          key:'TotalDownTime',
          value: false,
          sortby:false,
          type:'number',
        },{
          key:'JobAverageSPM',
          value: false,
          sortby:false,
          type:'number',
        }
        ,{
          key:'JobAverageCycleTime',
          value: false,
          sortby:false,
          type:'number',
        }
        ,{
          key:'ShiftAverageSPM',
          value: false,
          sortby:false,
          type:'number',
        },
        {
          key:'ShiftAverageCycleTime',
          value: false,
          sortby:false,
          type:'number',
        },
        {
          key:'BatchNumber',
          value: false,
          sortby:false,
          type:'number',
        },
        
      ]

    }

    MyInterval;


    ScreensInterval;

    getScrollData = (data) => {
        let scrollObj = localStorage.getItem('andon_state')
        if (scrollObj) {
            let obj = JSON.parse(localStorage.getItem('andon_state'));
            if (obj.AutoScroll) {
                data = [...data]
                if (obj.DisplayFor && (obj.DisplayFor !== "") && obj.NumberOfMachine && (obj.NumberOfMachine !== "")) {
                    let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
                    if ((endIndex > (data.length - 1))) {
                        data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                        localStorage.setItem("startIndex", 0);
                    } else {
                        data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                        localStorage.setItem("startIndex", endIndex);
                    }

                }
            }

        }
        return data;
    }
    getFilterData = (data, filterKey) => {
        let arr=[...data];
        data=[]
        Object.keys(this.props.filterBy).forEach((key)=>{
          let filterData = arr.filter(one =>((one.EquipmentGroup == key)&&(this.props.filterBy[key])))
          data=[...data,...filterData];
        })

        if (filterKey.SortBy == 'Descending') {
          data = data.sort(function (a, b) {
            if (a.Equipment < b.Equipment) {
              return 1;
            }
            else if (a.Equipment > b.Equipment) {
              return -1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Ascending') {
          data = data.sort(function (a, b) {
            if (a.Equipment < b.Equipment) {
              return -1;
            }
            else if (a.Equipment > b.Equipment) {
              return 1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Running On Top') {
          data = data.sort(function (a, b) {
            if (a.State == 'Running') {
              return -1;
            }
            else if (a.State !== 'Running') {
              return 1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Not Running On Top') {
          data = data.sort(function (a, b) {
            if (a.State !== 'Running') {
              return -1;
            }
            else if (a.State == 'Running') {
              return 1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'OEE Descending') {
          data = data.sort(function (a, b) {
            if (a.OEE < b.OEE) {
              return 1;
            }
            else if (a.OEE > b.OEE) {
              return -1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'OEE Ascending') {
          data = data.sort(function (a, b) {
            if (a.OEE < b.OEE) {
              return -1;
            }
            else if (a.OEE > b.OEE) {
              return 1;
            }
            return 0;
          });
        }
    
        if (filterKey.SortBy == 'Availability Descending') {
          data = data.sort(function (a, b) {
            if (a.Availability < b.Availability) {
              return 1;
            }
            else if (a.Availability > b.Availability) {
              return -1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Availability Ascending') {
          data = data.sort(function (a, b) {
            if (a.Availability < b.Availability) {
              return -1;
            }
            else if (a.Availability > b.Availability) {
              return 1;
            }
            return 0;
          });
        }
    
        if (filterKey.SortBy == 'Performance Descending') {
          data = data.sort(function (a, b) {
            if (a.Performance < b.Performance) {
              return 1;
            }
            else if (a.Performance > b.Performance) {
              return -1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Performance Ascending') {
          data = data.sort(function (a, b) {
            if (a.Performance < b.Performance) {
              return -1;
            }
            else if (a.Performance > b.Performance) {
              return 1;
            }
            return 0;
          });
        }
    
        if (filterKey.SortBy == 'Quality Descending') {
          data = data.sort(function (a, b) {
            if (a.Quality < b.Quality) {
              return 1;
            }
            else if (a.Quality > b.Quality) {
              return -1;
            }
            return 0;
          });
        }
        if (filterKey.SortBy == 'Quality Ascending') {
          data = data.sort(function (a, b) {
            if (a.Quality < b.Quality) {
              return -1;
            }
            else if (a.Quality > b.Quality) {
              return 1;
            }
            return 0;
          });
        }
    
        return data
      }
    
    displayFor = () => {
        let autoScrollObj = localStorage.getItem("andon_state");
        if (autoScrollObj) {
            autoScrollObj = JSON.parse(autoScrollObj);
            if (autoScrollObj.DisplayFor && (autoScrollObj.DisplayFor != "") && autoScrollObj.NumberOfMachine && (autoScrollObj.NumberOfMachine != "")) {
                this.ScreensInterval = setInterval(() => {
                    let obj = JSON.parse(localStorage.getItem('andon_state'));
                    if (obj.AutoScroll) {
                        let data = [...this.props.array]
                        if (obj.DisplayFor && (obj.DisplayFor !== "")) {
                            let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
                            if ((endIndex > (data.length - 1))) {
                                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                                localStorage.setItem("startIndex", 0);
                            } else {
                                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                                localStorage.setItem("startIndex", endIndex);
                            }

                        } else {
                            data = data.slice(0, parseInt(autoScrollObj.NumberOfMachine))
                        }

                        this.setState({ data: data, autoScrolObj: JSON.parse(localStorage.getItem("andon_state")) }, () => {
                            if ((JSON.stringify(this.state.autoScrolObj) !== localStorage.getItem('andon_state'))) {
                                this.ScreensInterval = null;
                                clearInterval(this.ScreensInterval);
                                this.displayFor();
                            }
                        });
                    } else {
                        this.ScreensInterval = null;
                        clearInterval(this.ScreensInterval);
                        this.setState({ data: this.props.array })
                    }

                }, (parseInt(autoScrollObj.DisplayFor) * 1000))
            }
        }

    }

    async componentDidMount() {
       /////////////////////////////////////////////// Change batchSize to vary the amount of equipment send through Api /////////////////////////////////////
       const batchSize = Number(BatchSize.BatchSize);
let equips;
        localStorage.setItem("startIndex", 0);
        if (this.props.user) {
            const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
            if (storedEquipGroupsData) {
                // Data exists in local storage, use it
                const storedEquipGroups = JSON.parse(storedEquipGroupsData);
                equips = await storedEquipGroups
                
                this.setState({querySuccess: false });
              } 
              // equips = equips.filter(one=>one.EquipmentID=="bm-03")
            // let equips = await axios.get(api.api1 + 'equipment');  //// getting equipment from api every 60 seconds and  when ever the page is reloaded
            this.setState({ querySuccess: true })
                
      for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
        let startIndex = i * batchSize;
        let endIndex = (i + 1) * batchSize;
        let senddata = equips.slice(startIndex,endIndex)
        ////////////////// compressed data with pako ///////////////
        const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
        try {
                    // let result = await axios.put(api.api1 + 'GetAndonViewDashboard', { UserId: this.props.user._id, Equips: [equips.data[i]] })
                    let result = await  axios
                    .put(api.api1 + "GetAndonViewDashboard",compressedData, {
                      headers: {
                        "Content-Encoding": "gzip", // Set the content encoding to gzip
                        "Content-Type": "application/json", // Set the content type to JSON
                      },
                    })
          /////////////////////////// de compressing the recived result  data ////////////
          
          const decompressedData = pako.ungzip(result.data,{to:'string'})
          
          ///////////////////////////// conevrting sting into json ///////////
          const jsonData = JSON.parse(decompressedData)
                    let array = [...this.state.array];
                    array = [...array, ...jsonData];
                    result = this.props.getData(array);
                    this.setState({ data: this.props.array, array: array, filterObj: this.props.filterObj });
                }
                catch (err) {
                    this.setState({ querySuccess: false })
                    if (err.response && err.response.data && (err.response.data.code == 901)) {
                        swal(`${err.response.data.message}`)
                    } else {
                        swal(`${err}`)
                    }
                }

            }
            this.setState({ querySuccess: false })
        }
        this.MyInterval = setInterval(async () => {
            if (this.props.user) {
                let array = [...this.state.array];
                let result;

                const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
                if (storedEquipGroupsData) {
                    // Data exists in local storage, use it
                    const storedEquipGroups = JSON.parse(storedEquipGroupsData);
                    equips = await storedEquipGroups
                    
                    this.setState({querySuccess: false });
                  } 


                // equips = equips.filter(one=>one.EquipmentID=="bm-03")

      for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
        let startIndex = i * batchSize;
        let endIndex = (i + 1) * batchSize;
        let senddata = equips.slice(startIndex,endIndex)
        ////////////////// compressed data with pako ///////////////
        const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
                    try {

                                  ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
let result = await  axios
.put(api.api1 + "GetAndonViewDashboard",compressedData, {
  headers: {
    "Content-Encoding": "gzip", // Set the content encoding to gzip
    "Content-Type": "application/json", // Set the content type to JSON
  },
})
/////////////////////////// de compressing the recived result  data ////////////

const decompressedData = pako.ungzip(result.data,{to:'string'})

///////////////////////////// conevrting sting into json ///////////
const jsonData = JSON.parse(decompressedData)


                        // result = await axios.put(api.api1 + 'GetAndonViewDashboard', { UserId: this.props.user._id, Equips: [equips.data[i]] })
                        if (jsonData.length > 0) {
                            array[i] = jsonData[0];
                        }

                    }
                    catch (err) {
                        this.setState({ querySuccess: false })
                        if (err.response && err.response.data && (err.response.data.code == 901)) {
                            swal(`${err.response.data.message}`)
                        } else {
                            swal(`${err}`)
                        }
                    }

                }
                // result = this.props.getData(array);

                // this.setState({ data: this.getScrollData(this.props.array), array: array, filterObj: this.props.filterObj });
                 result = this.getFilterData(this.state.array, this.props.filterObj)
                 let filter=this.state.configObj;
            let filtereddata = this.filterObjects(result,filter)
                this.setState({ data: this.getScrollData(filtereddata),filterBy:this.props.filterBy,array: array, filterObj: this.props.filterObj, filterObj: this.props.filterObj, querySuccess: false });
                // axios.put(api.api1 + 'GetAndonViewDashboard', { UserId: this.props.user._id,Equips:this.props.equipList })
                //     .then((result) => {
                //         // result = this.getFilterData(result.data, this.props.filterObj)
                //         result = this.props.getData(result.data);
                //         this.setState({ array: result, filterObj: this.props.filterObj, querySuccess: false });
                //     })
                //     .catch((err) => {
                //         this.setState({ querySuccess: false })
                //           toast.success(`${err}`, {
    
                //     })
            }
        }, 120000)

        this.displayFor()

    }
    filterObjects(arrayOfObjects, config) {
      return arrayOfObjects.map(obj => {
        const filteredObj = {};
        Object.keys(obj).forEach(key => {
          if (config[key]) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      });
    }
    componentDidUpdate() {
        // if (JSON.stringify(this.state.configObj) !== JSON.stringify(this.props.configObj)) {
        //     this.setState({ data: this.getScrollData(this.props.array), configObj: this.props.configObj });

        // }
        if (JSON.stringify(this.state.configObj) !== JSON.stringify(this.props.configObj)||(JSON.stringify(this.state.filterBy) !== JSON.stringify(this.props.filterBy)) || (this.state.filterObj.SortBy !== this.props.filterObj.SortBy)) {
            let result = this.getFilterData(this.state.array, this.props.filterObj)
            let filter=this.state.configObj;
            let filtereddata = this.filterObjects(result,filter)
            this.setState({ data: this.getScrollData(filtereddata),filterBy:this.props.filterBy,configObj: this.props.configObj, filterObj: this.props.filterObj, querySuccess: false });
          
        }
        // if (this.state.autoScrolObj&&JSON.stringify(this.state.autoScrolObj) !== JSON.stringify(this.props.autoScrollObj)) {
        //     clearInterval(this.ScreensInterval)
        //     this.ScreensInterval=null
        //     // this.setState({autoScrolObj:this.props.autoScrollObj})
        //    this.displayFor()

        // }
    }

    componentWillUnmount() {
        clearInterval(this.MyInterval)
        clearInterval(this.ScreensInterval)
    }

    // getFilterData = (data, filterKey) => {
    //     if (filterKey.FilterBy !== 'All') {
    //         data = data.filter(one => one.EquipmentGroup == filterKey.FilterBy)
    //     }
    //     if (filterKey.SortBy == 'Descending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Equipment < b.Equipment) {
    //                 return 1;
    //             }
    //             else if (a.Equipment > b.Equipment) {
    //                 return -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Ascending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Equipment < b.Equipment) {
    //                 return -1;
    //             }
    //             else if (a.Equipment > b.Equipment) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Running On Top') {
    //         data = data.sort(function (a, b) {
    //             if (a.State == 'Running') {
    //                 return -1;
    //             }
    //             else if (a.State !== 'Running') {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Not Running On Top') {
    //         data = data.sort(function (a, b) {
    //             if (a.State !== 'Running') {
    //                 return -1;
    //             }
    //             else if (a.State == 'Running') {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'OEE Descending') {
    //         data = data.sort(function (a, b) {
    //             if (a.OEE < b.OEE) {
    //                 return 1;
    //             }
    //             else if (a.OEE > b.OEE) {
    //                 return -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'OEE Ascending') {
    //         data = data.sort(function (a, b) {
    //             if (a.OEE < b.OEE) {
    //                 return -1;
    //             }
    //             else if (a.OEE > b.OEE) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }

    //     if (filterKey.SortBy == 'Availability Descending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Availability < b.Availability) {
    //                 return 1;
    //             }
    //             else if (a.Availability > b.Availability) {
    //                 return -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Availability Ascending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Availability < b.Availability) {
    //                 return -1;
    //             }
    //             else if (a.Availability > b.Availability) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }

    //     if (filterKey.SortBy == 'Performance Descending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Performance < b.Performance) {
    //                 return 1;
    //             }
    //             else if (a.Performance > b.Performance) {
    //                 return -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Performance Ascending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Performance < b.Performance) {
    //                 return -1;
    //             }
    //             else if (a.Performance > b.Performance) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }

    //     if (filterKey.SortBy == 'Quality Descending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Quality < b.Quality) {
    //                 return 1;
    //             }
    //             else if (a.Quality > b.Quality) {
    //                 return -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     if (filterKey.SortBy == 'Quality Ascending') {
    //         data = data.sort(function (a, b) {
    //             if (a.Quality < b.Quality) {
    //                 return -1;
    //             }
    //             else if (a.Quality > b.Quality) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //     }

    //     return data
    // }

    handleClickOnSingleCard = (one) => {
        one.DateTime = one.From
        axios.put(api.api1 + 'GetCardViewDashboardForLiveViewForEquipment', { Shift: one.Shift, DateTime: one.From, Equipment: one.Equipment })
            .then((result) => {
                const { singleMachineData } = { ...this.state }
                result.data.forEach(element => {
                    let arr = []
                    arr.push(new Date(element.DateTime))
                    if (one.DeviceConfig == 'ProductionCounter') {
                        arr.push(element.Count);
                    }
                    if (one.DeviceConfig == 'ProductionEvent') {
                        arr.push(1);
                    }

                    let color = one.State == 'Running' ? '#0F9D58' : one.ConnStatus == 'offline' ? '#F4B400' : '#DB4437'
                    arr.push('#4285F4')
                    singleMachineData.push(arr)
                });
                this.setState({ modelState: true, singleMachineData: singleMachineData, selectedCard: one, querySuccess: false });
            })
            .catch((err) => {
                this.setState({ querySuccess: false })
                swal(`${err}`)
            })



        axios.post(api.api1 + 'JobRunsReportForEquipmentForMachineActvity', { Shift: one.Shift, DateTime: one.From, Equipment: one.Equipment })
            .then((result) => {

                this.setState({ jobTableData: result.data });
            })
            .catch((err) => {
                this.setState({ querySuccess: false })
                swal(`${err}`)
            })

    }
    onHide = () => {
        this.setState({ selectedCard: {}, modelState: false, singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }]] });
    }




    //   handleRenderMachineDetailed = (data) => {
    //     if (data.length <= 0) {
    //       swal('No data available in this shift')
    //       return
    //     } else {
    //       return <MachineDetailedPage modelState={this.state.modelState} jobTableData={this.state.jobTableData} cardData={this.state.selectedCard} singleMachineData={this.state.singleMachineData} setModelState={this.onHide} />
    //     }
    //   }


    getColorForCarHeader = (one) => {
        let obj = {}
        if (one.State == 'Running') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#0F9D58', padding: 0 }
            }

        }
        else if (one.State == 'Down') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#DB4437', padding: 0 }
            }

        }
        else if (one.State == 'Break') {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#F4B400', padding: 0 }
            }
        }
        else {
            if (one.ConnStatus == 'offline') {
                obj = { backgroundColor: '#bbb291', padding: 0 }
            }
            if (one.ConnStatus == 'online') {
                obj = { backgroundColor: '#DB4437', padding: 0 }
            }
        }
        return obj;
    }

    getObjectKeysSorted = (one) => {
        const sortOrder = { ...this.state.andOnDasboardObjOrder }
        let keys = Object.keys(one);
        let arr = []
        keys.forEach((key, index) => {
            let obj = {}
            obj['field'] = key;
            obj['order'] = sortOrder[key];
            arr.push(obj);
        })
        arr.sort((a, b) => a.order - b.order);
        return arr;
    }

    getRenderRow = (key, one) => {
        if (one.DeviceConfig == 'ProductionLogger') {
            if (key.field == 'TargetParts' || key.field == 'PartsProduced' || key.field == 'TotalCycles' || key.field == 'DeviceConfig' || key.field == 'BalanceParts' || key.field == 'ComponentAlias' || key.field == 'TotalDownTime') {
                return ''
            } else {
                return <tr className='p-0 m-0'>
                    <td className="pl-1 font-weight-bold text-left">{this.getFieldWithPercentage(key.field)}</td>
                    <td className="pr-1 text-right">
                        <div className='font-weight-bold'>{key.field == 'Tool' && one[key.field] == 'NoJob' ? 'No Tool' : one[key.field] || ' - '}</div>
                    </td>
                </tr>


            }

        } else {
            if (key.field !== 'DeviceConfig') {
                return <tr className='p-0 m-0'>
                    <td className="pl-1 font-weight-bold text-left">{this.getFieldWithPercentage(key.field)}</td>
                    <td className="pr-1 text-right">
                        <div className='font-weight-bold'>{key.field == 'Tool' && one[key.field] == 'NoJob' ? 'No Tool' : one[key.field] || ' - '}</div>
                    </td>
                </tr>
            }

        }

  }
  setSpaceCamelCaseTex = (str) => {
    const text = str;
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  getFieldWithPercentage = (key) => {
    if (key == 'Availability' || key == 'Performance' || key == 'QualityFactor' || key == 'OEE') {
      return this.setSpaceCamelCaseTex(key) + ' (%)'
    } else {
      return this.setSpaceCamelCaseTex(key)
    }

    }

    handelsortby =(e,key)=>{
      
      const data = [...this.state.sortby]
      
        const updatedData = data.map(item => ({
          ...item,
          value: item.key === key,
          sortby: item.key === key

        }));
        if( this.state.data&& this.state.data.length>0 ){
          const test =  this.state.data.filter(item => item[key] )
          if( test && test.length>0){
            this.handelsortcards(this.state.data,updatedData)
          }else{
            return swal('this field is not selected for display')
          }
          }
        this.setState({sortby:updatedData});
        localStorage.setItem("filters", JSON.stringify(updatedData));
      // console.log(e.currentTarget.checked,key,updatedData,"vlskvks");

    }

    handelSort = (e, key) => {
      // console.log('sdhcbsjdbcsdjc')
      const data = [... JSON.parse(localStorage.getItem("filters"))] ||[ ...this.state.sortby];
  
      const updatedData = data.map(item => ({
        ...item,
        sortby: item.key === key ? !item.sortby : item.sortby
      }));
      if( this.state.data&& this.state.data.length>0 ){
      const test =  this.state.data.filter(item => item[key] )
      if( test && test.length>0){
        this.handelsortcards(this.state.data,updatedData)
      }else{
        return swal('this field is not selected to display')
      }
      }
      localStorage.setItem("filters", JSON.stringify(updatedData));
      this.setState({ sortby: updatedData });
  
      // console.log(e.currentTarget.checked, key, updatedData, "vlskvks");
    }
    handelsortcards = (data,updatedData) => {
      let selected;

      try {
        selected = [...JSON.parse(localStorage.getItem("filters"))];
      } catch (e) {
        selected = [...this.state.sortby];
      }
      const filtered = selected.filter(item => item.value === true);
    
      if (filtered && filtered.length > 0) {
        if (data && data.length > 0) {
          if (filtered[0].sortby === true) {
            data.sort((a, b) => {
              // Assuming 'key' is a property of items within the data array
              if (filtered[0].type === 'string') {
                return a[filtered[0].key].localeCompare(b[filtered[0].key]);
              }
              return a[filtered[0].key] - b[filtered[0].key];
            });
          } else {
            data.sort((a, b) => {
              // Assuming 'key' is a property of items within the data array
              if (filtered[0].type === 'string') {
                return b[filtered[0].key].localeCompare(a[filtered[0].key]);
              }
              return b[filtered[0].key] - a[filtered[0].key];
            });
          }
        }
        // console.log(filtered[0].key, "fkfwwjkf", data);
      }
    
    
      this.setState({ data: data });
    }
    
    
    handelsortcards1 = (data,updatedData) => {
      console.log(updatedData,"cicsdkcdc")
      const selected = [...updatedData];
      const filtered = selected.filter(item => item.value === true);
    
      if (filtered && filtered.length > 0) {
        if (data && data.length > 0) {
          if (filtered[0].sortby === true) {
            data.sort((a, b) => {
              // Assuming 'key' is a property of items within the data array
              if (filtered[0].type === 'string') {
                return a[filtered[0].key].localeCompare(b[filtered[0].key]);
              }
              return a[filtered[0].key] - b[filtered[0].key];
            });
          } else {
            data.sort((a, b) => {
              // Assuming 'key' is a property of items within the data array
              if (filtered[0].type === 'string') {
                return b[filtered[0].key].localeCompare(a[filtered[0].key]);
              }
              return b[filtered[0].key] - a[filtered[0].key];
            });
          }
        }
        // console.log(filtered[0].key, "fkfwwjkf", data);
      }
    
      return data ;
    }
    
    render() {
      console.log(JSON.parse(localStorage.getItem("filters")),'hello here is the data',this.state.data)
        return (
            <>
                {this.state.querySuccess ? <Loading labelShow={true} label={`${this.state.array.length}/${this.props.equipList.length}`} /> : ''}
                <div className='text-center'>
                    <p>Andon Dashboard</p>
                </div>

                <div className='text-center'>

                    <div className='row pl-3 mt-3'>
            {this.state.data && this.handelsortcards1(this.state.data,(JSON.parse(localStorage.getItem("filters")) || this.state.sortby)).map(one => {
              return <div className='col-sm-3 pl-1 rounded ' >
                <div className="jumbotron p-0 jumbotron-fluid  text-center rounded shadow-lg" style={{ overflow: 'hidden' }}>
                  <h5 className="mb-0 " style={this.getColorForCarHeader(one)}>{one.Equipment}</h5>
                  <h4 className="small m-0" style={this.getColorForCarHeader(one)} >{one.ConnStatus}</h4>
                  <div className="container p-0" >
                    <table style={{ display: 'inline-table' }} className="table table-sm  table-responsive mb-0 small ">
                      <tbody className='small '>
                        {console.log(one,"hello test")}
                        {this.getObjectKeysSorted(one).map(key => {
                          if (key.field !== 'Equipment' && key.field !== 'ConnStatus') {
                            return this.getRenderRow(key, one)
                          }
                        })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        })}


                    </div>
                </div>
                <Modal
        title="Sort By"
        open={this.props.open}
        onOk={this.props.handleCancel}
        // confirmLoading={true}
        onCancel={this.props.handleCancel}
      >
        
{(JSON.parse(localStorage.getItem("filters")) ||  this.state.sortby).map((one,index) => (
        <div key={one.key} className="d-flex justify-content-between" style={{width:'100%'}}><div class="form-check">
  <input class="form-check-input" type="radio" name={one.key} checked={one.value} id="flexRadioDefault1" onChange={(e)=>this.handelsortby(e,one.key)} />
  <label class="form-check-label" for="flexRadioDefault1">
  {one.key}  :
  </label>
</div>
           
<div> Acending <Switch disabled={!(one.value)} checked={one.sortby} size="small"  onChange={(e)=>this.handelSort(e,one.key)} /> Descending</div>
</div>
        ))}
      </Modal>
            </>
        );
    }
}

export default AndonDashboard;
