import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Chart from 'react-google-charts';
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFileFull, generateExcelFile } from '../utill/download'
import BarChart from 'react-google-charts';
import dateTime from '../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';



const BenchMarkMachineDetail = ({ obj }) => {
  const [equipProdData, setEquipProdData] = useState([["Time(In Minutes)", "Production Count", { role: "style" },{ role: 'annotation' }]]);
  const [querySuccess,setQuerySuccess]=useState(false);

  useEffect(()=>{
    handleOnSubmit(obj)
  },[])
 
//   const schema = {
//     From: joi.date().required().label('From'),
//     Till: joi.date().required().label('Till'),
//     Shift: joi.string().required().label('Shift'),
//     Equipment: joi.string().required().label('Equipment'),
//     Type: joi.string(),
//     EquipType: joi.string(),
//   }

 

  
  const handleOnSubmit = (data) => {
    let inputData={From:data.From,Till:data.Till,Shift:data.Shift,Equipment:data.Equipment,Type:'History',EquipType:data.EquipType}
  
    setQuerySuccess(true)
    axios.put(api.api + 'GetCardViewDashboardForLiveViewForEquipment', inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        const singleMacData = [["Time(In Minutes)", "Production Count", { role: "style" },{ role: 'annotation' }]];
        if (result.data.length > 0) {

          result.data.forEach(element => {
            let arr = []
            arr.push(new Date(element.DateTime))
            if (inputData.EquipType == 'ProductionCounter') {
              arr.push(element.Count);
              arr.push('#008000');
              arr.push('')
            }
            if (inputData.EquipType == 'ProductionEvent') {
              arr.push(1);
              arr.push('#008000');
              arr.push('')
            }
            if (inputData.EquipType == 'ProductionLogger'||inputData.EquipType == 'ProductionCounterLogger') {
              arr.push(1);
              if (element.EventType == 'EOP') {
                arr.push('yellow');
                arr.push(element.EventType)

              }
              else if (element.EventType == 'SOP') {
                arr.push('green');
                arr.push(element.EventType)

              } else {
                arr.push('red');
                arr.push(element.Event)

              }

            }


           
            singleMacData.push(arr)
          });
          setEquipProdData(singleMacData)
          setQuerySuccess(false)
        }
        else {
          setEquipProdData(singleMacData)
          swal('Production Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // // consol(err)
        setQuerySuccess(false)
      })

  }



  const chartRender = (data) => {
    return <BarChart
      //style={{ marginTop: 200 }}
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      loader={<div>Loading Chart</div>}
      options={{
        vAxis: { title: "Cycles Count" },
        tooltip: { isHtml: true },
        animation: { startup: true, duration: 400, easing: "out" },

        explorer: {
          maxZoomIn: 10,
          maxZoomOut: 8,
          zoomDelta: 2,
          actions: ["dragToZoom", "rightClickToReset"],
          axis: "horizontal",
          // axis: "vertical",
          // keepInBounds: true,
          // maxZoomIn: 4.0
        },

        hAxis: {
          slantedText: true,
          slantedTextAngle: 90,
          textStyle: { fontSize: 9 },
          title: "Time"
        },
        annotations: { textStyle: { fontSize: 10, color: "" } },
        legend: { position: 'none' }

      }}
    />
  }
  return (
    <React.Fragment>   <ToastContainer/>
      {querySuccess ? <Loading /> : ''}
      <div>
        {equipProdData.length > 1 ? chartRender(equipProdData) : querySuccess ? <p>Loading....</p> : ''}
      </div>
    </React.Fragment>
  );
}
export default BenchMarkMachineDetail;