import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';
import ReusableCheckboxDropdown from '../Common/CheckBoxDropdowComponent';
const ProductionReport = ({ handleDownloadTableToExcel, equips, equipGroups ,fromDateLimit,storeDataInLocalStorage}) => {
  const [productionData, setProductionData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({});
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [index, setIndex] = useState(0);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
  const [componentdata, setcomponentList] = useState([]);
  const [operationdata, setoperationList] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [Compareref, setCompareref] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [selecetddowntimegroup, setselecetddowntimegroup] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const[compList,setCompList]=useState([])

 
  console.log(inputData,"current data in the state")
  const schema = {
    From: joi.date().required().label('From Date'),
    UserId: joi.string(),
    Till: joi.date().required().label('Till Date'),
    Equips: joi.array(),
    Operation: joi.array().required().label('Operation'),
    Component: joi.array().required().label('Component'),

  }

  // useEffect(()=>{
  //   axios.get(api.api1 + "component")
  //   .then((result)=>{
  //     setCompList(result.data)
  //   })
  //   .catch((err)=>{
  //     // consol(err);
  //   })

  // },[])

  const DropDownData = [
 
    {
      heading: "Operation",
      label: "tablecolumns",
      initialItems: operationdata,
      selectedItemIds: selectedItemIds,
      name: "Operation",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Component",
      label: "tablecolumns",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
  ]
console.log(senddata,"vdsjv skv")
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
 let result = await storeDataInLocalStorage('component')
 const complist = result
 .map((componentList, index) => ({
   id: `${index}`,
   label: componentList.ComponentId,
 }))
 .slice()
 .sort((a, b) => a.label.localeCompare(b.label));
 let operatordata = await storeDataInLocalStorage('operator')
 const operationIds = [];
 result.forEach((item) => {
   item.Operation.forEach((operation) => {
     operationIds.push(operation.OperationID);
   });
 });
 // Sort and remove duplicates from operationIds
 const uniqueSortedOperations = [...new Set(operationIds)]
   .sort((a, b) => a - b)
   .map((operation, index) => ({ id: index, label: operation }));
console.log(complist,"ckwjenfcjwk",uniqueSortedOperations)
setcomponentList(complist)
setoperationList(uniqueSortedOperations)
        setCompList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);



  useEffect(() => {
    // Function to get today's date in 'YYYY-MM-DD' format
    const getTodayDate = () => {
      const today = new Date();
      let month = String(today.getMonth() + 1).padStart(2, '0');
      let day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      return `${year}-${month}-${day}`;
    };
    // Set today's date as the default value
let preSelectedDate = {
 From:getTodayDate(),
   Till:getTodayDate()
}
handleOnChange({currentTarget:{name:'Shift',value:'All',checked:true}});

    setInputData(preSelectedDate);
  }, []);


  const ProductionReportSchema = [
    { path: 'ShiftOf', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.ShiftOf)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'EquipmentGroup', label: 'EquipmentGroup' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Target', label: 'Target' },

    { path: 'Component', label: 'Component' },
    { path: 'ComponentAlias', label: 'Component Alias' , content: (obj) => 
    {
      let component=[...compList];
      component=component.filter((one)=>one.ComponentId==obj.Component)
      if(component.length>0){
        return <label>{component[0].Alias}</label>
      }else{
        return <label>N/A</label>
      }
    }
  },
    { path: 'Operation', label: 'Operation Id' },
    { path: 'PartsPerCycle', label: 'Parts Per Cycle' },
    { path: 'NumOfCycles', label: 'Cycles Run' },
    { path: 'NumOfParts', label: 'Parts Produced' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]
  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  const handleOnChange = (e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = convertDateFormat(e.currentTarget.value);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
    setInputData(inputData);
    setErrors({})
  }
  const handleOnSubmit = async (inputData) => {
    productionData.splice(0, productionData.length);
    setIndex(0)
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    const user = auth.getCurrentUser();
    inputData.UserId = user._id
    // consol(inputData,"submited data")
    if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    let equipForReport=getEquipsForReport()
    for (let i = 0; i < equipForReport.length; i++) {
      inputData.Equips = [equipForReport[i]]
      inputData.Component =senddata.Component
      inputData.Operation =senddata.Operation

      
      //let data=[...productionData];
      try {
        let result = await axios.post(api.api + 'ProductionReport', inputData)

        if (result.data !== null && result.data !== undefined) {
          if (result.data.length > 0) {
            let idx = i + 1
            result.data.forEach((one) => {
              // Loop through the properties of the 'one' object
              for (const key in one) {
                if (one.hasOwnProperty(key) && one[key] === null || one[key] === undefined) {
                  one[key] = "-";
                }
              }
              productionData.push(one);
            });
            
            setProductionData(productionData)
            setIndex(idx)
          }
          // else {
          //   setProductionData([])
          //   swal('Data Not Available');
          //   setQuerySuccess(false)
          // }
        }
        else {
          setQuerySuccess(false)
        }

      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
      }
    }
    setQuerySuccess(false)


  }


  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted)
      setSortColumn(sortColumn)
    }
  }

  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option)
      setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

      setSelectedEquipOption(option)
    }
  }
  const SelectedDates =(dates)=>{

    setInputData(prev => ({ ...prev, ...dates }));
  }
  const getEquipsForReport = () => {
    let equipArr = []
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      })
    })
    return equipArr;
  }
const  getCount = (data) => {
  let count = 0
  if (data.filter(one => one.value == 'All').length > 0) {
      count = data.length - 1;
  } else {
      count = data.length;
  }

  return count
}

const handleSelectedItemsChange = (newSelectedItems, name,totallength) => {
  setSelectedItemIds(newSelectedItems);
if(name==='ReasonGroup'){
setselecetddowntimegroup(newSelectedItems)

}
  const validHeadings = [
    "Component",
    "Operation",
  ];

  // Check if the provided heading name is valid
  if (!validHeadings.includes(name)) {
    // // console.error("Invalid heading:", name);
    return; // Exit the function if heading is invalid
  }
  setSelectedItemLengths((prevState) => ({
    ...prevState,
    [name]: newSelectedItems.length,
  }));
  if(name == 'DowntimeReason'){
    inputData[name] = newSelectedItems
  }else{
    inputData[name] = newSelectedItems.length == totallength?["all"]:newSelectedItems
  }
 

  // Now you have access to both newSelectedItems and heading
//  // consol(newSelectedItems.length == totallength?"All":"Noob","totallength")
  // // consol("Heading:", name, "inputData :", inputData);
  setsenddata(inputData);
  setCompareref(inputData.Refrence);
};
  console.log(equip,"csdnb csdb")
  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Production
        </h4>
      </div>
      <ToastContainer/>

      <div className="row mx-2">
      {equipGroups.length > 0 && <EquipmentSelection  equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}

        {/* <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} /> */}
      
        {componentdata && operationdata && operationdata.length > 0 && componentdata.length > 0 && DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  
                    <>
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}
                      selectedItemIds={data.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current data name (data.name ) / (data.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, data.name,data.initialItems.length) 
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={data.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={data.searchbar}
                      selectAll={data.selectAll}

                      />
                     
                      </>
                 
   

                  {  errors[data.heading] && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        <div  className={productionData.length > 0 ? 'col-sm-1' : 'col-sm-1 d-none'}>
          <a className='btn btn-sm btn-primary mt-4' onClick={() => handleDownloadTableToExcel('ProductionReport')} style={{ float: 'right', color: 'white' }}>Download</a>
        </div>
      </div>
      <div>

        {productionData.length > 0 ? <Table columns={ProductionReportSchema} onSort={handleSort} id='ProductionReport'  sortColumn={sortColumn} data={productionData} /> : <p></p>}
      </div>
    </React.Fragment>
  );
}
export default ProductionReport;