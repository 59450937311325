import React, { Component } from "react";
import Table from "../../../Common/table";
import { Modal, Container, Col, Button } from "react-bootstrap";
import axios from "../../../httpServices/httpService";
import api from "../../../config.json";
import swal from "sweetalert";
import dateTime from "../../../utill/dateTime";
import joi from "joi";
import validation from "../../../utill/validation";
import Loading from "./../../../utill/LoadingComponent";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
class Quality extends Component {
  state = {
    qualityList: [],
    action: "",
    indexcount:'',
    sortColumn: { path: "EquipmentGroup", order: "asc" },
    modalState: false,
    commentModalState: false,
    qualityReasonList: [],
    updateObj: { Rejection: [{ Stage: "", Rejection: [] }] },
    inputData: { From: new Date(Date.now()), Till: new Date(Date.now()) },
    errors: {},
    querySuccess: false,
    allRejectionsHaveStageAndRemarks: false,
    updatedOldObj: {},
    compList: [],
    stagelist: [],
    action: "",
  };

  schema = {
    From: joi.date().required().label("From Datetime"),
    Till: joi.date().required().label("Till Datetime"),
  };

  columns = [
    { path: "EquipmentGroup", label: "Equipment Group" },
    { path: "Equipment", label: "Equipment" },
    {
      path: "DateTime",
      label: "Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getDate(obj.DateTime)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      path: "Shift",
      label: "Shift",

      //     content: () => <React.Fragment>

      // <div className='form-group'>0
      //   <select className='form-control form-control-sm custom-select' name='ReasonGroup' onChange={this.handleOnChange}>
      //   <option>--select reason group--</option>
      //   {this.state.downTimeList.map(one=>{
      //    return one.ReasonGroup.map(one=>{
      //       return <option>{one}</option>
      //     })
      //   })}

      //   </select>
      // </div>

      //     </React.Fragment>
    },
    ////{ path: 'Reason', label: 'Reason' ,

    //     content: () => <React.Fragment>

    // <div className='form-group'>
    //   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
    //   <option>--select reason--</option>
    //   {this.state.downTimeList.map(one=>{
    //    return  one.Reason.map(reason=>{
    //       return  <option>{reason}</option>
    //     })
    //   })}
    //   </select>
    // </div>
    //     </React.Fragment>

    //},
    { path: "Component", label: "Component" },
    {
      key: "ComponentAlias",
      label: "Component Alias",
      content: (obj) => {
        let component = [...this.state.compList];
        component = component.filter((one) => one.ComponentId == obj.Component);
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>;
        } else {
          return <label>N/A</label>;
        }
      },
    },
    { path: "OperationID", label: "Operation ID" },
    {
      path: "ToolID",
      label: "Tool ID",
      content: (obj) => (
        <label>{obj.ToolID && obj.ToolID !== "null" ? obj.ToolID : "-"}</label>
      ),
    },
    {
      path: "TotalCount",
      label: "Total Parts",
    },
    {
      path: "Reject",
      label: "Rejection Count",
    },
    {
      path: "Remarks",
      label: "Remarks",
      content: (obj) => <label>{obj.Remarks ? obj.Remarks : "-"}</label>,
    },
    { path: "JobRemarks", label: "Job Remarks" },
    { path: "BatchNumber", label: "Job Batch Number" },
    { path: "JobNumber", label: "Job Number" },

    // { path: 'Total', label: 'Total'  },
    //{ path: 'NotOk', label: 'NotOk'  },
    {
      key: "view",
      content: (obj) => (
        <React.Fragment>
          <button
            disabled={
              !this.props.getRightsForAccess(
                "DynamicDataRights",
                "Quality",
                "read"
              )
            }
            onClick={() => this.handleOnUpDateClickQuality(obj, "view")}
            className="btn btn-sm btn-primary"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </React.Fragment>
      ),
    },
    {
      key: "add",
      content: (obj) => (
        <React.Fragment>
          <button
            disabled={
              !this.props.getRightsForAccess(
                "DynamicDataRights",
                "Quality",
                "write"
              )
            }
            onClick={() => {
              this.setState({ updatedOldObj: obj });
              this.handleOnUpDateClickQuality(
                { Rejection: [{ Stage: "", Rejection: [] }] },
                "add"
              );
            }}
            className="btn btn-sm btn-primary"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </React.Fragment>
      ),
    },

    {
      key: "Update",
      content: (obj) => (
        <React.Fragment>
          <button
            disabled={
              !this.props.getRightsForAccess(
                "DynamicDataRights",
                "Quality",
                "update"
              )
            }
            onClick={() => this.handleOnUpDateClickQuality(obj, "update")}
            className="btn btn-sm btn-primary"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </React.Fragment>
      ),
    },
  ];

  GetStageList() {
    axios
      .get(api.api + "rejectionstage")
      .then((result) => {
        let obj = {};
        this.setState({ stagelist: result.data });
      })
      .catch((err) => {
        // consol(err);
      });
  }

  componentDidMount() {
    this.GetStageList();
    axios
      .get(api.api1 + "component")
      .then((result) => {
        this.setState({ compList: result.data });
      })
      .catch((err) => {
        // consol(err);
      });

    this.handleClickOK(this.state.inputData);
    // this.setState({ querySuccess: true });
    // axios.get(api.api + "quality").then((result) => {
    //   this.setState({ querySuccess: false, qualityList: result.data });
    // })
    //   .catch((err) => {
    //     this.setState({ querySuccess: false });
    //     swal(`${err}`)
    //   })
  }

  handleOnUpDateClickQuality = (obj, action) => {
    let cloneObj = _.cloneDeep(obj);
    this.setState({
      modalState: true,
      querySuccess: true,
      updateObj: cloneObj,
      action: action,
    });
    axios
      .get(api.api + "qualityreason", {
        headers: { rights: this.props.dataRightsKey },
      })
      .then((result) => {
        // consol(result, "quality rejection");
        this.setState({ qualityReasonList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        swal(`${err.response.data.message}`);
      });
  };

  // manipulateQualityObj = (obj) => {
  //   if (this.state.action === "add") {
  //     let oldObj = { ...this.state.updatedOldObj };
  //     const newRejection = obj.Rejection[0];

  //     if (newRejection && newRejection.Stage) {
  //       // Check if Stage has a value before pushing
  //       if (newRejection.Stage.trim() !== "") {
  //         oldObj.Rejection.push(newRejection);
  //       } else {
  //         swal("Please select a stage.");
  //       }
  //     } else {
  //       swal("Fill all the Necessary Details");
  //     }

  //     return oldObj;
  //   } else if (this.state.action === "update" || this.state.action === "view") {
  //     let oldObj = { ...this.state.updateObj };

  //     // Filter out objects with Stage empty
  //     oldObj.Rejection = obj.Rejection.filter(
  //       (rejection) => rejection.Stage.trim() !== ""
  //     );

  //     return oldObj;
  //   }

  //   // Handle other cases or default behavior here if needed
  //   return obj; // Return the original object if no condition is met
  // };

  manipulateQualityObj = (obj) => {
    const currentDateTime = new Date(); // Get the current date and time

    if (this.state.action === "add") {
      let oldObj = { ...this.state.updatedOldObj };
      // consol(oldObj, "current object");
      const newRejection = obj.Rejection[0];
      if (this.state.stagelist.length == 1) {
        obj.Rejection.forEach((one, index) => {
          if (index === obj.Rejection.length - 1) {
            one.Stage = this.state.stagelist[0].Stage;
          }
        });
      }
      // consol(newRejection, "new rejection");
      if (newRejection && newRejection.Stage) {
        // Check if Stage has a value before pushing
        if (newRejection.Stage.trim() !== "") {
          newRejection.DateTime = currentDateTime; // Add the current date and time
          oldObj.Rejection.push(newRejection);
        } else {
          swal("Please select a stage.");
        }
        return oldObj;
      } else {
        swal("Fill all the Necessary Details");
      }
    } else if (this.state.action === "update" || this.state.action === "view") {
      let oldObj = { ...this.state.updateObj };

      // Filter out objects with Stage empty
      oldObj.Rejection = obj.Rejection.filter(
        (rejection) => rejection.Stage.trim() !== ""
      );

      // Add the current date and time to each rejection object
      oldObj.Rejection.forEach((rejection) => {
        rejection.DateTime = currentDateTime;
      });

      return oldObj;
    }

    // Handle other cases or default behavior here if needed
    return obj; // Return the original object if no condition is met
  };

  getSelectTagList = () => {
    const stageOptions = this.state.stagelist.map((one) => (
      <option key={one.Stage} value={one.Stage}>
        {one.Stage}
      </option>
    ));

    // Add the additional <option> element for "production"
    const productionOption = (
      <option key="production" value="production">
        --Select Stage--
      </option>
    );

    return (
      <>
        {/* {productionOption} */}
        {stageOptions}
      </>
    );
  };

  handleSaveChanges = (obj) => {
    obj = this.manipulateQualityObj(obj);
    // consol(obj, "revived");

    for (let i = 0; i < obj.Rejection.length; i++) {
      const one = obj.Rejection[i];
      // consol(one.Stage,"warning of stage")
      // if (!one.Stage) {
      //   swal("Please make sure rejection has a stage .");
      //   return; // Exit the function immediately
      // }
    }

    let count = 0;
    obj.Rejection.forEach((one) => {
      count = count + one.Count;
    });

    if (count > obj.TotalCount) {
      swal("Total Rejection Not Greater To Total Production");
      return;
    }

    delete obj.Reject;
    delete obj.TotalCount;

    this.setState({ querySuccess: true });

    axios
      .patch(api.api2 + "quality/" + obj._id, { new: obj })
      .then((result) => {
        // // consol("Data being sent:", {
        //   new: obj.Rejection.forEach((item) => item.Stage),
        // }); // Log the data being sent
        this.setState({
          querySuccess: false,
          modalState: false,
          commentModalState: false,
          action: "",
        });
        swal("successfuly saved");
        this.handleClickOK(this.state.inputData);
      })
      .catch((err) => {
        if(err.response.data.message == undefined){
          swal('Please make sure rejection has a stage .');
        }else{

          swal(`${err.response.data.message}`);
        }
        this.setState({ querySuccess: false });
        // swal(`${err.response.data.message}`);
      });
  };

  handleClose = () => {
    this.setState({ modalState: false });
  };
  handleModalEntryReasonCountChange = (
    e,
    reasonDoc,
    updateObjDoc,
    stageIdx
  ) => {
    const { updateObj } = { ...this.state };
    updateObjDoc = { ...updateObjDoc };
    if (updateObjDoc.Rejection[stageIdx].Rejection.length == 0) {
      let reject = {};
      reject.Count = parseInt(e.currentTarget.value);
      reject.Reason = reasonDoc.QualityReason;
      updateObj.Rejection[stageIdx].Rejection.push(reject);
    } else {
      for (
        let i = 0;
        i < updateObjDoc.Rejection[stageIdx].Rejection.length;
        i++
      ) {
        if (
          updateObjDoc.Rejection[stageIdx].Rejection[i].Reason !==
          reasonDoc.QualityReason
        ) {
          if (
            updateObjDoc.Rejection[stageIdx].Rejection.filter(
              (one, i) => one.Reason == reasonDoc.QualityReason
            ).length > 0
          ) {
            const index = updateObjDoc.Rejection[stageIdx].Rejection.findIndex(
              (one, i) => one.Reason == reasonDoc.QualityReason
            );
            updateObj.Rejection[stageIdx].Rejection[index].Count = parseInt(
              e.currentTarget.value
            );
          } else {
            let reject = {};
            reject.Count = parseInt(e.currentTarget.value);
            reject.Reason = reasonDoc.QualityReason;
            updateObj.Rejection[stageIdx].Rejection.push(reject);
          }
        } else {
          updateObj.Rejection[stageIdx].Rejection[i].Count = parseInt(
            e.currentTarget.value
          );
        }
      }
    }

    this.setState({ updateObj });
    // consol(updateObj);
  };

  handleOnChangeStage = (e, idx) => {
    const { updateObj } = { ...this.state };
    // consol(updateObj, "result of on change");
    if (e.currentTarget.value !== "") {
      if (updateObj.Rejection[idx]) {
        updateObj.Rejection[idx][e.currentTarget.name] = e.currentTarget.value;
      } else {
        updateObj.Rejection[idx] = {
          [e.currentTarget.name]: e.currentTarget.value,
          Rejection: [],
        };
      }
      // consol(updateObj, "on change");
      this.setState({ updateObj });
    }
  };

  handleSort = (sortColumn) => {
    // consol(sortColumn);
    if (this.state.qualityList.length > 0) {
      const sorted = _.orderBy(
        this.state.qualityList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, qualityList: sorted });
    }
  };

  handleOnChange = (e) => {
    const { inputData } = { ...this.state };
    inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    this.setState({ inputData });
  };
  handleClickOK = async(inputData) => {
    const errors = validation.validate(inputData, this.schema);
    // consol(errors);
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.setState({ querySuccess: true });
    // consol(inputData);
    //     const date1 = new Date('2023-12-21');
    // const date2 = new Date('2023-12-30');

    //  while(date1 <= date2){
    //    // consol(date1);
    //    date1.setDate(date1.getDate() + 1)
    //  }

    const date1 = new Date(inputData.From);
    const date2 = new Date(inputData.Till);

    // for (let currentDate = date1; currentDate <= date2; currentDate.setDate(currentDate.getDate() + 1)) {
    //     axios
    //       .put(api.api + "quality", {From: currentDate,Till: currentDate})
    //       .then((result) => {
    //         // consol(result.data, "console 2",currentDate);
    //         this.setState({ qualityList: result.data, querySuccess: false });
    //       })
    //       .catch((err) => {
    //         this.setState({ querySuccess: false });

    //         swal(`${err.response.data.message}`);
    //       });
    // }
    var Difference_In_Time = date2.getTime() - date1.getTime();
  
// To calculate the no. of days between two dates
var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
this.setState({ Difference_In_Days: Difference_In_Days });

    let QualityArray =[] ;
    let index = 1 ;
    while (date1 <= date2) {
      try {
        this.setState({ querySuccess: true });
        const result = await axios.put(api.api + "quality", { From: date1, Till: date1 });
        result.data.forEach(item => {
          QualityArray.push(item)
        });
        this.setState({ qualityList: QualityArray.sort((a, b) => a.DateTime - b.DateTime), querySuccess: false,indexcount :index++ });
        date1.setDate(date1.getDate() + 1);
      } catch (err) {
        this.setState({ querySuccess: false });

        swal(`${err.response.data.message}`);
        break; //
      }
      this.setState({ querySuccess: false });

      // axios
      //   .put(api.api + "quality", { From: date1, Till: date1 })
      //   .then((result) => {
      //     // consol(result.data, "console 2", date1);
      //     this.setState({ qualityList: result.data, querySuccess: false });
      //     date1.setDate(date1.getDate() + 1);
      //   })
      //   .catch((err) => {
      //     this.setState({ querySuccess: false });

      //     swal(`${err.response.data.message}`);
      //   });
    }
    this.setState({ indexcount :0 });

    
  };
  getSumOfRejectionCount = (obj, stageIdx) => {
    let count = 0;
    if (
      obj !== null &&
      obj !== undefined &&
      obj.Rejection !== undefined &&
      obj.Rejection.length > 0
    ) {
      if (
        obj.Rejection.length > 0 &&
        obj.Rejection[stageIdx].Rejection.length > 0
      ) {
        obj.Rejection[stageIdx].Rejection.forEach((one) => {
          if (isNaN(one.Count)) one.Count = 0;

          count += one.Count;
          // consol(count);
        });
        return count;
      }
    }
    return count;
  };

  handleCloseCommentModal = () => {
    this.setState({ commentModalState: false });
  };

  // getCommentModal = () => {
  //   return (
  //     <Modal
  //       show={this.state.commentModalState}
  //       size="md"
  //       backdrop="static"
  //       onHide={this.handleCloseCommentModal}
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title style={{ fontSize: 17 }}>Enter Remarks </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <div className="row">
  //           <div className="col form-group form-group-sm">
  //             <textarea
  //               className="form-control form-control-sm"
  //               onChange={(e) => {
  //                 if (this.state.action == "add") {
  //                   const obj = { ...this.state.updateObj };
  //                   obj.Rejection[0].Remarks = e.currentTarget.value;
  //                   this.setState({ updateObj: obj });
  //                 }
  //                 if (this.state.action == "update") {
  //                 }
  //               }}
  //               rows={3}
  //               cols={40}
  //               name="Remarks"
  //               placeholder="Enter Remarks here..."
  //             />
  //           </div>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="secondary" onClick={this.handleCloseCommentModal}>
  //           Close
  //         </Button>
  //         <Button
  //           variant="primary"
  //           onClick={() => {
  //             this.handleSaveChnages(this.state.updateObj);
  //           }}
  //         >
  //           Update
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
  render() {
    const { errors } = this.state;
    const { getRightsForAccess } = this.props;
    // // consol(this.state.action, "action of states");
    // // consol(
    //   this.props.getRightsForAccess("DynamicDataRights", "Quality", "write"),
    //   "user right for quality",
    //   this.props.getRightsForAccess("DynamicDataRights", "Quality", "read"),
    //   this.props.getRightsForAccess("DynamicDataRights", "Quality", "update")
    // );
    // // consol(
    //   this.state.updateObj.Reject,
    //   "update obj",
    //   this.state.qualityReasonList
    // );
    let totalSum = 0;
    // consol(
    //   this.state.stagelist,
    //   "number of stages",
    //   this.state.stagelist.length,
    //   "stages :",
    //   this.state.updateObj.Rejection.map((item) => item.Stage),
    //   this.state.updateObj.Rejection
    // );
    return (
      <React.Fragment>
        {/* {this.getCommentModal()} */} {this.state.querySuccess ? <Loading 
        // labelShow={true} label={`${this.state.indexcount}/${Math.ceil(this.state.Difference_In_Days)}`}
         /> : ''} 
        <Modal
          show={this.state.modalState}
          size="xl"
          backdrop="static"
          onHide={this.handleClose}
          a
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 17 }}>
              {this.state.action === "update" || this.state.action === "view"
                ? `${this.state.updateObj.Equipment}-${this.state.updateObj.Component}-${this.state.updateObj.OperationID}-${this.state.updateObj.Shift}`
                : `${this.state.updatedOldObj.Equipment}-${this.state.updatedOldObj.Component}-${this.state.updatedOldObj.OperationID}-${this.state.updatedOldObj.Shift}`}
              <br></br> Quality Rejection Entry
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive  ">
              <table className="table table-sm table-hover table-overflow">
                <thead>
                  {this.state.action == "add" ? (
                    ""
                  ) : (
                    <tr>
                      <th colspan="1" className="text-center sticky">
                        Date Time
                      </th>
                      {this.state.updateObj.Rejection.map((obj, stageIdx) => {
                        return (
                          <th scope="col">
                            <div className="row">
                              <div className="col-sm-8 d-flex">
                                <span
                                  className="fs-5 p-0 fw-bolder align-center text-center"
                                  style={{ fontSize: "18px" }}
                                  key={stageIdx}
                                  value={obj}
                                >
                                  {" "}
                                  {moment
                                    .utc(obj.DateTime)
                                    .utcOffset("+05:30")
                                    .format("YYYY-MM-DD hh:mm:ss A")}
                                </span>
                              </div>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  )}

                  <tr>
                    <div className="text-center sticky">
                      <th  className="desktop-only" colspan="1">Reason Group</th>
                      <th colspan="1">Reason</th>
                    </div>
                    {this.state.action == "update" ||
                    this.state.action == "view"
                      ? this.state.updateObj.Rejection.map((obj, stageIdx) => {
                          return (
                            <th scope="col">
                              <div className="row">
                                <div className="col-sm-8">
                                  <select
                                    disabled={this.state.action === "view"}
                                    value={
                                      // this.state.stagelist.length <= 0 || null
                                      //   ? "Production"
                                      //   : 
                                        this.state.stagelist.length > 1
                                        ? obj.Stage
                                        : this.state.stagelist.map(
                                            (one) => one.Stage
                                          )
                                    }
                                    onChange={(e) =>
                                      this.handleOnChangeStage(e, stageIdx)
                                    }
                                    name="Stage"
                                    className="form-control form-control-sm"
                                  >
                                    {this.state.stagelist.length === 1 ? (
                                      this.state.stagelist.map((one) => (
                                        <option
                                          key={one.Stage}
                                          value={one.Stage}
                                        >
                                          {one.Stage}
                                        </option>
                                      ))
                                    ) : this.state.stagelist.length > 1 ? (
                                      this.getSelectTagList()
                                    ) : (
                                      <option>No option available</option>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </th>
                          );
                        })
                      : this.state.updateObj.Rejection.map((obj, stageIdx) => {
                          return (
                            <th scope="col" key={stageIdx}>
                              <div className="row">
                                <div className="col-sm-8">
                                  <select
                                    value={
                                      // this.state.stagelist.length <= 0 || null
                                      //   ? "Production"
                                      //   :
                                         this.state.stagelist.length > 1
                                        ? obj.Stage
                                        : this.state.stagelist.map(
                                            (one) => one.Stage
                                          )
                                    }
                                    onChange={(e) =>
                                      this.handleOnChangeStage(e, stageIdx)
                                    }
                                    name="Stage"
                                    className="form-control form-control-sm"
                                    // required // Add the required attribute to make the select element required
                                  >
                                    {
                                    // this.state.stagelist.length <= 0 ||null ? (
                                    //   <option value="Production">
                                    //     Production
                                    //   </option>
                                    // ) : (
                                      <option value="">--Select Stage--</option>
                                    // )
                                    }
                                    {this.state.stagelist.map((one) => {
                                      return (
                                        <option
                                          key={one.Stage}
                                          value={one.Stage}
                                        >
                                          {one.Stage}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </th>
                          );
                        })}
                    <th
                      className={
                        this.state.action == "add"
                          ? "d-none"
                          : "col-md-2 text-center"
                      }
                      scope="col"
                    >
                      Total Rejections
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {this.state.qualityReasonList.map(one => {
                  return <tr>

                    <td>{one.QualityReasonGroup}</td>
                    <td>{one.QualityReason}</td>
                    {this.state.action == 'update' ?
                      this.state.updateObj.Rejection.map((obj, stageIdx) => {
                        return <td className="col-md-2"><input type='number' name='Count' defaultValue={obj.Rejection.length > 0 ? obj.Rejection.filter(one1 => one1.Reason === one.QualityReason).length > 0 ? obj.Rejection.filter(one1 => one1.Reason === one.QualityReason)[0].Count : 0 : 0} onChange={(e) => this.handleModalEntryReasonCountChange(e, one, this.state.updateObj, stageIdx)} /></td>
                      })
                      :
                      <td className="col-md-2"><input type='number' name='Count' defaultValue={this.state.updateObj.Rejection.length > 0 ? this.state.updateObj.Rejection.filter(one1 => one1.Reason === one.QualityReason).length > 0 ? this.state.updateObj.Rejection.filter(one1 => one1.Reason === one.QualityReason)[0].Count : 0 : 0} onChange={(e) => this.handleModalEntryReasonCountChange(e, one, this.state.updateObj, 0)} /></td>
                    }
              

                  </tr>
                })} */}
                  {this.state.qualityReasonList.map((one) => {
                    let totalRowCount = 0; // Variable to store the total count for each row

                    return (
                      <tr key={one.QualityReason}>
                        <div className="text-center sticky">
                          <td className="text-center desktop-only" colspan="1">
                            {one.QualityReasonGroup}
                          </td>
                          <td className="text-center " colspan="1">
                            {one.QualityReason}
                          </td>
                        </div>

                        {this.state.action === "update" || "view" ? (
                          this.state.updateObj.Rejection.map(
                            (obj, stageIdx) => {
                              const reasonCount =
                                obj.Rejection.length > 0
                                  ? obj.Rejection.filter(
                                      (one1) =>
                                        one1.Reason === one.QualityReason
                                    ).length > 0
                                    ? obj.Rejection.filter(
                                        (one1) =>
                                          one1.Reason === one.QualityReason
                                      )[0].Count
                                    : 0
                                  : 0;

                              if (!isNaN(reasonCount)) {
                                totalRowCount += reasonCount; // Add the actual value if it's not NaN
                              } else {
                                totalRowCount += 0; // Add 0 if it's NaN
                              } // Update the total count for each row

                              return (
                                <td key={stageIdx}>
                                  <input
                                    // style={{width:'95%'}}
                                    disabled={this.state.action == "view"}
                                    type="number"
                                    name="Count"
                                    defaultValue={reasonCount}
                                    onChange={(e) =>
                                      this.handleModalEntryReasonCountChange(
                                        e,
                                        one,
                                        this.state.updateObj,
                                        stageIdx
                                      )
                                    }
                                  />
                                </td>
                              );
                            }
                          )
                        ) : (
                          <td>
                            <input
                              type="number"
                              name="Count"
                              defaultValue={
                                this.state.updateObj.Rejection.length > 0
                                  ? this.state.updateObj.Rejection.filter(
                                      (one1) =>
                                        one1.Reason === one.QualityReason
                                    ).length > 0
                                    ? this.state.updateObj.Rejection.filter(
                                        (one1) =>
                                          one1.Reason === one.QualityReason
                                      )[0].Count
                                    : 0
                                  : 0
                              }
                              onChange={(e) =>
                                this.handleModalEntryReasonCountChange(
                                  e,
                                  one,
                                  this.state.updateObj,
                                  0
                                )
                              }
                            />
                          </td>
                        )}
                        {/* Display the total count for the row */}

                        <td
                          className={
                            this.state.action == "add"
                              ? "d-none"
                              : "col-md-2 text-center"
                          }
                        >
                          {totalRowCount}
                        </td>

                        {/* calculating the total count */}
                        <td className={"d-none"}>
                          {(totalSum += totalRowCount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  {this.state.action == "update" || "view" ? (
                    <tr>
                      <td className="text-center sticky" colspan="1">
                        Remarks
                      </td>
                      {this.state.updateObj.Rejection.map((one, stageIdx) => {
                        return (
                          <td className="col-md-2">
                            <textarea
                              disabled={this.state.action == "view"}
                              onChange={(e) => {
                                let obj = { ...this.state.updateObj };
                                obj.Rejection[stageIdx].Remarks =
                                  e.currentTarget.value;
                                this.setState({ updateObj: obj });
                              }}
                              type="text"
                              cols="23"
                              rows="10"
                              placeholder="Enter Remarks"
                              defaultValue={one.Remarks}
                            ></textarea>
                            {/* <input
                                  disabled={this.state.action== 'view'}

                              type="text"
                              onChange={(e) => {
                                let obj = { ...this.state.updateObj };
                                obj.Rejection[stageIdx].Remarks =
                                  e.currentTarget.value;
                                this.setState({ updateObj: obj });
                              }}
                              placeholder="Enter Remarks"
                              defaultValue={one.Remarks}
                            /> */}
                          </td>
                        );
                      })}
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td className="text-center sticky" colspan="1">
                      Total Count
                    </td>

                    {this.state.action == "update" || "view" ? (
                      this.state.updateObj.Rejection.map((one, stageIdx) => {
                        return (
                          <td className="col-md-2 text-center">
                            {this.getSumOfRejectionCount(
                              this.state.updateObj,
                              stageIdx
                            )}
                          </td>
                        );
                      })
                    ) : (
                      <td className="col-md-2 text-center">
                        {this.getSumOfRejectionCount(this.state.updateObj, 0)}
                      </td>
                    )}
                    {/* for the display of toatl sum of total rejection column */}
                    <td
                      className={
                        this.state.action == "add" ? "d-none" : " text-center"
                      }
                    >
                      {totalSum}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              // className={this.state.action== 'view'  ? 'd-none':''}
              variant="secondary"
              onClick={this.handleClose}
            >
              Close
            </Button>
            <Button
              className={this.state.action === "view" ? "d-none" : ""}
              variant="primary"
              onClick={() => {
                // if (this.state.action == "add") {
                // this.setState({ commentModalState: true });
                // } else {
                this.handleSaveChanges(this.state.updateObj);
                // }
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="text-center">
          <p>Quality Entry</p>
        </div>
        <div className="row mx-2">
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">From</label>
              <input
                className="form-control form-control-sm"
                type="date"
                onChange={this.handleOnChange}
                defaultValue={dateTime.getDateFormatForInputTypeHtml(
                  new Date(Date.now())
                )}
                name="From"
              />
              {errors.From && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors.From}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Till</label>
              <input
                className="form-control form-control-sm"
                type="date"
                onChange={this.handleOnChange}
                defaultValue={dateTime.getDateFormatForInputTypeHtml(
                  new Date(Date.now())
                )}
                name="Till"
              />
              {errors.Till && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors.Till}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-1">
            <div className="form-group text-center">
              <button
                className="btn btn-md btn-primary mt-4"
                disabled={
                  !getRightsForAccess("DynamicDataRights", "Quality", "read")
                }
                onClick={() => this.handleClickOK(this.state.inputData)}
              >
                OK
              </button>
            </div>
          </div>
        </div>

        <div>
          {this.state.qualityList.length < 0 ? (
            "Data Not Available"
          ) : (
           <div style={{overflow:'auto'}}><Table
              columns={this.columns}
              onSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              data={this.state.qualityList}
            /> </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Quality;
