// // consol('sandeep')
 
getDate();
        

export function getDate()
{
    var inputs = document.getElementsByTagName('input');
  
    // // consol(inputs[1])
    // // consol(inputs);
    var today = new Date(Date.now());
   var dd = today.getDate();
   var mm = today.getMonth()+1; //January is 0!
   var yyyy = today.getFullYear();
    if(dd<10){
           dd='0'+dd
       } 
       if(mm<10){
           mm='0'+mm
       } 
   
   today = yyyy+'-'+mm+'-'+dd;
   for(var i = 0; i < inputs.length; i++) {
    if(inputs[i].type.toLowerCase() == 'date') {
        
        //inputs[i].setAttribute('value',inputs[i].getAttribute('defaultValue')) 
        inputs[0].removeAttribute('value')
       inputs[0].setAttribute('defaultValue',today)
       
        inputs[0].setAttribute('value',today)
        // // consol(inputs[0].getAttribute('value')+"-----------------------------------------------------")
       //document.getElementsByTagName('input')[i].setAttribute('value','2020-04-29')
    }
    if(inputs[i].type.toLowerCase() == 'time') {
       // inputs[i].setAttribute('value',inputs[i].getAttribute('defaultValue')) 
       inputs[i].setAttribute('value',getTime())
        inputs[i].setAttribute('defaultValue',getTime())
   }
}
// // consol(today)
// // consol(getTime())
//// consol(inputs[0].getAttribute('value')+"-----------------------------------------------------")
}






function getTime(){
    
      let Time = new Date();
      return (
        (Time.getHours() < 10 ? "0" : "") +
        Time.getHours() +
        ":" +
        (Time.getMinutes() < 10 ? "0" : "") +
        Time.getMinutes()
      );
  
  };

export default{
    getDate,
}

