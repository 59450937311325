import React, { Component } from "react";
import ComponentOperation from "./ComponentOperation";
import axios from "../../../httpServices/httpService";
import endPoint from "../../../config";
import swal from "sweetalert";
import joi from "joi";
import valid from "../../../utill/validation";
import Loading from "../../../utill/LoadingComponent";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import MultiSelect from "../../../Common/multiselect";
import { ToastContainer, toast } from 'react-toastify';


class ComponentTest extends Component {
  state = {
    componentData: { Operation: [] },
    noOfOperations: [],
    NumberOfOperations: 0,
    oldObj: {},
    toolRequireRadioState: 1,
    cycleTimeRadioState: 1,
    selectedEquipmentRequired: "",
    equipmentTypeList: [],
    toolList: [],
    errors: {},
    querySuccess: false,
    modelState: false,
    Operation: { ToolRequiredToProduce: true },
    index: 0,
    opStatus: "",
    operationList:[],
    selectedEquiType:'',
    count:0,  

    customerList:[],
   
    toolList: [],
    querySuccess: false,
    actionStatus: false,
    opStatus: false,

  };
  schemaFull = {
    operationRejectionCost:joi.number(),

    ComponentId: joi.string().required().label("Component Id"),
    CustomerName: joi.string(),
    Alias:joi.string(),
    NumberOfOperations: joi.number().required().label("No Of Operations"),

    Operation: joi
      .array()
      .items(
        joi.object().keys({
          Tool: joi
            .array().min(1).unique()
            .when("ToolRequiredToProduce", { is: true, then: joi.required() }),
          OperationID: joi.string().required(),
          OperationSerial: joi.number().min(0).required(),
    operationRejectionCost:joi.number(),
    EquipmentType: joi.string().required(),
         // NetWeightPerComponent: joi.number().min(1).required(),
          ToolRequiredToProduce: joi.bool().required(),
          PartsPerCycle:  joi.number().min(0).when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          //CyclePerParts: joi.number(),
          // ScrapWeightCycle: joi
          //   .number()
          //   .min(1)
          //   .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          IdealCycleTime: joi
            .number()
            .min(0)
            .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          //CycleTime:joi.number().min(0).when('ToolRequiredToProduce',{is:false,then:joi.required()})
        })
      )
      .unique('OperationID')
      .min(1)
      .required()
      .label("Operations"),
  };

  comparator=(a, b)=>{
    return a.OperationID === b.OperationID;
  }

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios
      .get(endPoint.api + "customer")
      .then((result) => {
        // consol(result.data);
        this.setState({ customerList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
    this.setState({ querySuccess: true });
    axios
      .get(endPoint.api + "equipmenttype")
      .then((result) => {
        // consol(result.data);
        this.setState({ equipmentTypeList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
    this.setState({ querySuccess: true });
  
      axios
      .get(endPoint.api + "operation")
      .then((result) => {
        this.setState({ operationList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
    if (this.props.actionStatus === "add") {
      this.setState({ componentData: { Operation: [] } });
    }
    if (this.props.actionStatus === "update") {
      const component = { ...this.props.componentData };

      this.setState({
        componentData: this.props.componentData,
        oldObj: component,
      });
    }
  }
  handleOnChangeOperation = (e, name1) => {
    const { Operation, errors } = { ...this.state };
    if (name1 == 'Tool') {
      // const errorMessage = valid.validateProperty({value:e.value,name:name1}, schema);
      // if (errorMessage) newErrors[name1] = errorMessage;
      // else delete newErrors[name1];
    }
    else {
      // const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
      // if (errorMessage) errors[e.currentTarget.name] = errorMessage;
      // else delete errors[e.currentTarget.name];

    }

    if (name1 == 'Tool') {
      let tool = []
      if (e) {
        this.state.toolList.forEach(one => {
          e.forEach(t => {
            if (one.ToolId == t.value) {
              tool.push(t.value)
            }
          })
        })
      }
      this.setState({ ChnageEvent: name1 })
      Operation[name1] = tool;
    }
    else {
      Operation[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ ChnageEvent: e.currentTarget.name })
    }
    this.setState({ Operation: Operation, errors: errors || {} })

    // this.props.handleClickOk(Operation);

  };

  handleRadioChange = (e) => {
    //const { component } = { ...this.state };
    const { Operation } = { ...this.state };
    const { name, value } = e.currentTarget;

    if (name === `ToolRequiredToProduce|${this.props.id}`) {
      Operation[e.currentTarget.name.split('|')[0]] = e.currentTarget.value;
      if (value === 'false') {
        this.setState({ toolRequireRadioState: 0, Operation: Operation });
      }
      else {
        this.setState({ toolRequireRadioState: 1, Operation: Operation });
      }
    }
    if (name === `CycleTime|${this.props.id}`) {
      Operation[e.currentTarget.name.split('|')[0]] = e.currentTarget.value;
      if (value === 'CPP') {
        this.setState({ cycleTimeRadioState: 0, Operation: Operation });
      }
      else {
        this.setState({ cycleTimeRadioState: 0, Operation: Operation });
      }

    }
  };

  handleSubmitModelClick = (operation) => {
    delete operation.OperationSerial
    delete operation._id
 
    if (this.props.actionStatus === 'update') {
      if (operation.Tool)
        operation['Tool'] = operation.Tool

      if (operation.EquipmentType)
        operation['EquipmentType'] = operation.EquipmentType
    }
    if(operation.ToolRequiredToProduce==false||operation.ToolRequiredToProduce=='false'){
      delete operation.Tool
    }
    const errors1 = valid.validate(operation, this.schema);
    this.setState({ errors: errors1 || {} })
    if(errors1){return}
    this.handleSubmitModel(operation);
    this.setState({Operation:{ ToolRequiredToProduce: true },ChangeEvent:''})
  }


  schema = {
    operationRejectionCost:joi.number(),
    Tool: joi
      .array().min(1).unique()
      .when("ToolRequiredToProduce", { is: true, then: joi.required() }),
    OperationID: joi.string().required(),
    // OperationSerial: joi.number().min(0).required(),
    EquipmentType: joi.string().required(),
    // NetWeightPerComponent: joi.number().min(1).required(),
    ToolRequiredToProduce: joi.bool().required(),
    PartsPerCycle: joi
      .number().min(0).label('PartsPerCycle').when("ToolRequiredToProduce", { is: false, then: joi.required() }),

    // ScrapWeightCycle: joi
    //   .number()
    //   .min(1)
    //   .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
    
    IdealCycleTime: joi
      .number()
      .min(0)
      .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
  }

  getToolListForValue = (Operation) => {
    let arr = []
    if (Operation.Tool !== undefined) {

      if (Operation.Tool.length > 0) {
        Operation.Tool.forEach(one => {
          let obj = {}
          obj.label = one
          obj.value = one
          arr.push(obj)
        })
        return arr
      }
      else {
        return arr
      }
    }
    else {
      return arr
    }
  }
  getToolListForOptions = (list) => {
    let arr = []
    list.forEach(one => {
      let obj = {}
      obj.label = one.ToolId
      obj.value = one.ToolId
      arr.push(obj)
    })
    return arr
  }





  handleOnChange = (e) => {
    const { componentData, noOfOperations, errors } = { ...this.state };
    const { name, value } = e.currentTarget;
    if(name!=='Alias'){
      const errorMessage = valid.validateProperty(e.currentTarget, this.schemaFull);
      if (errorMessage) errors[e.currentTarget.name] = errorMessage;
      else delete errors[e.currentTarget.name];
    }
    

    componentData[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ componentData, noOfOperations, errors: errors || {} });
  };

  handleChangeEquipmentType = (e) => {
    const { Operation} = { ...this.state };
    Operation[e.currentTarget.name]=e.currentTarget.value
    let type = e.currentTarget.value;
    type=this.state.equipmentTypeList.filter(one=>one.EquipmentType==type)[0];
    axios
      .put(endPoint.api + "tool", { EquipmentType: type._id })
      .then((result) => {
        this.setState({ toolList: result.data,Operation:Operation, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

  }


  // schema1 = {
  //   Tool: joi
  //     .array().unique().min(1)
  //     .when("ToolRequiredToProduce", { is: true, then: joi.required() }),
  //   OperationID: joi.string().required(),
  //   OperationSerial: joi.number().min(0).required(),
  //   EquipmentType: joi.string().required(),
  //  // NetWeightPerComponent: joi.number().min(1).required(),
  //   ToolRequiredToProduce: joi.bool().required(),
  //   PartsPerCycle: joi
  //     .number()
  //     .min(1).required(),
  //     // .when("CycleTime", { is: "PPC", then: joi.required() }),
  //   // CyclePerParts: joi
  //   //   .number()
  //   //   .min(0)
  //   //   .when("CycleTime", { is: "CPP", then: joi.required() }),
   
  //   // ScrapWeightCycle: joi
  //   //   .number()
  //   //   .min(1)
  //   //   .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
  //   IdealCycleTime: joi
  //     .number()
  //     .min(1)
  //     .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
  // };

  handleSubmitModel = (operation) => {
    const { componentData, opStatus } = { ...this.state };
    if (this.props.actionStatus == "add") {
      // consol(operation);
      delete operation.CycleTime;
      if(opStatus==="add")
      {
        componentData.Operation.push(operation);
        this.setState({
          componentData,
          modelState: false,
          Operation: { ToolRequiredToProduce: true },
        });
        // consol(componentData);
      }
      if(opStatus==="update")
      {
        componentData.Operation.filter(obj=>obj.OperationID==operation.OperationID||obj.OperationSerial==operation.OperationSerial)[0]=operation
        this.setState({
          count:0,
          componentData,
          modelState: false,
          Operation: { ToolRequiredToProduce: true },
        });
      }
     
    }
    if (this.props.actionStatus == "update") {
      delete operation.CycleTime;
      if (opStatus === "add") {
        componentData.Operation.push(operation);
      }  if (opStatus === "update") {
        // operation["OperationSerial"] = this.state.Operation.OperationSerial;
        // this.state.componentData.Operation[this.state.index] = operation;
        let index=0;
        componentData.Operation.forEach((obj,i)=>{
          if(obj.OperationID==operation.OperationID){
            index=i
          }
        })
        componentData.Operation[index]=operation
        this.setState({
          componentData,
          modelState: false,
          Operation: { ToolRequiredToProduce: true },
        });
      }
      // consol(componentData);

      this.setState({
      
        componentData,
        modelState: false,
        Operation: { ToolRequiredToProduce: true },
      });
    }
  };
  handleSubmit = (data) => {
    const { componentData } = this.state;
    if (this.props.actionStatus === "add") {
      componentData.Operation.map((one, i) => {
        one["OperationSerial"] = i + 1;
      });
      componentData["NumberOfOperations"] = componentData.Operation.length;
      // consol(this.state.noOfOperations.length);
      const errors = valid.validate(data, this.schemaFull);
      this.setState({ errors: errors || {} });
      if (errors) return;

      this.setState({ querySuccess: true });
      data.Operation.forEach((one,i)=>{
        if(one.ToolRequiredToProduce=='false'||one.ToolRequiredToProduce==false){
          delete data.Operation[i].Tool
        }
      })

       axios
        .post(endPoint.api1 + "component", data)
        .then((result) => {
          if (result.status == 200) {
            this.setState({ querySuccess: false });
            swal("Save Data Successfully");
            this.props.history.push("/setting/component/list");
            this.props.setNavSelectedState("/setting/component/list")
          } else {
            this.setState({ querySuccess: false });
            swal(`Data Not Saved`);
          }
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        });
    }
    if (this.props.actionStatus === "update") {
      componentData.Operation.map((one, i) => {
        one["OperationSerial"] = i + 1;
      });
      componentData["NumberOfOperations"] = componentData.Operation.length;
      this.setState({ querySuccess: true });
      axios
        .patch(endPoint.api + "component/"+data._id, {
          old: this.state.oldObj,
          new: data
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.props.history.push("/setting/component/list");
            swal("Updated Data Successfully");
          } else {
            this.setState({ querySuccess: false });
            swal(`${result.data}`);
          }
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        });
    }
  };
  handleAddOperation = () => {
    // const {count}=this.state;
    // count++
    this.setState({ modelState: true, opStatus: "add" ,toolRequireRadioState:1,Operation:{ToolRequiredToProduce:true}});
  };
  handleHideModel = () => {
    this.setState({ modelState: false,toolRequireRadioState:1,Operation:{ToolRequiredToProduce:true} });
  };
  handleEditOperation = (operation, index) => {
    // consol(operation);
    // consol(index);

    this.setState({ Operation: operation, modelState: true,toolRequireRadioState:operation.ToolRequiredToProduce==false?0:operation.ToolRequiredToProduce=='false'?0:1, index: index,opStatus:"update" },()=>{
      let type=this.state.equipmentTypeList.filter(one=>one.EquipmentType==operation.EquipmentType)[0];
      axios
        .put(endPoint.api + "tool", { EquipmentType: type._id })
        .then((result) => {
          this.setState({ toolList: result.data});
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        });
    });
  };
  handleRemoveOperation = (operation, i) => {
    const componentData = { ...this.state.componentData };
    let operations=componentData.Operation.filter(obj=>obj.OperationID!==operation.OperationID||obj.OperationSerial!==operation.OperationSerial)
       componentData.Operation=operations;
    //componentData.Operation.splice(i, 1);
    this.setState({ componentData });
  };

  handleUpShiftOperation=(operation,currentIndex)=>{
    const componentData = { ...this.state.componentData };
    let repObj=componentData.Operation[currentIndex-1];
    componentData.Operation[currentIndex]=repObj;
    if(0<=currentIndex-1){
      componentData.Operation[currentIndex-1]=operation;
      this.setState({componentData});
    }else
    {
      swal('No More Operation For Shifting to Up')
    }
   
  }

  handleDownShiftOperation=(operation,currentIndex)=>{
    const componentData = { ...this.state.componentData };
    let repObj=componentData.Operation[currentIndex+1];
    componentData.Operation[currentIndex]=repObj;
    if(componentData.Operation.length-1>=currentIndex+1){
      componentData.Operation[currentIndex+1]=operation;
      this.setState({componentData});
    }else
    {
      swal('No More Operation For Shifting to Down')
    }
   
  }
  

  render() {
    return (
      <div className="" id="parent">              <ToastContainer/>

        {this.state.querySuccess ? <Loading /> : ""}
        <div className="row text-center">
          <div className="col-sm-4 ">
            <div className="form-group ">
            <label>Component</label>
              <input
                type="text"
                name="ComponentId"
                defaultValue={this.state.componentData.ComponentId}
                placeholder="Enter Component Id.."
                onChange={this.handleOnChange}
                className="form-control  form-control-sm"
              />
              {this.state.errors.ComponentId && (
                <div className="alert small alert-danger">
                  {this.state.errors["ComponentId"]}
                </div>
              )}
            </div>
           
          </div>
          <div className="col-sm-4 ">
            <div className="form-group ">
            <label>Alias (Optional)</label>
              <input
                type="text"
                name="Alias"
                defaultValue={this.state.componentData.Alias}
                placeholder="Enter alias..."
                onChange={this.handleOnChange}
                className="form-control  form-control-sm"
              />
              {this.state.errors.Alias && (
                <div className="alert small alert-danger">
                  {this.state.errors["Alias"]}
                </div>
              )}
            </div>
           
          </div>

          <div className="col-sm-4 ">
            <div className="form-group ">
            <label>Customer (Optional)</label>
             <MultiSelect masterName="Name" name="CustomerName" objLevel={1}  handleOnChange={this.handleOnChange} selected={this.state.componentData['CustomerName']} all={this.state.customerList}/>
              {this.state.errors.CustomerName && (
                <div className="alert small alert-danger">
                  {this.state.errors["CustomerName"]}
                </div>
              )}
            </div>
           
          </div>
          {/* <div className='col-sm-6'>
            <div className="form-group text-center">
              <input type="number" placeholder='Enter No Of Operation..' disable={this.props.actionStatus==='update'?true:false} defaultValue={this.state.componentData.NumberOfOperations} name="NumberOfOperations" onChange={this.handleOnChange} className="form-control form-control-sm" />
              {this.state.errors.NumberOfOperations && <div className="alert small alert-danger">{this.state.errors['NumberOfOperations']}</div>}
            </div>
          </div> */}
        </div>
        <div>
          <a className="btn btn-primary" onClick={this.handleAddOperation}>
            Add Operation +
          </a>
        </div>

        <ComponentOperation
         modelState={this.state.modelState}
         handleHideModel={this.handleHideModel}
         Operation={this.state.Operation}
          handleOnChange={this.handleOnChangeOperation}
          operationList={this.state.operationList}
          toolList={this.state.toolList}
          equipmentTypeList={this.state.equipmentTypeList}
          errors={this.state.errors}
          handleChangeEquipmentType={this.handleChangeEquipmentType}
          toolRequireRadioState={this.state.toolRequireRadioState}
          handleRadioChange={this.handleRadioChange}
          getToolListForOptions={this.getToolListForOptions}
          getToolListForValue={this.getToolListForValue}
          handleCloseModel={this.handleHideModel}
          handleSubmitModelClick={this.handleSubmitModelClick}
        />
        <table className="table table-sm text-center">
          <tbody>
            {this.state.componentData.Operation.map((one, i) => {
              return (
                <tr key={i}>
                  <td className="bg-success">{`Operation #${one.OperationID}`}</td>
                  <td>
                    <a
                      onClick={() => this.handleEditOperation(one, i)}
                      className="btn"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} color={'blue'}/>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => this.handleRemoveOperation(one, i)}
                      className="btn "
                    >
                     <FontAwesomeIcon icon={faTrash} color={'red'}/>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => this.handleUpShiftOperation(one, i)}
                      className="btn "
                    >
                     <FontAwesomeIcon icon={faArrowCircleUp} />
                    </a>
                    <a
                      onClick={() => this.handleDownShiftOperation(one, i)}
                      className="btn "
                    >
                     <FontAwesomeIcon icon={faArrowCircleDown}/>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* {this.state.noOfOperations.map((one,i)=>{
          
         return <React.Fragment key={i}><tr  className='text-center collapsed bg-success ' aria-expanded="false"  aria-controls={`Operation${one}`} data-toggle='collapse'  data-target={`#Operation${one}`} style={{cursor:'pointer'}}><td>Operatoion  #{one}</td></tr>
           <ComponentOperation id={one} toolRequireRadioState={this.state.toolRequireRadioState}
           cycleTimeRadioState={this.state.cycleTimeRadioState}
           toolList={this.state.toolList}
           equipmentTypeList={this.state.equipmentTypeList}
           handleClickOk={this.handleClickOk}
           Operation={this.state.componentData.Operation}
           actionStatus={this.props.actionStatus}
           />
         </React.Fragment>
        
        })} */}

        {this.state.errors.Operation && (
          <div className="alert small alert-danger">
            {this.state.errors["Operation"]}
          </div>
        )}
        <div className="text-center">
          <button
            className="btn btn-primary"
            onClick={() => this.handleSubmit(this.state.componentData)}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default ComponentTest;
