import React, { Component } from "react";
import MultiRadialChart from "../../utill/MultiRadialChart";
import PieChart from "../../utill/PieChartAVEP";
import MachineDetailedPage from "../../utill/MachineDetailedPage";
import api from '../../config.json';
import BatchSize from '../../config.json';

import axios from '../../httpServices/httpService';
import auth from '../../httpServices/authService';
import swal from 'sweetalert';
import './dashboardV1.1.css';
import date from '../../utill/dateTime';
import Loading from "../../utill/LoadingComponent";
import pako from 'pako'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Button, Tooltip, ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import BarChart from "react-google-charts";

class DashBoard extends Component {

  state = {
    array: [],
    modelState: false,
    singleMachineData: [[]],
    singleMachineData1: [[
      "Time(In Minutes)",
      "Device Power",
      { role: "style" },
      { role: "annotation" },
      // "Device Status",
      // {role:"annotation"},

    ],],

    querySuccess: false,
    selectedCard: {},
    loading: false,
    filterObj: {},
    jobTableData: [],
    originalData: [],
    Equips: [],
    filterBy: {},
    autoScrolObj: {},
    PowerDown: false,
  }

  MyInterval;


  ScreensInterval;

  getScrollData = (data) => {
    let scrollObj = localStorage.getItem('card_state')
    if (scrollObj) {
      let obj = JSON.parse(localStorage.getItem('card_state'));
      if (obj.AutoScroll) {
        data = [...data]
        if (obj.DisplayFor && (obj.DisplayFor !== "") && obj.NumberOfMachine && (obj.NumberOfMachine !== "")) {
          let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
          if ((endIndex > (data.length - 1))) {
            data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
            localStorage.setItem("startIndex", 0);
          } else {
            data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
            localStorage.setItem("startIndex", endIndex);
          }

        }
      }

    }
    return data;
  }

  displayFor = () => {
    let autoScrollObj = localStorage.getItem("card_state");
    if (autoScrollObj) {
      autoScrollObj = JSON.parse(autoScrollObj);
      if (autoScrollObj.DisplayFor && (autoScrollObj.DisplayFor != "") && autoScrollObj.NumberOfMachine && (autoScrollObj.NumberOfMachine != "")) {
        this.ScreensInterval = setInterval(() => {
          let obj = JSON.parse(localStorage.getItem('card_state'));
          if (obj.AutoScroll) {
            let data = [...this.state.originalData]
            if (obj.DisplayFor && (obj.DisplayFor !== "")) {
              let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
              if ((endIndex > (data.length - 1))) {
                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                localStorage.setItem("startIndex", 0);
              } else {
                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                localStorage.setItem("startIndex", endIndex);
              }

            } else {
              data = data.slice(0, parseInt(autoScrollObj.NumberOfMachine))
            }
            data = this.getFilterData(data, this.props.filterObj)
            this.setState({ array: data, autoScrolObj: JSON.parse(localStorage.getItem("card_state")) }, () => {
              if ((JSON.stringify(this.state.autoScrolObj) !== localStorage.getItem('card_state'))) {
                this.ScreensInterval = null;
                clearInterval(this.ScreensInterval);
                this.displayFor();
              }
            });
          } else {
            this.ScreensInterval = null;
            clearInterval(this.ScreensInterval);

            this.setState({ array: this.getFilterData(this.state.originalData, this.props.filterObj) })
          }

        }, (parseInt(autoScrollObj.DisplayFor) * 1000))
      }
    }





  }

  async componentDidMount() {
    localStorage.setItem("startIndex", 0);
    let equips
    /////////////////////////////////////////////// Change batchSize to vary the amount of equipment send through Api /////////////////////////////////////
    const batchSize = Number(BatchSize.BatchSize);

    if (this.props.user) {
      const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
      if (storedEquipGroupsData) {
        // Data exists in local storage, use it
        const storedEquipGroups = JSON.parse(storedEquipGroupsData);
        equips = await storedEquipGroups
        
        this.setState({ querySuccess: false });
      }
      // equips=equips.filter(one=>one.EquipmentID=='bm-03')
      this.setState({ querySuccess: true, loading: true });
      

      for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
        let startIndex = i * batchSize;
        let endIndex = (i + 1) * batchSize;
        let senddata = equips.slice(startIndex, endIndex)
        ////////////////// compressed data with pako ///////////////
        const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
        try {
          // this.setState({ querySuccess: true,loading:true });
          // let res=await axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:[equips.data[i]] })

          ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
          let res = await axios
            .put(api.api1 + "GetCardViewDashboardForLiveView", compressedData, {
              headers: {
                "Content-Encoding": "gzip", // Set the content encoding to gzip
                "Content-Type": "application/json", // Set the content type to JSON
              },
            })

          /////////////////////////// de compressing the recived result  data ////////////

          const decompressedData = pako.ungzip(res.data, { to: 'string' })

          ///////////////////////////// conevrting sting into json ///////////
          const jsonData = JSON.parse(decompressedData)


          let array = [...this.state.array];
          array = [...array, ...jsonData];
          let originalData = [...array]
          array = this.getFilterData(array, this.props.filterObj)
          this.setState({ array: array, originalData: originalData, filterBy: this.props.filterBy, filterObj: this.props.filterObj });
        }
        catch (err) {
          this.setState({ querySuccess: false, loading: false })
          if (err.response && err.response.data && (err.response.data.code == 901)) {
            swal(`${err.response.data.message}`)
          } else {
            swal(`${err}`)
          }

        }
      }
      this.setState({ querySuccess: false, loading: false, Equips: equips.data })

    }
    this.MyInterval = setInterval(async () => {
      if (this.props.user) {

        const storedEquipGroupsData = localStorage.getItem('equipment');     /// getting equipments from local storage , the equipments are stored in machine state dashboard by calling api
        if (storedEquipGroupsData) {
          // Data exists in local storage, use it
          const storedEquipGroups = JSON.parse(storedEquipGroupsData);
          equips = await storedEquipGroups
          // equips=equips.filter(one=>one.EquipmentID=='bm-03')

          this.setState({ querySuccess: false });
        }
        // let equips= await axios.get(api.api1 + 'equipment');

        let array = [...this.state.array];
        
        for (let i = 0; i < Math.ceil(equips?.length / batchSize); i++) {
          let startIndex = i * batchSize;
          let endIndex = (i + 1) * batchSize;
          let senddata = equips.slice(startIndex, endIndex)
          ////////////////// compressed data with pako ///////////////
          const compressedData = pako.gzip(JSON.stringify({ UserId: this.props.user._id, Equips: senddata }));
          try {
            // this.setState({ querySuccess: true,loading:true });
            // let res=await axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:[equips.data[i]] })
            ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
            let res = await axios
              .put(api.api1 + "GetCardViewDashboardForLiveView", compressedData, {
                headers: {
                  "Content-Encoding": "gzip", // Set the content encoding to gzip
                  "Content-Type": "application/json", // Set the content type to JSON
                },
              })

            /////////////////////////// de compressing the recived result  data ////////////

            const decompressedData = pako.ungzip(res.data, { to: 'string' })

            ///////////////////////////// conevrting sting into json ///////////
            const jsonData = JSON.parse(decompressedData)
            if (jsonData.length > 0) {
              array[i] = jsonData[0];
            }

          }
          catch (err) {
            this.setState({ querySuccess: false, loading: false })
            if (err.response && err.response.data && (err.response.data.code == 901)) {
              swal(`${err.response.data.message}`)
            } else {
              swal(`${err}`)
            }
          }
        }

        let originalData = [...array]
        array = this.getScrollData(array)
        array = this.getFilterData(array, this.props.filterObj)
        this.setState({ array: array, originalData: originalData, filterBy: this.props.filterBy, filterObj: this.props.filterObj });

        // axios.put(api.api1 + 'GetCardViewDashboardForLiveView', { UserId: this.props.user._id,Equips:this.state.Equips })
        //   .then((result) => {
        //     let originalData = [...result.data]
        //     result = this.getFilterData(result.data, this.props.filterObj)
        //     this.setState({ array: result, originalData: originalData, filterObj: this.props.filterObj, querySuccess: false });
        //   })
        //   .catch(async (err) => {
        //     this.setState({ querySuccess: false })
        //     if (err.response) {
        //       swal(`${err.response.data.message}`);
        //     } else {
        //       swal(`${err}`);
        //     }
        //   })
      }
    }, 75000)
    this.displayFor()

  }

  componentDidUpdate() {
    if ((JSON.stringify(this.state.filterBy) !== JSON.stringify(this.props.filterBy)) || (this.state.filterObj.SortBy !== this.props.filterObj.SortBy)) {
      let result = this.getFilterData(this.state.originalData, this.props.filterObj)
      this.setState({ array: result, filterBy: this.props.filterBy, filterObj: this.props.filterObj, querySuccess: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.MyInterval)
    clearInterval(this.ScreensInterval)
  }

  getFilterData = (data, filterKey) => {
    let arr = [...data];
    data = []
    Object.keys(this.props.filterBy).forEach((key) => {
      let filterData = arr.filter(one => ((one.EquipmentGroup == key) && (this.props.filterBy[key])))
      data = [...data, ...filterData];
    })
    if (filterKey.SortBy == 'Descending') {
      data = data.sort(function (a, b) {
        if (a.Equipment < b.Equipment) {
          return 1;
        }
        else if (a.Equipment > b.Equipment) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Ascending') {
      data = data.sort(function (a, b) {
        if (a.Equipment < b.Equipment) {
          return -1;
        }
        else if (a.Equipment > b.Equipment) {
          return 1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Running On Top') {
      data = data.sort(function (a, b) {
        if (a.State == 'Running') {
          return -1;
        }
        else if (a.State !== 'Running') {
          return 1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Not Running On Top') {
      data = data.sort(function (a, b) {
        if (a.State !== 'Running') {
          return -1;
        }
        else if (a.State == 'Running') {
          return 1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'OEE Descending') {
      data = data.sort(function (a, b) {
        if (a.OEE < b.OEE) {
          return 1;
        }
        else if (a.OEE > b.OEE) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'OEE Ascending') {
      data = data.sort(function (a, b) {
        if (a.OEE < b.OEE) {
          return -1;
        }
        else if (a.OEE > b.OEE) {
          return 1;
        }
        return 0;
      });
    }

    if (filterKey.SortBy == 'Availability Descending') {
      data = data.sort(function (a, b) {
        if (a.Availability < b.Availability) {
          return 1;
        }
        else if (a.Availability > b.Availability) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Availability Ascending') {
      data = data.sort(function (a, b) {
        if (a.Availability < b.Availability) {
          return -1;
        }
        else if (a.Availability > b.Availability) {
          return 1;
        }
        return 0;
      });
    }

    if (filterKey.SortBy == 'Performance Descending') {
      data = data.sort(function (a, b) {
        if (a.Performance < b.Performance) {
          return 1;
        }
        else if (a.Performance > b.Performance) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Performance Ascending') {
      data = data.sort(function (a, b) {
        if (a.Performance < b.Performance) {
          return -1;
        }
        else if (a.Performance > b.Performance) {
          return 1;
        }
        return 0;
      });
    }

    if (filterKey.SortBy == 'Quality Descending') {
      data = data.sort(function (a, b) {
        if (a.Quality < b.Quality) {
          return 1;
        }
        else if (a.Quality > b.Quality) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Quality Ascending') {
      data = data.sort(function (a, b) {
        if (a.Quality < b.Quality) {
          return -1;
        }
        else if (a.Quality > b.Quality) {
          return 1;
        }
        return 0;
      });
    }

    return data
  }

  handleClickOnSingleCard = (one) => {
    one.DateTime = one.From
    axios.put(api.api1 + 'GetCardViewDashboardForLiveViewForEquipment', { Shift: one.Shift, DateTime: one.From, Equipment: one.Equipment, EquipType: one.DeviceConfig })
      .then((result) => {
        let { singleMachineData, singleMachineData1 } = { ...this.state }
        singleMachineData.shift()
        if (one.DeviceConfig === 'ProductionEvent') {
          singleMachineData.push(["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }, "next diffrence", { role: "style" }])
        } else {
          singleMachineData.push(["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }])


        }
        result.data.forEach(element => {
          console.log(one.DeviceConfig, "sending nan instead of data")
          let arr = [];

          ////  For Power Down Power Up Code
          let arr1 = [];
          if(one.DeviceConfig=='ProductionCounter' || one.DeviceConfig == 'ProductionEvent'){
            if (element.DocumentType === "Power") {
              arr1.push(new Date(element.DateTime))
              arr1.push(1);
              if (element.Event === 'PowerUp') {
  
                arr1.push("#008000");
  
              } else {
  
                arr1.push("red");
              }
               arr1.push(element.Event);
            }else{
              arr1.push(new Date(element.DateTime))
              arr1.push(0)
              arr1.push("#007bff")
              arr1.push("")
            }
          }
          ////  For Power Down Power Up Code



          arr.push(new Date(element.DateTime))
         
          if (one.DeviceConfig == 'ProductionCounter') {

            arr.push(element.Count || 0);
            arr.push("#007bff");
            arr.push("");


          }
         
          if (one.DeviceConfig == 'ProductionEvent') {

            // if (element.DocumentType === "Power") {
            //   console.log(element, "POWER DOCUME");
            //   arr.push(1.5);
            //   arr.push("blue");
            //   arr.push(element.Event);
            // }else{

            arr.push(1);
            arr.push("#007bff");
            arr.push('');
            // }
            arr.push(
              (!isNaN(element.nextDiff) && element.nextDiff !== null && element.nextDiff !== undefined) ?
                Number(element.nextDiff) :
                0
            );

            arr.push('blue');


          }
          if ((one.DeviceConfig == 'ProductionLogger')) {
            arr.push(1);
            if (element.EventType == 'EOP') {
              arr.push('yellow');
              arr.push(element.EventType)

            }
            else if (element.EventType == 'SOP') {
              arr.push('green');
              arr.push(element.EventType)

            } else {
              arr.push('red');
              arr.push(element.Event)

            }

          }
          if ((one.DeviceConfig == 'ProductionCounterLogger')) {
            arr.push(1);
            if (element.EventType == 'EOP') {
              arr.push('yellow')
              arr.push(element.EventType)

            }
            else if (element.EventType == 'SOP') {
              arr.push('green')
              arr.push(element.EventType)

            } else {
              arr.push('red')
              arr.push(element.Event)

            }

          }

          // let color = one.State == 'Running' ? '#0F9D58' : one.ConnStatus == 'offline' ? '#F4B400' : '#DB4437'
          //arr.push('#4285F4')
          singleMachineData.push(arr)
          singleMachineData1.push(arr1)
        });
        console.log(singleMachineData, "data in the chart", singleMachineData1)

        this.setState({ modelState: true, singleMachineData: singleMachineData, singleMachineData1: singleMachineData1, selectedCard: one, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false })
        swal(`${err}`)
      })



    axios.post(api.api1 + 'JobRunsReportForEquipmentForMachineActvity', { Shift: one.Shift, DateTime: one.From, Equipment: one.Equipment })
      .then((result) => {

        this.setState({ jobTableData: result.data });
      })
      .catch((err) => {
        this.setState({ querySuccess: false })
        swal(`${err}`)
      })

  }
  onHide = () => {
    this.setState({ selectedCard: {}, modelState: false, singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }, "next diffrence", { role: "style" }]] });
  }

  // componentDidUpdate(){
  //   if(this.state.filterObj.FilterBy!==this.props.filterObj.FilterBy){
  //     let data=this.getFilterData(this.state.array,this.props.filterObj);
  //     this.setState({array:data})
  //   }
  //   if(this.state.filterObj.SortBy!==this.props.filterObj.SortBy){
  //     let data=this.getFilterData(this.state.array,this.props.filterObj);
  //     this.setState({array:data})
  //   }

  // }

  getColorForCarHeader = (one) => {
    let obj = {}
    if (one.State == 'Running') {
      if (one.ConnStatus == 'offline') {
        obj = { backgroundColor: '#bbb291', padding: 0 }
      }
      if (one.ConnStatus == 'online') {
        obj = { backgroundColor: '#0F9D58', padding: 0 }
      }

    }
    else if (one.State == 'Down') {
      if (one.ConnStatus == 'offline') {
        obj = { backgroundColor: '#bbb291', padding: 0 }
      }
      if (one.ConnStatus == 'online') {
        obj = { backgroundColor: '#DB4437', padding: 0 }
      }

    }
    else if (one.State == 'Break') {
      if (one.ConnStatus == 'offline') {
        obj = { backgroundColor: '#bbb291', padding: 0 }
      }
      if (one.ConnStatus == 'online') {
        obj = { backgroundColor: '#F4B400', padding: 0 }
      }
    }
    else {
      if (one.ConnStatus == 'offline') {
        obj = { backgroundColor: '#bbb291', padding: 0 }
      }
      if (one.ConnStatus == 'online') {
        obj = { backgroundColor: '#DB4437', padding: 0 }
      }
    }
    return obj;
  }

  handleRenderMachineDetailed = (data, data1) => {
    console.log(data, "fdkjvbdfiugbdfiu")
    if (this.state.singleMachineData?.length <= 0) {
      swal('No data available in this shift')
      return
    } else {
      if (this.state.PowerDown === true) {

      } else {
        data1 = []
      }
      return <>
        <MachineDetailedPage modelState={this.state.modelState} jobTableData={this.state.jobTableData} cardData={this.state.selectedCard} singleMachineData={this.state.singleMachineData} setModelState={this.onHide} singleMachineData1={this.state.singleMachineData1} />

      </>
    }
  }



  render() {
    // if (this.state.loading ){
    //   return <Loading/>
    // }
    // console.log(this.state.singleMachineData1,"vfsv")
    return (
      <>
        {this.state.loading ? <Loading labelShow={true} label={`${this.state.array.length}/${this.props.equipList.length}`} /> : ''}
        <div className='text-center'>
          <p>Card View Dashboard</p>
        </div>
        <div className='row' style={{ paddingLeft: 20 }} >
          {this.handleRenderMachineDetailed(this.state.singleMachineData, this.state.singleMachineData1)}

          {this.state.array.map((one, i) => <div className="chartCard " key={i} onClick={() => { this.handleClickOnSingleCard(one); this.setState({ PowerDown: true }) }} style={{ padding: 0, cursor: 'pointer' }}>
            <div className='col-sm-12' style={{ padding: 0 }}>
              <div className="card shadow card-sm   p-1 bg-white rounded" style={{ padding: 0 }}>
                <div className="card-header position-relative" style={this.getColorForCarHeader(one)}>
                  <div className="row" style={{ padding: 0 }}>
                    <div className="col-sm-3  text-left" >
                      {/* <p style={{ color: "black" }}>
                    <FontAwesomeIcon icon={faSignal}/> 
                  </p> */}
                    </div>
                    <div className=" text-center  p-0 m-0" style={{ padding: 0 }}>
                      <h6
                        style={{ color: "black" }}
                        className='p-0 m-0'
                      >
                        {one.Equipment}
                      </h6>
                      <h6
                        style={{ color: "black" }}
                        className='small  p-0 m-0'
                      >
                        {one.ConnStatus == 'offline' ? one.ConnStatus : one.State}
                      </h6>
                      <h6
                        style={{ color: "black" }}
                        className='small p-0 m-0'
                      >
                        {date.getDateFormatForInputTypeHtml(one.LastDowntimeLookedForTill)}
                      </h6>
                      <h6
                        style={{ color: "black" }}
                        className='small p-0 m-0'
                      >
                        {date.getTime(one.LastDowntimeLookedForTill)}
                      </h6>
                    </div>
                    {/* <div className="col-sm-4 text-right" >
                  <p className='small'
                    style={{ color: "black",fontSize:10 }}
                  > 
                  00:00<br/>12.07.17
                  </p>
                </div> */}
                  </div>

                  {/* {one.ConnStatus == 'offline' &&    <Tooltip placement="rightTop"title={
      <div>
  <p className="text-white">1. Check your internet connection.</p>
<p className="text-white">2. Check if a WiFi connection is available on the device.</p>
<p className="text-white">3. Check if the WiFi light on the device is blinking.</p>

      </div>
    }>
          <button className=" fw-bolder m-1 position-absolute top-0 rounded-circle end-0  bg-danger d-flex justify-content-center align-items-center text-white shadow" style={{width:'20px',height:'20px',border:'none',padding:'0'}} >!</button>
               
        </Tooltip>}  */}
                </div>


                <div className="row " style={{ padding: 0 }}>
                  <div className="col-sm-12"  >
                    <table className="table small p-0 m-0 table-sm table-striped table-bordered" style={{ padding: 0 }}>
                      <tbody className='small'>
                        <tr style={{ padding: 0 }}>
                          <td style={{ padding: 0 }}>
                            <label className="small" style={{ fontSize: 10 }}>
                              Operator
                            </label>
                            <h6 style={{ fontSize: 13 }}>{one.OperatorName}</h6>
                          </td>
                          <td style={{ padding: 0 }}>
                            <label className="small" style={{ fontSize: 10 }}>
                              {one.DeviceConfig == 'ProductionLogger' ? 'Total Runtime' : 'Total Cycles'}
                            </label>
                            <h6 style={{ fontSize: 12 }}> {one.DeviceConfig == 'ProductionLogger' ? one.TotalTime : one.TotalCycles}</h6>
                          </td>

                        </tr>

                        <tr style={{ padding: 0 }}>
                          <td style={{ padding: 0 }}>
                            <label className="small" style={{ fontSize: 10 }}>
                              Component
                            </label>
                            <h6 style={{ fontSize: 12 }}>{one.Component.slice(0, 10)}{one.ComponentAlias ? "/" + one.ComponentAlias.slice(0, 10) : ''}</h6>
                            <h6 style={{ fontSize: 12 }}>{one.Operation.slice(0, 10)}</h6>
                          </td>
                          <td style={{ padding: 0 }}>
                            <label className="small" style={{ fontSize: 10 }}>
                              {one.DeviceConfig == 'ProductionLogger' ? 'Actual Runtime' : 'Qty'}
                            </label>
                            <h6 style={{ fontSize: 13 }}> {one.DeviceConfig == 'ProductionLogger' ? one.RunTime : one.Qty}</h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="progress p-0">
                      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" title={'OEE -' + one.OEE ? parseFloat(one.OEE).toFixed(1) : '0' + '%'} data-toggle="tooltip" data-placement="bottom" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: `${one.OEE ? parseFloat(one.OEE).toFixed(1) : 0}%` }}></div>
                    </div>
                  </div>
                  <div style={{ padding: 0, marginLeft: 32 }}>
                    {/* {<MultiRadialChart progress={70} />} */}
                    {/* <PieChart perf={one.Performance} qf={one.QualityFactor} availability={one.Availability} oee={one.OEE}/> */}
                  </div>



                </div>
              </div>
            </div>
          </div>)}

        </div>
      </>
    );
  }
}

export default DashBoard;
