import React, { Component } from "react";
import { Chart } from "react-google-charts";
import api from '../config.json';
import BatchSize  from '../config.json';

import axios from '../httpServices/httpService';
import auth from '../httpServices/authService';
import swal from 'sweetalert'
import $ from 'jquery';
import Loading from "./LoadingComponent";
//import "tui-chart/dist/tui-chart.css";
import pako from "pako";
import { ToastContainer, toast } from 'react-toastify';

export class MachineStateChart extends Component {
  chartref = React.createRef();

  state = {
    timeLineData: [],
    querySuccess: false,
    rawData: [],
    requestNumber: 0,
    updateState: {},
    rowHeight: 0,
    timeLinePos: 0,
    filterObj: {},
    filterBy: {},
    JobView: { JobView: false, ViewCount: 0 },
    autoScrolObj: {},
    cacheData:[]
  };

  MyInterval;

  handleScroll = (e) => {

  }

  getFilterData = (data, filterKey) => {
    let arr = [...data];
    data = []
    Object.keys(this.props.filterBy).forEach((key) => {
      let filterData = arr.filter(one => ((one.EquipmentGroup == key) && (this.props.filterBy[key])))
      data = [...data, ...filterData];
    })
    if (filterKey.SortBy == 'Descending') {
      data = data.sort(function (a, b) {
        if (a.Equipment < b.Equipment) {
          return 1;
        }
        else if (a.Equipment > b.Equipment) {
          return -1;
        }
        return 0;
      });
    }
    if (filterKey.SortBy == 'Ascending') {
      data = data.sort(function (a, b) {
        if (a.Equipment < b.Equipment) {
          return -1;
        }
        else if (a.Equipment > b.Equipment) {
          return 1;
        }
        return 0;
      });
    }
    return data
  }

  componentDidUpdate() {
    if (this.state.filterObj && Object.keys(this.state.filterObj).length > 0) {
      if ((JSON.stringify(this.state.filterBy) !== JSON.stringify(this.props.filterBy)) || (this.state.filterObj.SortBy !== this.props.filterObj.SortBy) || (this.state.filterObj.JobView !== this.props.filterObj.JobView)) {
        let result = this.getFilterData(this.state.rawData, this.props.filterObj)
        if(this.props.filterObj.JobView){
          result = this.organiseTimeLineDataWithJobs(result)
        }else{
          result = this.organiseTimeLineData(result)
        }
        this.setState({ timeLineData: result, filterBy: this.props.filterBy, filterObj: this.props.filterObj, querySuccess: false });
      }
    }

    if (this.props.reportType == 'History') {
      const JobView = { ...this.state.JobView }
      const jobView = { ...this.props.jobView }
      if (jobView) {
        if ((this.props.jobView.JobView == JobView.JobView) && (JobView.ViewCount == 0)) {
          let res = []
          if (this.props.jobView.JobView) {
            res = this.organiseTimeLineDataWithJobs(this.props.data);
          } else {
            res = this.organiseTimeLineData(this.props.data);
          }

          JobView.JobView = this.props.jobView.JobView;
          JobView.ViewCount = JobView.ViewCount + 1
          this.setState({ timeLineData: res, JobView: JobView, rawData: this.props.data, rowHeight: res.length })
        }
        if ((this.props.jobView.JobView !== JobView.JobView) && (JobView.ViewCount == 1)) {

          let res = []
          if (this.props.jobView.JobView) {
            res = this.organiseTimeLineDataWithJobs(this.props.data);
          } else {
            res = this.organiseTimeLineData(this.props.data);
          }
          JobView.JobView = this.props.jobView.JobView;
          JobView.ViewCount = 0
          this.setState({ timeLineData: res, JobView: JobView, rawData: this.props.data, rowHeight: res.length })
        }
        if ((this.props.filterView) && (JobView.ViewCount == 1)) {

          let res = []
          if (this.props.jobView.JobView) {
            res = this.organiseTimeLineDataWithJobs(this.props.data);
          } else {
            res = this.organiseTimeLineData(this.props.data);
          }
          JobView.JobView = this.props.jobView.JobView;
          JobView.ViewCount = 0
          this.props.setFilterView(false);
          this.setState({ timeLineData: res, JobView: JobView, rawData: this.props.data, rowHeight: res.length })
        }

      }


    }

  }
  getScrollData=(data)=>{
    let scrollObj=localStorage.getItem('mac_state')
    if(scrollObj){
      let obj=JSON.parse(localStorage.getItem('mac_state'));
      if(obj.AutoScroll){
        data=[...data]
        if (obj.DisplayFor && (obj.DisplayFor !== "")&&obj.NumberOfMachine && (obj.NumberOfMachine !== "")) {
          let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
          if ((endIndex > (data.length - 1))) {
            data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
            localStorage.setItem("startIndex", 0);
          } else {
            data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
            localStorage.setItem("startIndex", endIndex);
          }
  
        } 
      }
    
    }
    return data;
  }

  ScreensInterval;

  displayFor = () => {
    let autoScrollObj = localStorage.getItem("mac_state");
    if (autoScrollObj) {
      autoScrollObj = JSON.parse(autoScrollObj);
      if (autoScrollObj.DisplayFor && (autoScrollObj.DisplayFor != "") && autoScrollObj.NumberOfMachine && (autoScrollObj.NumberOfMachine != "")) {
        this.ScreensInterval = setInterval(() => {
          let obj=JSON.parse(localStorage.getItem('mac_state'));
          if(obj.AutoScroll){
            let data=[...this.state.cacheData]
            if (obj.DisplayFor && (obj.DisplayFor !== "")) {
              let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(obj.NumberOfMachine)
              if ((endIndex > (data.length - 1))) {
                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                localStorage.setItem("startIndex", 0);
              } else {
                data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                localStorage.setItem("startIndex", endIndex);
              }
  
            } else {
              data = data.slice(0, parseInt(autoScrollObj.NumberOfMachine))
            }
            data=this.organiseTimeLineData(data)
            this.setState({ timeLineData: data, autoScrolObj: JSON.parse(localStorage.getItem("mac_state")) }, () => {
              if ((JSON.stringify(this.state.autoScrolObj) !== localStorage.getItem('mac_state'))) {
                this.ScreensInterval = null;
                clearInterval(this.ScreensInterval);
                this.displayFor();
              }
            });
          }else{
            this.ScreensInterval = null;
            clearInterval(this.ScreensInterval);

            this.setState({timeLineData:this.organiseTimeLineData(this.state.cacheData)})
          }
         
        }, (parseInt(autoScrollObj.DisplayFor) * 1000))
      }
    }





  }
  async componentDidMount() {
  
    localStorage.setItem("startIndex", 0);

    /////////////////////////////////////////////// Change batchSize to vary the amount of equipment send through Api /////////////////////////////////////
    const batchSize = Number(BatchSize.BatchSize);

    //  window.addEventListener('scroll', this.handleScroll);
    if (this.props.reportType == 'History') {
      const JobView = { ...this.state.JobView }
      const jobView = { ...this.props.jobView }
      if (jobView) {
        JobView.JobView = this.props.jobView.JobView;
        let res = []
        if (this.props.jobView.JobView) {
          res = this.organiseTimeLineDataWithJobs(this.props.data)
        } else {
          res = this.organiseTimeLineData(this.props.data)
        }

        this.setState({ timeLineData: res, JobView: JobView, rawData: this.props.data, rowHeight: res.length })
      } else {
        let res = this.organiseTimeLineData(this.props.data);
        this.setState({ timeLineData: res, rawData: this.props.data, rowHeight: res.length })
      }



    }

    else {
      this.setState({ querySuccess: true });

      let equips;
// Check if data exists in local storage
// const storedEquipGroupsData = localStorage.getItem('equipment');
equips = await this.props.storeDataInLocalStorage('equipment');
// if (storedEquipGroupsData) {
//   // Data exists in local storage, use it

//   const storedEquipGroups = JSON.parse(storedEquipGroupsData);
//   equips = await  storedEquipGroups
//   // consol(equips,"from localstorage data")
//   this.setState({querySuccess: false });
// } else {
//   // Data doesn't exist in local storage, make API call to fetch it
//   this.setState({querySuccess: true });
//   await  axios.get(api.api + 'equipment').then((result) => {

//     if (result.data) {
//       // consol(result.data,"from api")
//       // Save equipGroups data in local storage
//       equips =result.data
//       localStorage.setItem('equipment', JSON.stringify(result.data));
//       // Update state
//       this.setState({querySuccess: false });
//     } else {
//       this.setState({ querySuccess: false });
//     }
//   });
// }
      // let equips = await axios.get(api.api1 + 'equipment');
    equips = await this.props.storeDataInLocalStorage('equipment');

      let user = auth.getCurrentUser();   

      // // consol(BatchSize,"BatchSize")
      for (let i = 0; i < Math.ceil(equips?.length / batchSize); i++) {
        let startIndex = i * batchSize;
        let endIndex = (i + 1) * batchSize;
        let senddata = equips.slice(startIndex,endIndex)
        ////////////////// compressed data with pako ///////////////
        const compressedData = pako.gzip(JSON.stringify({ UserId: user._id, Equips: senddata }));
        try {
/////////////////////////// previous sending method without compression ////////////////////////
          // let result = await axios.put(api.api1 + 'GetMachineActvityForLiveReport', { UserId: user._id, Equips: senddata });


          ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
let result = await  axios
          .put(api.api1 + "GetMachineActvityForLiveReport",compressedData, {
            headers: {
              "Content-Encoding": "gzip", // Set the content encoding to gzip
              "Content-Type": "application/json", // Set the content type to JSON
            },
          })
/////////////////////////// de compressing the recived result  data ////////////

const decompressedData = pako.ungzip(result.data,{to:'string'})

///////////////////////////// conevrting sting into json ///////////
const jsonData = JSON.parse(decompressedData)


          let raw = [...jsonData, ...this.state.rawData];
          result = this.getFilterData(raw, this.props.filterObj)
          if(this.props.filterObj.JobView){
            result = this.organiseTimeLineDataWithJobs(result);
          }else{
            result = this.organiseTimeLineData(result);
          }
       
          //result=[...result,...this.state.timeLineData]
          this.setState({ timeLineData: result, rowHeight: result.length, rawData: raw,cacheData:raw, filterBy: this.props.filterBy, filterObj: this.props.filterObj, requestNumber: 1 });

        }
        catch (err) {
          if (err.response && err.response.data && (err.response.data.code == 901)) {
            swal(`${err.response.data.message}`)
          } else {
            toast.success(`${err}`, {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: 'Red', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
            // swal(`${err}`)
          }
          this.setState({ querySuccess: false });
        }

      }
      this.setState({ querySuccess: false });

      this.MyInterval = setInterval(async () => {
        // const storedEquipGroupsData = localStorage.getItem('equipment');
       equips = await this.props.storeDataInLocalStorage('equipment');

// if (storedEquipGroupsData) {
//   // Data exists in local storage, use it

//   const storedEquipGroups = JSON.parse(storedEquipGroupsData);
//   equips = await  storedEquipGroups
//   // consol(equips,"from localstorage data")
//   this.setState({querySuccess: false });
// }
        // let equips = await axios.get(api.api1 + 'equipment');
        let user = auth.getCurrentUser();
        let raw = [...this.state.rawData];
        let result;
      for (let i = 0; i < Math.ceil(equips.length / batchSize); i++) {
        let startIndex = i * batchSize;
        let endIndex = (i + 1) * batchSize;
        let senddata = equips.slice(startIndex,endIndex)
        ////////////////// compressed data with pako ///////////////
        const compressedData = pako.gzip(JSON.stringify({ UserId: user._id, Equips: senddata }));
        try{
           /////////////////////////// previous sending method without compression ////////////////////////
          // let result = await axios.put(api.api1 + 'GetMachineActvityForLiveReport', { UserId: user._id, Equips: senddata });


          ///////////////////////////////////// current sending  method with comprtession(pako:gzip) /////////////////
let result = await  axios
.put(api.api1 + "GetMachineActvityForLiveReport",compressedData, {
  headers: {
    "Content-Encoding": "gzip", // Set the content encoding to gzip
    "Content-Type": "application/json", // Set the content type to JSON
  },
})
/////////////////////////// de compressing the recived result  data ////////////

const decompressedData = pako.ungzip(result.data,{to:'string'})

///////////////////////////// conevrting sting into json ///////////
const jsonData = JSON.parse(decompressedData)
            if (jsonData.length > 0) {
              raw[i] = jsonData[0]
            }
            this.setState({ querySuccess: false });
          }
          catch (err) {
            if (err.response && err.response.data && (err.response.data.code == 901)) {
              swal(`${err.response.data.message}`)
            } else {
              // swal(`${err}`)
               toast.success(`${err}`, {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: 'Red', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
            }
            this.setState({ querySuccess: false });
          }

          result=this.getScrollData(raw);
          result = this.getFilterData(result, this.props.filterObj)
  
          if(this.state.filterObj.JobView){
            result = this.organiseTimeLineDataWithJobs(result);
          }else{
            result = this.organiseTimeLineData(result);
          }
        }
       
        //result=[...result,...this.state.timeLineData]
        this.setState({ timeLineData: result,cacheData:raw, rowHeight: result.length, rawData: raw, filterBy: this.props.filterBy, filterObj: this.props.filterObj, requestNumber: 1 });

        // axios.put(api.api1 + 'GetMachineActvityForLiveReport', { UserId: user._id, Equips: this.props.equipList })
        //   .then((result) => {
        //     let requestNumber = { ...this.state.requestNumber }
        //     requestNumber++
        //     let raw = [...jsonData];
        //     result = this.getFilterData(jsonData, this.props.filterObj)
        //     result = this.organiseTimeLineData(result)
        //     this.setState({ timeLineData: result, rowHeight: result.length, querySuccess: false, rawData: raw, filterObj: this.props.filterObj, requestNumber: requestNumber });
        //   })
        //   .catch((err) => {
        //     this.setState({ querySuccess: false });
        //     swal(`${err}`)
        //   })
        this.setState({ querySuccess: false });
      }, 75000)
    }


    if (this.props.reportType !== 'History'){
      this.displayFor()
    }
  


  }

  //  componentDidUpdate(){
  //   if(this.props.reportType=='History'){
  //     let res=this.organiseTimeLineData(this.props.data)
  //     this.setState({timeLineData:res})
  //   }
  //  }


  componentWillUnmount() {
    // // consol('Clear Interval');
    clearInterval(this.MyInterval);
    clearInterval(this.ScreensInterval);
  }



  getCurrentDateTime = dateTime => {
    let currentDate = new Date(dateTime);
    // currentDate.setHours(currentDate.getHours()+5);
    // currentDate.setMinutes(currentDate.getMinutes()+30);
    let CurrentDateTime =
      currentDate.getDate() +
      "-" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) +
      "-" +
      currentDate.getFullYear() +
      "   |   " +
      currentDate.getHours() +
      ":" +
      (currentDate.getMinutes() < 10 ? "0" : "") +
      currentDate.getMinutes();
    //   ":" +
    //   currentDate.getSeconds();
    return CurrentDateTime;
  };

  getTimeDuration = (start, end) => {
    let from = new Date(start);
    let till = new Date(end);

    //  let duration=new Date(till.getTime()-from.getTime()).getHours()<=0?new Date(till.getTime()-from.getTime()).getMinutes()+' '+'Mins':new Date(till.getTime()-from.getTime()).getHours()+' '+'Hours, '+new Date(till.getTime()-from.getTime()).getMinutes()+' '+'Mins';
    let duration = Math.round(
      Math.abs(new Date(till.getTime() - from.getTime()) / 6e4)
    );
    let min = duration % 60;
    let hour = Math.floor(duration / 60);

    return `${hour} Hour ` + ` ${min} Min`;
  };



  organiseTimeLineData = (list) => {
    if (this.props.reportType == 'History' && this.props.id !== 'reporting') {
      let dataList = [...list];
      list = []
      this.props.equipGroups.forEach((one) => {
        let filterResult = dataList.filter((obj) => ((obj.EquipmentGroup == one.name) && (one.enable)));
        list = [...list, ...filterResult];
      })
    }
    list.sort(function (a, b) {
      if (a.Equipment < b.Equipment) { return -1; }
      if (a.Equipment > b.Equipment) { return 1; }
      return 0;
    })
    let timeLineArray = [];
    let chartSchema = [
      { type: "string", id: "MachineName" },
      { type: "string", id: "State" },
      { type: "string", role: "tooltip", p: { html: true } },
      { type: "string", role: "style" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" }
    ];
    timeLineArray.push(chartSchema);
    let machineNameArray = [];

    for (let i = 0; i < list.length; i++) {
      let machineName = list[i].Equipment;

      for (let j = 0; j < list[i].ListOfEvents.length; j++) {
        var oneArray = [];
        oneArray.push(machineName);
        if (list[i].ListOfEvents[j].State == "Running") {
          if (list[i].ListOfEvents[j].DowntimeReason == null || list[i].ListOfEvents[j].DowntimeReason == 'null') {
            oneArray.push(list[i].ListOfEvents[j].State);
            oneArray.push(
              `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].From
              )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].Till
              )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
              }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
                list[i].ListOfEvents[j].From,
                list[i].ListOfEvents[j].Till
              )}</td></tr></table></div>`
            );
            oneArray.push("#0F9D58");
          } else {
            oneArray.push(list[i].ListOfEvents[j].State);
            oneArray.push(
              `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].From
              )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].Till
              )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
              }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
                list[i].ListOfEvents[j].From,
                list[i].ListOfEvents[j].Till
              )}</td></tr></table></div>`
            );
            oneArray.push("#F4B400");
          }

        } else if (
          list[i].ListOfEvents[j].State == "Down"
        ) {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td >Remarks</td><td>${list[i].ListOfEvents[j].Remarks || '-'}</td></tr></table></div>`
          );
          oneArray.push("#DB4437");
        }
        else if (
          list[i].ListOfEvents[j].State == "Break"
        ) {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr></table></div>`
          );
          oneArray.push("#F4B400");
        }

        else {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td>Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td >Remarks</td><td>${list[i].ListOfEvents[j].Remarks || '-'}</td></tr></table></div>`
          );
          oneArray.push(list[i].ListOfEvents[j].Color);
        }

        // let from=new Date(this.props.timeLineData[i].ListOfEvents[j].From);
        // let to=this.props.timeLineData[i].ListOfEvents[j].Till===undefined?new Date():new Date(this.props.timeLineData[i].ListOfEvents[j].Till);
        oneArray.push(
          new Date(list[i].ListOfEvents[j].From)
        );
        oneArray.push(
          new Date(list[i].ListOfEvents[j].Till)
        );
        // let dateFrom=new Date(this.props.timeLineData[i].ListOfEvents[j].From);

        // oneArray.push(dateFrom.getDate()+'-'+(dateFrom.getMonth()+1)+'-'+dateFrom.getFullYear());
        timeLineArray.push(oneArray);
      }

    }


    

    return timeLineArray;
  };

  organiseTimeLineDataWithJobs = (list) => {
    if (this.props.reportType == 'History' && this.props.id !== 'reporting') {
      let dataList = [...list];
      list = []
      this.props.equipGroups.forEach((one) => {
        let filterResult = dataList.filter((obj) => ((obj.EquipmentGroup == one.name) && (one.enable)));
        list = [...list, ...filterResult];
      })
    }
    list.sort(function (a, b) {
      if (a.Equipment < b.Equipment) { return -1; }
      if (a.Equipment > b.Equipment) { return 1; }
      return 0;
    })
    let timeLineArray = [];
    let chartSchema = [
      { type: "string", id: "MachineName" },
      { type: "string", id: "State" },
      { type: "string", role: "tooltip", p: { html: true } },
      { type: "string", role: "style" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" }
    ];
    timeLineArray.push(chartSchema);
    let machineNameArray = [];

    for (let i = 0; i < list.length; i++) {
      let machineName = list[i].Equipment;

      for (let j = 0; j < list[i].ListOfEvents.length; j++) {
        var oneArray = [];
        oneArray.push(machineName);
        if (list[i].ListOfEvents[j].State == "Running") {
          if (list[i].ListOfEvents[j].DowntimeReason == null || list[i].ListOfEvents[j].DowntimeReason == 'null') {
            oneArray.push(list[i].ListOfEvents[j].State);
            oneArray.push(
              `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].From
              )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].Till
              )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
              }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
                list[i].ListOfEvents[j].From,
                list[i].ListOfEvents[j].Till
              )}</td></tr></table></div>`
            );
            oneArray.push("#0F9D58");
          } else {
            oneArray.push(list[i].ListOfEvents[j].State);
            oneArray.push(
              `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].From
              )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
                list[i].ListOfEvents[j].Till
              )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
              }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
                list[i].ListOfEvents[j].From,
                list[i].ListOfEvents[j].Till
              )}</td></tr></table></div>`
            );
            oneArray.push("#F4B400");
          }

        } else if (
          list[i].ListOfEvents[j].State == "Down"
        ) {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr></table></div>`
          );
          oneArray.push("#DB4437");
        }
        else if (
          list[i].ListOfEvents[j].State == "Break"
        ) {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;"><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr></table></div>`
          );
          oneArray.push("#F4B400");
        }

        else {
          oneArray.push(list[i].ListOfEvents[j].State);
          oneArray.push(
            `<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 30px; overflow: hidden;"><table><tr><td>Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].ListOfEvents[j].Till
            )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].ListOfEvents[j].From,
              list[i].ListOfEvents[j].Till
            )}</td></tr></table></div>`
          );
          oneArray.push(list[i].ListOfEvents[j].Color);
        }

        // let from=new Date(this.props.timeLineData[i].ListOfEvents[j].From);
        // let to=this.props.timeLineData[i].ListOfEvents[j].Till===undefined?new Date():new Date(this.props.timeLineData[i].ListOfEvents[j].Till);
        oneArray.push(
          new Date(list[i].ListOfEvents[j].From)
        );
        oneArray.push(
          new Date(list[i].ListOfEvents[j].Till)
        );
        // let dateFrom=new Date(this.props.timeLineData[i].ListOfEvents[j].From);

        // oneArray.push(dateFrom.getDate()+'-'+(dateFrom.getMonth()+1)+'-'+dateFrom.getFullYear());
        timeLineArray.push(oneArray);
      }
      //State Event Eend

      //Jobs Array Start
      if (list[i].Jobs) {
        for (let j = 0; j < list[i].Jobs.length; j++) {
          var oneArray = [];
          oneArray.push(machineName);
          let job = 'Job - ' + list[i].Jobs[j].Component + ' | ' + list[i].Jobs[j].OperationID + ' | ' + (list[i].Jobs[j].ToolID !== 'null' ? list[i].Jobs[j].ToolID : 'NA')
          oneArray.push(job);
          oneArray.push(
            `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${this.getCurrentDateTime(
              list[i].Jobs[j].From
            )}</td></tr><tr><td>Till</td><td>${this.getCurrentDateTime(
              list[i].Jobs[j].Till
            )}</td></tr><tr><td>Event</td><td>${job
            }</td></tr><tr><td>Duration</td><td>${this.getTimeDuration(
              list[i].Jobs[j].From,
              list[i].Jobs[j].Till
            )}</td></tr></table></p>`
          );
          oneArray.push(this.getRandomColor(1, 4));
          oneArray.push(
            new Date(list[i].Jobs[j].From)
          );
          oneArray.push(
            new Date(list[i].Jobs[j].Till)
          );
          timeLineArray.push(oneArray);
        }
      }

      ///Job Array End
    }



    return timeLineArray;
  };

  getRandomColor = (min, max) => {

    min = Math.ceil(min);
    max = Math.floor(max);
    let randNum = Math.floor(Math.random() * (max - min + 1)) + min
    let objKeys = Object.keys(api.JobColour[randNum])
    return api.JobColour[randNum][objKeys[0]];

  }


  updateChartEvent = (str) => {
    let requestNumber = { ...this.state.requestNumber }
    requestNumber++
    this.setState({ requestNumber })
    this.props.handleChartEvent(str)
  }



  chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        //chart.setSelection([{row:800,column:null}])
        // var chartWrapper = new google.visualization.ChartWrapper({
        //   chartType: 'Timeline',
        //   dataTable: this.state.timeLineData,
        //   containerId: 'timeline'
        // });
        // chartWrapper.draw();
        // google.visualization.events.removeListener()
        if (this.state.requestNumber <= 1) {

          if (!this.props.systemGenerated) {
            google.visualization.events.addListener(chart, "select", e => {
              //const { row, column } = e;
              const data = this.state.timeLineData;
              // // consol($('#timeline').scrollTop())
              let selection = chart.getSelection()
              for (var i = 0; i < selection.length; i++) {
                var item = selection[i];
                if (item.row != null) {
                  var str = data[item.row + 1];
                  if (this.props.id == 'Downtime') {
                    if (this.props.entryType.Multiple || this.props.entryType.Multiple == 'true') {

                      this.updateSelectableEventColor(item.row + 1)

                    }
                    this.updateChartEvent(str)
                  }

                }
              }

            });
            //   google.visualization.events.addListener(chart, "onmouseover", e => {
            //     //const { row, column } = e;
            //  // consol(this.chartref.current)

            //   });
            //   google.visualization.events.addListener(chart, "onmouseover", e => {
            //     //const { row, column } = e;
            //     $('#timeline').scrollTop(this.state.timeLinePos)


            //   });
          }
        }
      }
    }
  ]



  updateSelectableEventColor = (row) => {

    const updateEventMultiple = [...this.props.updateEventMultiple]
    const data = [...this.state.timeLineData];
    let filer = updateEventMultiple.filter(one => this.props.getSystemState(one) == this.props.getSystemState(data[row]));
    let str = data[row];
    let events = str[1].split('-');
    if (events[0].trim() !== 'Job') {
      if (updateEventMultiple.length == 0) {
        data[row][3] = 'gray';
        this.setState({ timeLineData: data })
      } else {
        if (filer.length > 0) {
          data[row][3] = 'gray';
          this.setState({ timeLineData: data })
        } else {
          //this.setState({ systemGenerated: true });

        }
      }
    }

    // const data = [...this.state.timeLineData];
    // data[row][3] = 'gray';
    // this.setState({ timeLineData: data })

  }

  render() {

    // TuiChart.registerTheme("newTheme",this.theme);

    // if (typeof this.state.timeLineData === typeof "")
    //   return <h3>{this.state.timeLineData}</h3>;

    if (this.state.timeLineData.length <= 1 && !this.state.querySuccess) {
      return <p  >No Data Available </p>
    }
    // localStorage.setItem("Machinestatechartheight",(this.props.equipList.length)*50 );
    // const height =localStorage.getItem("Machinestatechartheight")
   
    // if(this.chartref.current && this.chartref.current._isMounted){
    //   return <p>loading....</p>
    // }
    return (
      //TuiChart.registerTheme("color",theme)
      <>
        {this.state.querySuccess ? <Loading labelShow={true} label={`${this.state.rawData.length}/${this.props.equipList.length}`} /> : ''}
        <div className='text-center'>
          <p>Machine State Chart / 4M Change Monitor</p>
        </div>
        {this.state.rawData.length > 0 ? <div ><Chart
      // height={`${height}px`}
      height={'1500px'}
          // height={window.screen.availHeight-(window.screen.availHeight/3)}
          //width={'100%'}
          //style={{minHeight:500}}
          // style={{width:'100%',}
          ref={this.chartref}
          graphID='timeline'
          style={{
            width: "100%",
            marginRight: 5,
            // height: window.screen.height - window.screen.height / 3
          }}

          chartEvents={this.chartEvents}
          chartType="Timeline"
          loader={<div>Loading Chart</div>}
          data={this.state.timeLineData}
          options={{
            tooltip: { isHtml: true },
            //chartArea:{width:'100%',height:'100%'}
            timeline: {
              rowLabelStyle: { fontSize: 15 },//9
              barLabelStyle: { fontSize: 8 }//8
            }

          }}
          rootProps={{ "data-testid": "3" }}
        /></div> : ''}
      </>


    );
  }
}
