import React, { Component } from "react";
import Table from "../../../Common/table";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import dateTime from "../../../utill/dateTime";
import swal from "sweetalert";
import api from "../../../config";
import axios from "../../../httpServices/httpService";
import _ from "lodash";
import Loading from "../../../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';

class RejectionStageTable extends Component {
  state = {
    qualityReasonGroupList: [],
    sortColumn: { path: "QualityReasonGroup", order: "asc" },
    querySuccess: false,
  };
  handleSort = (sortColumn) => {
    // // consol(sortColumn);
    if (this.state.qualityReasonGroupList.length > 0) {
      const sorted = _.orderBy(
        this.state.qualityReasonGroupList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, qualityReasonGroupList: sorted });
    }
  };

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios
      .get(api.api + "rejectionstage")
      .then((result) => {
        // // consol(result.data);
        if (result.data.length > 0) {
          this.setState({
            qualityReasonGroupList: result.data,
            querySuccess: false,
          });
        }
        else{
          this.setState({querySuccess:false})
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  }

  onDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "rejectionstage/"+data._id, { data: data})
          .then((result) => {
            // // consol(result.data);
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.qualityReasonGroupList.filter(
                (obj) => obj._id !== data._id
              );
              this.setState({
                qualityReasonGroupList: filterData,
                querySuccess: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  columns = [
    { path: "Stage", label: "Stage" },

    {
      key: "createdAt",
      label: "Created Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.createdAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "updatedAt",
      label: "Last Updated Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "update",
      content: (user) => (
        <button
          onClick={() => this.props.onUpdate(user)}
          className="btn btn-sm btn-primary"
          disabled={!this.props.getRightsForAccess('StaticDataRights','ToolMaster','update')}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    {
      key: "delete",
      content: (user) => (
        <button
          onClick={() => this.onDelete(user)}
          className="btn btn-sm btn-danger"
          disabled={!this.props.getRightsForAccess('StaticDataRights','QualityMaster','write')}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];

  render() {
    // // consol(this.props.getRightsForAccess('StaticDataRights','QualityMaster','write'))
    return (
      <React.Fragment>       <ToastContainer/>
        {this.state.querySuccess ? <Loading /> : ""}
        <div className='text-center'>
                   <p>Rejection Stages</p>
                 </div>
       {this.props.getRightsForAccess('StaticDataRights','QualityMaster','write')? <a
          style={{ cursor: "pointer" }}
          className="float-right common-anchor"
          onClick={() => {
            this.props.history.push("/setting/rejectionstage/add");
            this.props.handleOnAdd(
              "/setting/rejectionstage/add",
              "add",
              "qualityReasonGroupList",
              this.state.qualityReasonGroupList
            );
          }}
        >
          {" "}
          <FontAwesomeIcon size="1x" icon={faPlus} /> Add
        </a>:''}
        {this.state.qualityReasonGroupList.length < 0 ? (
          "Data Not Available"
        ) : (
          <Table
            columns={this.columns}
            onSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            data={this.state.qualityReasonGroupList}
          />
        )}
      </React.Fragment>
    );
  }
}

export default RejectionStageTable;
