import React, { Component } from "react";
import axios from "../../../httpServices/httpService";
import api from "../../../config";
import swal from "sweetalert";
import valid from "../../../utill/validation";
import joi from "joi";
import Loading from "../../../utill/LoadingComponent";
import common from '../../../utill/common';
import dateTime from '../../../utill/dateTime';
import MultiSelect from "../../../Common/multiselect";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import _ from 'lodash'
import { items } from "joi/lib/types/array";
import auth from "../../../httpServices/authService";
import { ToastContainer, toast } from "react-toastify";

class ComponentDataEntry extends Component {
  state = {
    equipmentGroupList: [],
    equipmentList: [],
    componentList: [],
    componentCacheList: [],
    aliasNames: [],
    selectedAlias: "",
    operationIdList: [],
    operatorList: [],
    operatorOptions: [],
    operatorSelectedOption: [],
    partsPerCycleReason: [],
    toolIdList: [],
    customerList: [],
    partsPerCycleList: [],
    errors: {},
    querySuccess: false,
    componentData: { RunningStatus: "NotRunning", ToolID: '', OperatorEnable: false },
    RunningStatus: "NotRunning",
    old: {},
    selectdeComp: '',
    toolRequiredForSelectedOperation: false,
    FromDate: '',
    FromTime: "",
    TillDate: "",
    TillTime: "",
    noob: "",
    allTools: [],
  };
  schema = {
    Component: joi.string().required(),
    ToolID: joi.string(),
    Equipment: joi.string().required(),
    EquipmentGroup: joi.string().required(),
    RunningStatus: joi.string(),//.required(),
    OperationID: joi.string().required(),
    CustomerName: joi.string(),
    TargetQty: joi.number(),
    PartsPerCycle: joi.number(),
    PartsPerCycleReason: joi.string(),
    JobNumber: joi.string(),
    BatchNumber: joi.string(),
    // OperatorEnable: joi.bool().required(),
    // FromTime: joi.string(),//.required(),
    // FromDate: joi.string(),//.required(),
    Operators: joi.array().min(1).required().label('Operators'),
    Remarks: joi.string(),
    //.when("OperatorEnable", { is: true, then: joi.required() }),
    // TillDate: joi
    //   .string(),
    //   //.when("RunningStatus", { is: "NotRunning", then: joi.required() }),
  };
  schema1 = {
    Component: joi.string().required(),
    ToolID: joi.string(),
    Equipment: joi.string().required(),
    RunningStatus: joi.string(),//.required(),
    EquipmentGroup: joi.string().required(),
    OperationID: joi.string().required(),
    CustomerName: joi.string(),
    TargetQty: joi.number(),
    Operators: joi.array().min(1).required().label('Operators'),
    PartsPerCycle: joi.number(),
    PartsPerCycleReason: joi.string(),
    JobNumber: joi.string(),
    BatchNumber: joi.string(),
    //OperatorEnable: joi.bool().required(),
    From: joi.date(),//.required(),//.label("From Datetime"),
    Till: joi
      .date()
      .label("Till Datetime"),

    Remarks: joi.string(),
    //.when("RunningStatus", { is: "NotRunning", then: joi.required() }),
  };
  getDate = (datetime) => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };
  getTime = (datetime) => {
    if (datetime) {
      let Time = new Date(datetime);
      return (
        (Time.getHours() < 10 ? "0" : "") +
        Time.getHours() +
        ":" +
        (Time.getMinutes() < 10 ? "0" : "") +
        Time.getMinutes()
      );
    }
  };
  getReasonList = () => {
    axios.get(api.api + "partspercyclereason")
      .then((result) => {
        this.setState({ partsPerCycleReason: result.data });
      })
      .catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  componentDidMount() {
    this.getReasonList()
    if (this.props.actionStatus === "update") {

      const old = { ...this.props.dataEntryComponentData }
      let selectOperator = []
      if (old.Operators) {
        old.Operators.forEach((one) => {
          let obj = { value: one.OperatorID, label: one.Operator };
          selectOperator.push(obj);
        })
      }
      this.setState({
        operatorSelectedOption: selectOperator,
        FromDate: this.getDate(this.props.dataEntryComponentData.From),
        FromTime: this.getTime(this.props.dataEntryComponentData.From),
        TillDate: this.getDate(this.props.dataEntryComponentData.Till),
        TillTime: this.getTime(this.props.dataEntryComponentData.Till),
        selectdeComp: this.props.dataEntryComponentData.Component,
        old: old,

        componentData: old,
        RunningStatus: this.props.dataEntryComponentData.Till
          ? "NotRunning"
          : "Running",
      });

    }
    if (this.props.actionStatus === "add") {
      this.setState({ componentData: { RunningStatus: "Running" } });

    }

    this.setState({ querySuccess: true });
    let eqpgrpList = axios.get(api.api1 + "equipmentgroup");

    let opList = axios.get(api.api1 + "component");
    let custList = axios.get(api.api + "customer");
    let allToolList = axios.get(api.api + "tool");
    Promise.all([eqpgrpList, opList, custList, allToolList])
      .then((result) => {
        // consol(result,"total");
        if (this.props.actionStatus === 'update') {
          const ppcList = []

          let eqpGroup = result[0].data.filter(one => one.EquipmentGroup == this.props.dataEntryComponentData.EquipmentGroup);

          axios.put(api.api1 + "equipment", { EquipmentGroup: eqpGroup[0]._id })
            .then((result) => {
              this.setState({ equipmentList: result.data })
            })
            .catch((err) => {
              swal(`${err}`)
            });
          let operationList = result[1].data.filter(
            (one) => one.ComponentId === this.props.dataEntryComponentData.Component
          );

          let toolIdList = []
          operationList[0].Operation.filter((one) => {
            if (one.OperationID === this.props.dataEntryComponentData.OperationID) {
              toolIdList = [...toolIdList, ...one.Tool];
            }
          })
          let cacheList = [...result[1].data];
          let allAliasNames = []
          cacheList.forEach((one) => {
            const alias = one.Alias;
            if (!allAliasNames.some(item => item.name === alias)) {
              allAliasNames.push({ name: alias });
            }
          });

          let componentAlias = result[1].data.filter((one) => one.ComponentId == this.props.dataEntryComponentData.Component);
          if (componentAlias.length > 0) {
            componentAlias = componentAlias[0].Alias;
            // consol("alias",result[1].data)
          }

          for (let i = parseInt(this.state.componentData['PartsPerCycle']); i >= 1; i--) {
            ppcList.push({ PartsPerCycle: i.toString() })
            // consol(ppcList,"ppcList")
          }
          this.setState({ partsPerCycleList: ppcList })

          this.setState({
            selectedAlias: componentAlias,
            aliasNames: allAliasNames,
            operationIdList: operationList[0].Operation,
            toolIdList: toolIdList,///operationList[0].Operation[0].Tool == undefined ? [] : operationList[0].Operation[0].Tool,
            querySuccess: false,
            equipmentGroupList: result[0].data,
            componentCacheList: result[1].data,
            componentList: result[1].data,
            customerList: result[2].data,
            allTools: result[3].data,
            partsPerCycleList: ppcList
          });
          this.getpartpercycleList()

        }
        else {
          let cacheList = [...result[1].data];
          let allAliasNames = []
          cacheList.forEach((one) => {
            const alias = one.Alias;
            if (!allAliasNames.some(item => item.name === alias)) {
              allAliasNames.push({ name: alias });
            }
          });

          this.setState({
            aliasNames: allAliasNames,
            querySuccess: false,
            equipmentGroupList: result[0].data,
            componentCacheList: result[1].data,
            componentList: result[1].data,
            customerList: result[2].data,
            allTools: result[3].data

          });
          // this.getpartpercycleList()

        }

      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

    this.setState({ querySuccess: true });
    axios.get(api.api1 + "operator")
      .then((result) => {
        let options = []
        result.data.forEach((one) => {
          let obj = {}
          obj.value = one.OperatorID;
          obj.label = one.Name;
          options.push(obj);
        })
        this.setState({ operatorList: result.data, operatorOptions: options, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })



  }
  handleRadioChange = (e) => {
    const { componentData, errors } = { ...this.state };
    componentData[e.currentTarget.name] =
      e.currentTarget.value === "Running" ? "NotRunning" : "Running";
    this.setState({
      [e.currentTarget.name]:
        e.currentTarget.value === "Running" ? "NotRunning" : "Running",
      componentData,
    });
    // consol(componentData);
  };

  handleGetOnSelect = (name, value) => {
    if (name == 'EquipmentGroup') {
      axios.put(api.api1 + "equipment", { EquipmentGroup: value })
        .then((result) => {
          this.setState({ equipmentList: result.data })
        })
        .catch((err) => {
          toast.success(`${err}`, {
            position: 'bottom-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'red', // Set your desired background color
              color: 'white',     // Set your desired text color
            },
          });
        })

    }

  }
  handleOnChange = (e) => {
    // consol('onChange function ',e.currentTarget.value)

    const { componentData, errors } = { ...this.state };
    // consol(componentData,'data check')
    if (e.currentTarget.name !== 'FromTime' && e.currentTarget.name !== 'FromDate' && e.currentTarget.name !== 'TillTime' && e.currentTarget.name !== 'TillDate') {
      const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
      if (errorMessage) errors[e.currentTarget.name] = errorMessage;
      else delete errors[e.currentTarget.name];
    }
    if (
      e.currentTarget.name === "FromDate" ||
      e.currentTarget.name === "FromTime"
    ) {
      if (e.currentTarget.name === "FromDate") {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(new Date().getHours(),new Date().getMinutes());
        // componentData["From"] = date;
        this.setState({ FromDate: e.currentTarget.value })
      }
      if (e.currentTarget.name === "FromTime") {
        // let Time = e.currentTarget.value.split(":");
        // componentData["From"] = new Date(
        //   new Date(componentData.From).setHours(Time[0], Time[1])
        // );
        this.setState({ FromTime: e.currentTarget.value })
      }
    } else if (
      e.currentTarget.name === "TillDate" ||
      e.currentTarget.name === "TillTime"
    ) {
      if (e.currentTarget.name === "TillDate") {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(new Date().getHours(),new Date().getMinutes());
        // componentData["Till"] = date;
        this.setState({ TillDate: e.currentTarget.value })
      }
      if (e.currentTarget.name === "TillTime") {
        // let Time = e.currentTarget.value.split(":");
        // componentData["Till"] = new Date(
        //   new Date(componentData.Till).setHours(Time[0], Time[1])
        // );
        this.setState({ TillTime: e.currentTarget.value })
      }
    }
    else if (
      e.currentTarget.name === "EquipmentGroup"
    ) {
      componentData[e.currentTarget.name] = e.currentTarget.value;
      let eqpGroup = this.state.equipmentGroupList.filter(one => one.EquipmentGroup == e.currentTarget.value);
      axios.put(api.api1 + "equipment", { EquipmentGroup: eqpGroup[0]._id })
        .then((result) => {
          this.setState({ equipmentList: result.data })
        })
        .catch((err) => {
          swal(`${err}`)
        });
    }
    else if (
      e.currentTarget.name === "OperationID"
    ) {
      const ppcList = []
      let componentList = this.state.componentList.filter(
        (one) => one.ComponentId === this.state.selectdeComp
      )
      componentData[e.currentTarget.name] = e.currentTarget.value;
      let operationList = componentList[0].Operation.filter(one1 => one1.OperationID == e.currentTarget.value);
      componentData['PartsPerCycle'] = operationList[0].PartsPerCycle;
      if (operationList[0].ToolRequiredToProduce == 'true' || operationList[0].ToolRequiredToProduce) {
        let filterTool = this.state.allTools.filter(tool => tool.ToolId == operationList[0].Tool[0]);
        if (filterTool.length > 0) {
          componentData['PartsPerCycle'] = filterTool[0].PartsPerCycle;
          componentData['ToolID'] = filterTool[0].ToolId;
        }
      } else {
        delete componentData['ToolID'];
      }
      for (let i = parseInt(componentData.PartsPerCycle); i >= 1; i--) {
        ppcList.push({ PartsPerCycle: i.toString() })
      }
      this.setState({ partsPerCycleList: ppcList, toolIdList: operationList[0].Tool, toolRequiredForSelectedOperation: operationList[0].ToolRequiredToProduce });
    }
    else {
      if (e.currentTarget.name === "Component") {
        const ppcList = []
        let operationList = this.state.componentList.filter(
          (one) => one.ComponentId === e.currentTarget.value
        );
        componentData['CustomerName'] = operationList[0].CustomerName;
        componentData['OperationID'] = operationList[0].Operation[0].OperationID;
        componentData['PartsPerCycle'] = operationList[0].Operation[0].PartsPerCycle;

        if (operationList[0].Operation[0].ToolRequiredToProduce == 'true' || operationList[0].Operation[0].ToolRequiredToProduce) {
          let filterTool = this.state.allTools.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
          if (filterTool.length > 0) {
            componentData['PartsPerCycle'] = filterTool[0].PartsPerCycle;


            componentData['ToolID'] = filterTool[0].ToolId;
          }
        }
        for (let i = parseInt(componentData.PartsPerCycle); i >= 1; i--) {
          ppcList.push({ PartsPerCycle: i.toString() })
          // consol(ppcList,"ppcList")
        }
        this.setState({ partsPerCycleList: ppcList, toolIdList: operationList[0].Operation[0].Tool, operationIdList: operationList[0].Operation, selectdeComp: e.currentTarget.value });
      }
      if (e.currentTarget.name == "ToolID") {
        const ppcList = []
        let operationList = this.state.componentList.filter(
          (one) => one.ComponentId === componentData.Component
        );
        const operation = operationList[0].Operation;
        componentData['PartsPerCycle']=operation.filter(one=>one.OperationID==componentData.OperationID)[0].PartsPerCycle

        if (operationList[0].Operation[0].ToolRequiredToProduce == 'true' || operationList[0].Operation[0].ToolRequiredToProduce) {
          let filterTool = this.state.allTools.filter(tool => tool.ToolId == e.currentTarget.value);
          if (filterTool.length > 0) {
            componentData['PartsPerCycle'] = filterTool[0].PartsPerCycle;
            // componentData['ToolID'] = filterTool[0].ToolId;
          }
        }
        for (let i = parseInt(componentData.PartsPerCycle); i >= 1; i--) {
          ppcList.push({ PartsPerCycle: i.toString() })
          // consol(ppcList,"ppcList")
        }
        this.setState({ partsPerCycleList: ppcList });
      }
      if (e.currentTarget.name == 'PartsPerCycle' && this.IsReasonEnable(e.currentTarget.value)) {
        let filterReason = this.state.partsPerCycleReason.filter((one) => one.Default);
        // consol(filterReason,'filterReason',this.state.partsPerCycleReason)

        if (filterReason.length > 0) {
          componentData['PartsPerCycleReason'] = filterReason[0].Reason;
          // consol(componentData['PartsPerCycleReason'],"PartsPerCycleReason")

        }
      }
      this.handleGetOnSelect(e.currentTarget.name, e.currentTarget.value);
      componentData[e.currentTarget.name] = e.currentTarget.value;
    }


    this.setState({ componentData, errors: errors || {} });
    // consol(componentData,'result');
  };
  getpartpercycleList = () => {
    const ppcList = []
    // consol("function runed")
    // let operationList = componentList.filter(
    //   (one) => one.ComponentId === componetid
    // );
    let operationList = this.state.componentList.filter(
      (one) => one.ComponentId === this.props.dataEntryComponentData.Component
    );

    // consol(operationList,"f11111",this.state.allTools)

    let filterTool = this.state.allTools.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
    // let filterTool = toolList.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
    // consol(filterTool,"f22222")
    // componentData['PartsPerCycle'] =filterTool[0].PartsPerCycle
    for (let i = parseInt(filterTool[0]?.PartsPerCycle); i >= 1; i--) {
      ppcList.push({ PartsPerCycle: i.toString() })
    }
    // consol(ppcList,"setComponentData3")
    this.setState({ partsPerCycleList: ppcList })
    return ppcList

  }
  setDateTimeToData = (data) => {
    let from = new Date(this.state.FromDate)
    let fromTime = this.state.FromTime.split(':')
    from.setHours(fromTime[0], fromTime[1])
    data['From'] = from
    let till = new Date(this.state.TillDate)
    let tillTime = this.state.TillTime.split(':')
    till.setHours(tillTime[0], tillTime[1])
    data['Till'] = till
    return data
  }

  validateCavity = (data) => {
    let err = false
    data = { ...data }
    let componentList = this.state.componentList.filter(
      (one) => one.ComponentId === data.Component
    )
    if (componentList.length > 0) {
      componentList = componentList[0].Operation.filter(one => one.OperationID == data.OperationID);
      if (componentList.length > 0) {
        if (componentList[0].ToolRequiredToProduce == 'true' || componentList[0].ToolRequiredToProduce) {
          let filterTool = this.state.allTools.filter(tool => tool.ToolId == data.ToolID);
          if (filterTool.length > 0) {
            if (parseFloat(data.PartsPerCycle) > parseFloat(filterTool[0].PartsPerCycle)) {
              err = true
            }
          }

        } else {
          if (parseFloat(data.PartsPerCycle) > parseFloat(componentList[0].PartsPerCycle)) {
            err = true
          }
        }
      }
    }

    return err;
  }

  handleSubmit = (data1) => {
    let data = { ...data1 }
    if (this.validateCavity(data)) {
      swal("parts per cycle of job should be less than or equal to master data", { dangerMode: true });
      return
    }

    const { FromTime, TillTime, FromDate, TillDate } = { ...this.state }
    if (FromDate == "" || FromTime == "") { swal('From Date Time Not Allowed To Be Emphty'); return }
    if (TillDate == "" || TillTime == "") { swal('Till Date Time Not Allowed To Be Emphty'); return }


    data = this.setDateTimeToData(data)
    data.From.setMilliseconds(1)
    if (dateTime.getDifferenceBetweenTimes(data.From, data.Till).minutes < 1) {
      swal('From Time and Till Time Diff Should Be Greater Than One Minutes', { dangerMode: true })
      return
    }
    if (new Date(data.From) > new Date(data.Till)) {
      swal('from time is must be less than till time', { dangerMode: true })
      return
    }



    if (this.props.actionStatus === "update") {
      // consol(data,"errur")
      // if(data.RunningStatus==='NotRunning')
      // {
      //   data.RunningStatus=false
      //   //delete data.RunningStatus
      // }
      // if(data.RunningStatus==='Running')
      // {
      //   data.RunningStatus=true
      //   //delete data.RunningStatus
      //   delete data.Till
      // }
      this.setState({ querySuccess: true });
      let opertor = []
      if (this.state.operatorSelectedOption) {
        this.state.operatorSelectedOption.filter((one) => {
          let filterOp = this.state.operatorList.filter((op) => op.OperatorID == one.value)[0];
          if (filterOp) {
            let obj = { Name: filterOp.Name, OperatorID: filterOp.OperatorID, id: filterOp._id };
            opertor.push(obj)
          }

        })
      }

      data.Operators = opertor;
      if (this.IsReasonEnable(data.PartsPerCycle) == false || this.IsReasonEnable(data.PartsPerCycle) == "false") {
        data.PartsPerCycleReason = '';
      }
      // consol(this.state.old, data,"data to be send")
      axios
        .patch(api.api2 + "jobselected/" + this.state.old._id, {
          old: this.state.old,
          new: data,
        }, { headers: { rights: this.props.dataRightsKey } })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.props.handleCloseAddJob()
            this.props.handleOnSubmit(this.props.inputData);
            swal("Update Data Successfully");
          } else {
            this.props.handleCloseAddJob()
            this.props.handleOnSubmit(this.props.inputData);
            this.setState({ querySuccess: false });
            swal(`${result.data}`);
          }
        })
        .catch((err) => {
          swal(`${err.response.data.message}`);
          this.setState({ querySuccess: false });
        });
    } else {
      let opertor=[]
      this.state.operatorSelectedOption.filter((one)=>{
        let filterOp=this.state.operatorList.filter((op)=>op.OperatorID==one.value)[0];
        let obj={Name:filterOp.Name,OperatorID:filterOp.OperatorID,id:filterOp._id};
          opertor.push(obj)
      })
      data.Operators=opertor;
      delete data.Accept
      let myThis = this
      const errors = valid.validate(data, this.schema1);
      // consol(errors);
      this.setState({ errors: errors || {} });
      if (errors) return;


      if(this.state.toolIdList.length>0){
        if(data.ToolID==''||data.ToolID==null||data.ToolID=='null'||data.ToolID==undefined){
          swal('Tool is required',{dangerMode:true});
          return;
        }
      }
    
      this.setState({ querySuccess: true });
      data.Accept = false;
      if(this.IsReasonEnable(data.PartsPerCycle) == false || this.IsReasonEnable(data.PartsPerCycle) == "false"){
        data.PartsPerCycleReason = '';
      }
      axios
        .post(api.api2 + "ValidateJob", data)
        .then((result) => {
         
          if (result.status == 200) {
            this.setState({ querySuccess: false });
            axios
            .post(api.api2 + "createJobSelected", data)
            .then((result) => {
             if (result.status == 200) {
               //  swal(`Data Submitted Successfully`);
               this.props.handleCloseAddJob()
               
               this.props.handleOnSubmit(this.props.inputData);
               swal(`Data Submitted Successfully job Number : ${result.data.jobNumber}`);
               this.setState({ querySuccess: false });
            
             } else {
              console.log('sahcdugcsd 1')
               this.setState({ querySuccess: false });
               swal(`${result.response.data.messgae}`);
             }
            }).catch(error => {
              this.setState({ querySuccess: false });
            
            })
            this.props.handleCloseAddJob()
            // this.props.handleOnSubmit(this.props.inputData);
          } else {
            this.setState({ querySuccess: false });
            swal(`${result.response.data.messgae}`);
          }
        })
        .catch((err) => {
          console.log(err.response.data,"cdscjnsdc")

         if(err.response.data.code == 500){
           const OperatorAssigned =   err.response.data.message.filter(item =>item.code === 411)
           const EquipmentBussy =   err.response.data.message.filter(item =>item.code === 408)
         console.log(OperatorAssigned,"cjhsdc 1",OperatorAssigned.map(item=>item.message))
           if(EquipmentBussy && EquipmentBussy.length > 0){
             this.setState({ querySuccess: false });
            return swal({
              title: "Equipment error please correct :",
              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                    <ul>
                      ${EquipmentBussy.map(item => `
                        <li>
                          On ${item.ShiftOf.split('T')[0]} in ${item.Shift}: ${item.message}
                        </li>
                      `).join('')}
                    </ul>
                  `
                }
              },
              icon: "warning",
              dangerMode: true,
            })
          
           }
          else if(OperatorAssigned && OperatorAssigned.length > 0){
console.log(OperatorAssigned,"cjhsdc 2",OperatorAssigned.map(item=>item.message))

swal({
  title: "Operator already assigned. Do you still want to assign?",
  content: {
    element: "div",
    attributes: {
      innerHTML: `
        <ul>
          ${err.response.data.message.map(item => `
            <li>
              On ${item.ShiftOf.split('T')[0]} in ${item.Shift}: ${item.message}
            </li>
          `).join('')}
        </ul>
      `
    }
  },
  icon: "warning",
  buttons: ["No", "Yes"],
  dangerMode: true,
})
   .then((confirmed) => {
     if (confirmed) {
       // User clicked "Yes," so you can call your API here
       // Example API call using fetch:
       data['createMultipleShiftJobData'] =true
       console.log(data,"vskvndvnvnfd")

       axios
.post(api.api2 + "createJobSelected", data)
.then((result) => {
 if (result.status == 200) {
   swal(`Data Submitted Successfully`);
   this.props.handleCloseAddJob()
   
   this.props.handleOnSubmit(this.props.inputData);
   this.setState({ querySuccess: false });

 } else {
  console.log('sahcdugcsd 1')
   this.setState({ querySuccess: false });
   swal(`${result.response.data.messgae}`);
 }
}).catch(error => {
  this.setState({ querySuccess: false });

});}

this.setState({ querySuccess: false });

}).catch(error => {
  this.setState({ querySuccess: false });

});;

  }}
   else if (err.response.data.code == 410){
            swal({
              title: "Job Accross multiple shift",
              text: `${err.response.data.message}`,
              icon: "warning",
              buttons: ["No", "Yes"], // Reversed the order of buttons to have "Yes" as the last button
              dangerMode: true,
            })
            .then((confirmed) => {
              if (confirmed) {
                // User clicked "Yes," so you can call your API here
                // Example API call using fetch:
                data['createMultipleShiftJobData'] =true
                axios
        .post(api.api2 + "ValidateJob", data)
        .then((result) => {
          if (result.status == 200) {
            this.setState({ querySuccess: false });
            swal(`Data Submitted Successfully`);
            console.log(data,"vskvndvnvnfd")

            axios.post(api.api2 + "createJobSelected", data)
            .then((result) => {
              if (result.status == 200) {
                swal(`Data Submitted Successfully`);
                this.props.handleCloseAddJob()
                
                this.props.handleOnSubmit(this.props.inputData);
                this.setState({ querySuccess: false });
    
              } else {
                this.setState({ querySuccess: false });
            console.log(`sahcdugcsd 3'`);

                swal(`${result.response.data.messgae}`);
              }
            }).catch(error => {});
            this.props.handleOnSubmit(this.props.inputData);

          } else {
            this.setState({ querySuccess: false });
            console.log(`sahcdugcsd 4`);
            swal(`${result.response.data.messgae}`);
          }
        }).catch(error => {
console.log(err.response.data.code,"onwqeqe",error.response)

             if(err.response.data.code == 500 || error.response.data.code == 500){
               const OperatorAssigned =   error.response.data.message.filter(item =>item.code === 411)
               const EquipmentBussy =   error.response.data.message.filter(item =>item.code === 408)

               if(EquipmentBussy && EquipmentBussy.length > 0){
                this.setState({ querySuccess: false });

                return swal({
                  title: "Equipment error please correct :",
                  content: {
                    element: "div",
                    attributes: {
                      innerHTML: `
                        <ul>
                          ${EquipmentBussy.map(item => `
                            <li>
                              On ${item.ShiftOf.split('T')[0]} in ${item.Shift}: ${item.message}
                            </li>
                          `).join('')}
                        </ul>
                      `
                    }
                  },
                  icon: "warning",
                  dangerMode: true,
                })
              
               }
              else if(OperatorAssigned && OperatorAssigned.length > 0){
                swal({
                  title: "Operator already assigned. Do you still want to assign?",
                  content: {
                    element: "div",
                    attributes: {
                      innerHTML: `
                        <ul>
                          ${error.response.data.message.map(item => `
                            <li>
                              On ${item.ShiftOf.split('T')[0]} in ${item.Shift}: ${item.message}
                            </li>
                          `).join('')}
                        </ul>
                      `
                    }
                  },
                  icon: "warning",
                  buttons: ["No", "Yes"],
                  dangerMode: true,
                })
                
                .then((confirmed) => {
                  if (confirmed) {
                    // User clicked "Yes," so you can call your API here
                    // Example API call using fetch:
                    data['createMultipleShiftJobData'] =true
                    console.log(data,"vskvndvnvnfd")
                    axios
            .post(api.api2 + "createJobSelected", data)
            .then((result) => {
              if (result.status == 200) {
                swal(`Data Submitted Successfully`);
                this.props.handleCloseAddJob()
                
                this.props.handleOnSubmit(this.props.inputData);
                this.setState({ querySuccess: false });
    
              } else {
            console.log(`sahcdugcsd 5`);

                this.setState({ querySuccess: false });
                swal(`${result.response.data.messgae}`);
              }
            }).catch(error => {
              this.setState({ querySuccess: false });
            });}
  this.setState({ querySuccess: false });
          
          })
            .catch(error => {
              this.setState({ querySuccess: false });
            });;

               }


                }
                 else {
            console.log(`sahcdugcsd 8`);

                  this.setState({ querySuccess: false });
                  swal(`${err.response.data.message}`);
                }
                  // Handle any errors that occurred during the API call
                  // console.error('API error:', error);
                });
              } else {
                this.setState({ querySuccess: false });
                // consol('User clicked "No" or closed the dialog');
              }
            }).catch(error => {
              this.setState({ querySuccess: false });
            });
            
          } 
        else  if (err.response.status == 409) {
          this.opertorAlreadyAssigned(err,data,myThis)

          } else {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          }
        });
    }
  };
  opertorAlreadyAssigned =(err,data,myThis)=>{
    swal({
      title: "The operator is already assigned for following machines. Do you want to deploy him again?",
      text: `${err.response.data.message}`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        data.Accept = true;
data['createMultipleShiftJobData'] =false

        axios
          .post(api.api2 + "createJobSelected", data)
          .then(result => {
  // consol(result,"total data")

            if (result.status == 200) {
              console.log(result,"dsihvbsjvhbfsjvb ")
              // consol(result,"total data")
              
              myThis.props.handleCloseAddJob()
              myThis.props.handleOnSubmit(this.props.inputData);
              swal(`Data Submitted Successfully job Number : ${result.data.jobNumber}`);
              this.setState({ querySuccess: false })
            }
            else {
              this.setState({ querySuccess: false })
              swal(`${result.data.message}`)
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
              toast.success(`${err}`, {
  position: 'bottom-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    background: 'red', // Set your desired background color
    color: 'white',     // Set your desired text color
  },
});
          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });
  }
  handleOnChangeOperator = (e) => {
    const componentData = { ...this.state.componentData }
    if (e.currentTarget.name == 'OperatorEnable') {
      componentData[e.currentTarget.name] = e.currentTarget.checked
    } else {
      componentData[e.currentTarget.name] = e.currentTarget.value
    }
    this.setState({ componentData: componentData });
  }



  SelectMy = () => {
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      );
    };

    const MultiValue = props => (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );

    const animatedComponents = makeAnimated();
    return <Select
      // isDisabled={this.props.actionStatus === "update"}                                                                                                                                                           
      options={this.state.operatorOptions}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue, animatedComponents }}
      onChange={(values) => this.setState({ operatorSelectedOption: values })}
      allowSelectAll={true}
      value={this.state.operatorSelectedOption}
      menuPlacement="auto"
    // onChange={handleServiceChange}
    // value={getValues()}
    // isMulti={true}
    // options={options}
    // isClearable={false}
    />
  }

  IsReasonEnable = (PartsPerCycle) => {
    let list = [...this.state.partsPerCycleList]
    list = list.map(one => parseInt(one.PartsPerCycle));
    let max = _.max(list);
    if (parseInt(PartsPerCycle) < max) {
      // consol("true")
      return true
    } else {
      // consol("false")

      return false;
    }
  }



  render() {
    // consol(this.props.dataEntryComponentData);

    return (
      <div className="border text-center">   <ToastContainer />
        {this.state.querySuccess ? <Loading /> : ""}
        <p>JOB {this.state.componentData.JobNumber ? this.state.componentData.JobNumber : ''}</p>
        <div className="row  mx-1 justify-content-center">
          <div className={"col-sm-10"}>
            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Batch Number</label>

                  <input type="text" placeholder='Enter Batch Number...' value={this.state.componentData.BatchNumber} name="BatchNumber" onChange={this.handleOnChange} />
                  {this.state.errors.BatchNumber && (
                    <div className="alert small alert-danger">
                      {this.state.errors["BatchNumber"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4 '>
                <p className='small align-middle mt-4 '>( optional )</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">Equipment Group</label>
                  <MultiSelect name='EquipmentGroup' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName='EquipmentGroup' all={this.state.equipmentGroupList} handleOnChange={this.handleOnChange} selected={this.state.componentData['EquipmentGroup']} />
                  {/* <select
                   className="form-control form-control-sm "
                    onChange={this.handleOnChange}
                    disabled={this.props.actionStatus == 'update'}
                    name="EquipmentGroup"
                    value={this.state.componentData.EquipmentGroup}
                  >
                    <option>--select equipment group--</option>
                    {this.state.equipmentGroupList.map((one) => {
                      return <option >{one.EquipmentGroup}</option>;
                    })}
                  </select> */}
                  {this.state.errors.EquipmentGroup && (
                    <div className="alert small alert-danger">
                      {this.state.errors["EquipmentGroup"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4'>

                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>

            </div>

            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">Equipment</label>
                  <MultiSelect name='Equipment' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName="EquipmentID" all={this.state.equipmentList} handleOnChange={this.handleOnChange} selected={this.state.componentData['Equipment']} />

                  {/* <select
                    className="form-control form-control-sm "
                    onChange={this.handleOnChange}
                    name="Equipment"
                    disabled={this.props.actionStatus == 'update'}
                    value={this.state.componentData.Equipment}
                  >
                    <option>--select equipment --</option>
                    {this.state.equipmentList.map((one) => {
                      return <option>{one.EquipmentID}</option>;
                    })}
                  </select> */}
                  {this.state.errors.Equipment && (
                    <div className="alert small alert-danger">
                      {this.state.errors["Equipment"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">Component Alias</label>
                  <MultiSelect name='AliasName' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName="name" all={this.state.aliasNames} selected={this.state.selectedAlias} handleOnChange={(value) => {
                    let cacheList = [...this.state.componentCacheList];
                    if (value.currentTarget.value) {
                      cacheList = cacheList.filter((one) => one.Alias && one.Alias.toLowerCase().includes(value.currentTarget.value.toLowerCase()));
                    }
                    this.setState({ componentList: cacheList, selectedAlias: value.currentTarget.value })

                  }} />
                  {/* <input onChange={(e)=>{
                    let cacheList=[...this.state.componentCacheList];
                    if(e.currentTarget.value){
                      cacheList=cacheList.filter((one)=>one.Alias&&one.Alias.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
                    }
                    this.setState({componentList:cacheList})
                  }} style={{width:'100%'}} type="text" name="Alias" placeholder="search component alias name here..." className="form-control form-control-sm"/> */}
                </div>

              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">Component</label>
                  <MultiSelect name='Component' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName="ComponentId" all={this.state.componentList} handleOnChange={this.handleOnChange} selected={this.state.componentData['Component']} />

                  {/* <select
                    className="form-control form-control-sm "
                    onChange={this.handleOnChange}
                    name="Component"
                    disabled={this.props.actionStatus == 'update'}
                    value={this.state.componentData.Component}
                  >
                    <option>--select component --</option>
                    {this.state.componentList.map((one) => {
                      return <option>{one.ComponentId}</option>;
                    })}
                  </select> */}
                  {this.state.errors.Component && (
                    <div className="alert small alert-danger">
                      {this.state.errors["Component"]}
                    </div>
                  )}
                </div>

              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">OperationId</label>
                  {/* <select
                    className="form-control form-control-sm "
                    onChange={this.handleOnChange}
                    disabled={this.props.actionStatus == 'update'}
                    name="OperationID"
                    value={this.state.componentData.OperationID}
                  >
                    <option>--select OperationId --</option>
                    {this.state.operationIdList.map((one) => {
                      return <option>{one.OperationID}</option>;
                    })}
                  </select> */}
                  <MultiSelect name='OperationID' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName="OperationID" all={this.state.operationIdList} handleOnChange={this.handleOnChange} selected={this.state.componentData['OperationID']} />

                  {this.state.errors.OperationID && (
                    <div className="alert small alert-danger">
                      {this.state.errors["OperationID"]}
                    </div>
                  )}
                </div>

              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-9'>
                <div className="form-group text-center">
                  <label className="small">ToolID</label>
                  {/* <select
                    disabled={this.props.actionStatus == 'add' ? this.state.toolRequiredForSelectedOperation == false || this.state.toolRequiredForSelectedOperation == 'false' : true}
                    className="form-control form-control-sm "
                    onChange={this.handleOnChange}
                    name="ToolID"
                    value={this.state.componentData.ToolID}
                  >
                    <option>--select ToolID --</option>
                    {this.state.toolIdList.map((one) => {
                      return <option>{one}</option>;
                    })}
                  </select> */}
                  <MultiSelect name='ToolID' isDisable={this.props.actionStatus === "update"} objLevel={0} masterName="ToolID" all={this.state.toolIdList} handleOnChange={this.handleOnChange} selected={this.state.componentData['ToolID']} />

                  {this.state.errors.ToolID && (
                    <div className="alert small alert-danger">
                      {this.state.errors["ToolID"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>



            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Operator</label>
                  {/* <select className='form-control form-control-sm'
                    disabled={this.props.actionStatus == 'update'}
                    value={this.state.componentData.OperatorID}
                    onChange={this.handleOnChangeOperator} name='OperatorID'>
                    <option>--select operator--</option>
                    {this.state.operatorList.map(one => {
                      return <option value={one.OperatorID}>{one.Name}</option>
                    })}
                  </select> */}
                  <this.SelectMy />
                  {/* <MultiSelect name='OperatorID' isDisable={this.props.actionStatus === "update"} objLevel={1} masterName="Name" all={this.state.operatorList} handleOnChange={this.handleOnChange} selected={this.state.componentData['OperatorID']} /> */}

                  {this.state.errors.Operators && (
                    <div className="alert small alert-danger">
                      {this.state.errors["Operators"]}
                    </div>
                  )}
                </div>

              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'update' ? <p className='small'>( can not update )</p> : ''}
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Target Parts</label>
                  <input className='form-control form-control-sm'
                    //disabled={this.props.actionStatus == 'update'}
                    value={this.state.componentData.TargetQty}
                    onChange={this.handleOnChange}
                    placeholder='enter quantity'
                    name='TargetQty'
                    type='number'
                    min='0'
                  />
                  {this.state.errors.TargetQty && (
                    <div className="alert small alert-danger">
                      {this.state.errors["TargetQty"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Customer Name</label>
                  {/* <select className='form-control form-control-sm'
                    // disabled={this.props.actionStatus == 'update'}
                    value={this.state.componentData.CustomerName}
                    onChange={this.handleOnChange} name='CustomerName'>
                    <option>--select customer--</option>
                    {this.state.customerList.map(one => {
                      return <option value={one.Name}>{one.Name}</option>
                    })}
                    
                  </select> */}
                  <MultiSelect name='CustomerName' objLevel={1} masterName="Name" all={this.state.customerList} handleOnChange={this.handleOnChange} selected={this.state.componentData['CustomerName']} />

                  {this.state.errors.CustomerName && (
                    <div className="alert small alert-danger">
                      {this.state.errors["CustomerName"]}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Parts Per Cycle/Num of Cavity</label>
                  <select
                    name="PartsPerCycle"
                    className="custom-select form-control"
                    value={this.state.componentData['PartsPerCycle']}
                    onChange={this.handleOnChange}
                  >
                    {/* Render the default option if a value is already selected */}
                    {this.props.actionStatus == "add" || this.props.actionStatus == 'update' ? (
                      ''
                    ) : (
                      <option value={this.state.componentData['PartsPerCycle']}>
                        {this.state.componentData['PartsPerCycle']}
                      </option>

                    )}

                    {/* Render the options from the partsPerCycleList */}
                    {this.state.partsPerCycleList.map((item, index) => (
                      <option key={index} value={item.PartsPerCycle}>
                        {item.PartsPerCycle}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Parts Per Cycle</label>
                  {// consol(this.state.componentData['PartsPerCycle'],"when add")}
                  <MultiSelect name='PartsPerCycle' objLevel={1} masterName="PartsPerCycle" all={this.state.partsPerCycleList} handleOnChange={this.handleOnChange}  selected={this.state.componentData['PartsPerCycle']} />
                
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div>
            </div> */}
            {this.state.componentData.PartsPerCycle && this.IsReasonEnable(this.state.componentData.PartsPerCycle) ? <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center'>
                  <label className="small">Cavity Loss Reason</label>
                  <MultiSelect name='PartsPerCycleReason' placeholder='Enter parts per cycle...' objLevel={1} masterName="Reason" all={this.state.partsPerCycleReason} handleOnChange={this.handleOnChange} selected={this.state.componentData['PartsPerCycleReason']} />
                  {/* <input type="number" className='form-control form-control-sm' placeholder='Enter parts per cycle...' value={this.state.componentData.PartsPerCycle} name="PartsPerCycle" onChange={this.handleOnChange} /> */}
                </div>
              </div>
              {/* <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div> */}
            </div> : <></>}

            <div className='row'>
              <div className='col-sm-9'>
                <div className='form-group text-center d-flex' >
                  <label className="small">Remarks</label>

                  <textarea width="100%" placeholder='Enter Remarks...' value={this.state.componentData.Remarks} name="Remarks" onChange={this.handleOnChange} rows="2" cols="35" maxLength="50" />
                </div>
              </div>
              <div className='col-sm-3 mt-4'>
                {this.props.actionStatus == 'add' ? <p className='small'>( optional )</p> : ''}
              </div>
            </div>

            {/* {this.props.actionStatus=='add'? <div className="form-group text-center">
            <label className="small">Operator</label>
              <div className="input-group mb-3 text-center">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input type="checkbox" name='OperatorEnable' checked={this.state.componentData.OperatorEnable} onChange={this.handleOnChangeOperator} aria-label="Checkbox for following text input" />
                  </div>
                </div>
                <select className='form-control form-control-sm' disabled={!this.state.componentData.OperatorEnable} onChange={this.handleOnChangeOperator} name='OperatorID'>
                  <option>--select operator--</option>
                 {this.state.operatorList.map(one=>{
                   return <option value={one.OperatorID}>{one.Name}</option>
                 })}
                </select>
              </div>
              {this.state.errors.OperatorID && (
                <div className="alert small alert-danger">
                  {this.state.errors["OperatorID"]}
                </div>
              )}
            </div>:''} */}


            <div className="row">
              <div className="form-group text-center col-sm-6">
                <label className="small">From Date</label>
                <input
                  className="form-control form-control-sm"
                  type="date"
                  defaultValue={this.state.FromDate}
                  onChange={this.handleOnChange}
                  name="FromDate"


                />
                {this.state.errors.FromDate && (
                  <div className="alert small alert-danger">
                    {this.state.errors["FromDate"]}
                  </div>
                )}
              </div>
              <div className="form-group text-center col-sm-6">
                <label className="small">From Time</label>
                <input
                  className="form-control form-control-sm"
                  defaultValue={this.state.FromTime}
                  type="time"
                  onChange={this.handleOnChange}
                  name="FromTime"
                />
                {this.state.errors.FromTime && (
                  <div className="alert small alert-danger">
                    {this.state.errors["FromTime"]}
                  </div>
                )}
              </div>
              {this.state.errors.From && (
                <div className="alert small alert-danger">
                  {this.state.errors["From"]}
                </div>
              )}
            </div>
            {/* <div className="form-group">
              <input
                type="checkbox"
                name="RunningStatus"
                value={this.state.RunningStatus}
                onChange={this.handleRadioChange}
                checked={this.state.RunningStatus === "Running"}
              />
              &nbsp;&nbsp;&nbsp;
              <label className="small">Currently Running</label>
            </div> */}
            {this.state.RunningStatus === "NotRunning" ? (
              <div className="row">
                <div className="form-group text-center col-sm-6">
                  <label className="small">Till Date</label>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    defaultValue={this.state.TillDate}
                    onChange={this.handleOnChange}
                    name="TillDate"
                  />
                  {this.state.errors.FromDate && (
                    <div className="alert small alert-danger">
                      {this.state.errors["TillDate"]}
                    </div>
                  )}
                </div>
                <div className="form-group text-center col-sm-6">
                  <label className="small">Till Time</label>
                  <input
                    className="form-control form-control-sm"
                    defaultValue={this.state.TillTime}
                    type="time"
                    onChange={this.handleOnChange}
                    name="TillTime"
                  />
                  {this.state.errors.FromTime && (
                    <div className="alert small alert-danger">
                      {this.state.errors["TillTime"]}
                    </div>
                  )}
                </div>
                {this.state.errors.Till && (
                  <div className="alert small alert-danger">
                    {this.state.errors["Till"]}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.handleSubmit(this.state.componentData);
            }}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default ComponentDataEntry;
